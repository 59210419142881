@import '../../styles/customMediaQueries.css';

.mobileInputRoot {
  width: 100%;
}

.desktopInputRoot {
  height: var(--topbarHeightDesktop);
}
.locationSearch{
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  @media (max-width: 767px) {
    height: 360px;
  }
   }
.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}
.modalButtons {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  width: 100%;
  @media (max-width: 767px) {
    gap: 11px;
    right: 0;
    padding: 32px 20px;
  }

  & button {
    max-width: 144px;
    height: 40px;
    border: none;
    background: #1fd1bc;
    border-radius: 6px;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #ffffff;

    @media (max-width: 767px) {
      max-width: 162px;
      font-size: 14px;
      line-height: 18px;
    }

    &:first-child {
      color: #060531;
      background: #ffffff;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }
  }
}

.mobileInput {
  flex-grow: 1;

  /* Font */
  composes: h1 from global;

  /* Layout */
  margin: 0 24px 0 0;
  padding: 4px 13px 10px 13px;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

.desktopIcon {
  position: absolute;
  top: 12px;
  left: 19px;
  & svg{
    height: 20px;
    width: 20px;
  }
}

.desktopInput {
  flex-grow: 1;
  /* Font */
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  opacity: unset !important;
  padding-left: 48px !important;
  background: #F4F6F8 !important;
  border-radius: 10px !important;
  height: 56px !important;
  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;
  @media (max-width: 767px) {
    height: 50px !important;
  }
  &:hover,
  &:focus {
    border-bottom-color: none;
    outline: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--matterColor);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    
    color: #1F403B;
    
    opacity: 0.4;
    transition: var(--transitionStyleButton);
  }

  margin-top: 0;
  margin-bottom: 0;
}

.mobilePredictions {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(
    100dvh -
      calc(
        var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) -
          var(--TopbarSearchForm_bottomBorder)
      )
  );
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: 58px;
  left: 0;
  background-color: #ffffff;
  box-shadow: unset;
  z-index: calc(var(--zIndexPopup) + 1);
  max-width: 434px;
}
