.discountSectionWrapper {
  @media (min-width: 1024px) {
    margin: 0 -10px;
  }

  @media (max-width: 1023px) {
    margin: 0 -5px;
  }

  &>div {
    /* padding: 60px 0; */
    border-radius: 10px;
    width: 100%;
    margin: 32px 0 96px;

    @media (max-width: 1023px) {
      /* padding: 32px 24px; */
    }

    @media (max-width: 920px) {
      width: 100%;
      flex: 0 0 auto;
      width: 100%;
      max-width: 100%;
    }

    @media (max-width: 767px) {
      margin: 0px 0 32px;
    }

    @media (max-width: 460px) {
      width: 100%;
    }

    & h3 {
      font-weight: 700;
      font-size: 36px;
      line-height: 60px;
      letter-spacing: 0.02em;
      color: #ffffff;
      margin: 0 0 8px;

      @media (max-width: 1300px) {
        font-size: 38px;
        line-height: 50px;
      }

      @media (max-width: 1023px) {
        font-size: 24px;
        line-height: 36px;
      }

      @media (max-width: 767px) {
        font-size: 22px;
        line-height: 26px;
      }
    }

    & p {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #ffffff;
      margin: 0;

      @media (max-width: 1300px) {
        font-size: 20px;
        line-height: 22px;
      }

      @media (max-width: 1023px) {
        font-size: 14px;
        line-height: 18px;
        height: 40px;
      }

      @media (max-width: 767px) {
        height: auto;
      }
    }
  }

  & .mobileSlider {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: 920px) {
      margin: 16px 0 32px;
      overflow-x: auto;
      /* flex-wrap: no-wrap;
      overflow-x: auto; */

      &::-webkit-scrollbar {
        display: none;
      }

      & {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
      }
    }

    &>div {
      padding: 60px 48px;
      border-radius: 10px;
      width: 100%;
      min-width: 100%;

      @media (max-width: 1023px) {
        padding: 32px 24px;

        &:nth-child(1) {
          order: 2;
        }

        &:nth-child(2) {
          order: 1;
        }

        &:nth-child(3) {
          order: 3;
        }
      }

      @media(max-width: 920px) {
        width: 80%;
        min-width: 80%;
      }

      @media (max-width: 767px) {
        width: 100%;
        flex: 0 0 auto;
        width: 70%;
        max-width: 100%;
      }

      @media (max-width: 460px) {
        width: 100%;
        height: 134px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      & h3 {
        font-weight: 700;
        font-size: 36px;
        line-height: 60px;
        letter-spacing: 0.02em;
        color: #ffffff;
        margin: 0 0 8px;

        @media (max-width: 1300px) {
          font-size: 38px;
          line-height: 50px;
        }

        @media (max-width: 1023px) {
          font-size: 30px;
          line-height: 42px;
        }

        @media (max-width: 767px) {
          font-size: 22px;
          line-height: 26px;
        }
      }

      & p {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: #ffffff;
        margin: 0;

        @media (max-width: 1300px) {
          font-size: 20px;
          line-height: 22px;
        }

        @media (max-width: 1023px) {
          font-size: 16px;
          line-height: 20px;
          height: 40px;
        }

        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
          height: auto;
        }
      }
    }
  }

  & :global(.slick-slide) {
    padding: 0 12px;


    @media (max-width: 767px) {
      padding: 0 5.5px;
    }
  }

  & :global(.slick-dots) {
    bottom: 0;

    & li {
      width: 14px;

      @media (max-width: 767px) {
        width: 10px;
      }
    }
  }

  &>div {
    &>div {
      &>div {
        &>div {
          &>div {
            &>div {
              padding: 60px 48px;
              border-radius: 10px;
              width: 100%;
              cursor: pointer;

              @media(min-width: 1024px) {
                height: 222px;
                padding: 60px 40px;
              }

              @media(max-width: 767px) {
                height: 150px;
              }

              @media(max-width: 440px) {
                height: 122px;
              }

              @media(max-width: 390px) {
                height: 112px;
              }

              @media (max-width: 1023px) {
                padding: 32px 24px;
                display: flex !important;
                flex-direction: column;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}



.discountContentsFirstSlide {
  background: linear-gradient(84.25deg, #FB9646 2.46%, #ECBC02 99.2%);
}

.discountContentsSecondSlide {
  background: linear-gradient(83.14deg, #509E82 3.33%, #1FD1BC 100%);
  position: relative;
}

.discountContentsThirdSlide {
  background: linear-gradient(83.98deg, #FD4E50 2.53%, #FAA516 105.67%);
  border-radius: 10px;
}

.discountContentsSecondSlide h3 {
  display: flex;
  justify-content: space-between;
}


.comingSoon {
  background: linear-gradient(270deg, #4f396a 5.25%, #576bb0 96.68%);
  display: inline-block;
  padding: 10px 20px 10px 20px;
  color: white;
  position: absolute;
  top: 20px;
  right: -15px;
  border-radius:  10px 0 0 10px;
}
.comingSoon:after {
  content: "";
  width: 0;
  height: 0;
  top: 0;
  position: absolute;
  left: 0;
  /* border-left: 20px solid #fff;/ */
  border-bottom: 20px solid transparent;
}
.comingSoon:before {
  height: 0;
  width: 0;
  border-top: 15px solid #381f57;
  border-right: 15px solid transparent;
  bottom: -15px;
  position: absolute;
  content: "";
  right: 0;
}


.innerStatus {
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  color: #fff;
}