@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
  @media (max-width: 767px) {
    padding: 11px 20px 0 20px;
  }
  & input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1f403bab;
    opacity: 1; /* Firefox */
  }
}
.informationContents{
max-width: 808px;
min-width: 95%;
margin: auto;
  @media (min-width: 768px) {
    padding-right: 20px;
  }
  @media (min-width: 1024px) {
    padding-right: 40px;
  }
  @media (min-width: 1250px) {
    padding-right: 60px;
  }
  @media (min-width: 1336px) {
    padding-right: 0px;
    min-width: 500px; 
  }
  @media (min-width: 1500px) {
    max-width: 100%;
  }
}

.modal {
  @media (max-width: 767px) {
    height: auto !important;
    min-height: unset !important;
  }
}

.modalContainer {
  height: auto;
  & .modalSubTitle {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 24px 0 0;
  }
  & .paragraphList {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 16px 0 0;
    @media (max-width: 767px) {
      margin: 8px 0 0;
    }
  }
  & h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #060531;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  & .firstParagraph{
    margin: 16px 0 8px;
    @media (max-width: 767px) {
      margin: 8px 0 16px; 
    }
  }
  & h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: rgba(31, 64, 59, 0.8);
   
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  & h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
.dividerLine {
  width: 104%;
  height: 1px;
  margin: 16px 0 24px -16px;
  background: #d8dad9;
  @media (max-width: 767px) {
    display: none;
  }
}
.modalTitle {
  display: flex;
  gap: 16px;
  @media (max-width: 767px) {
    gap: 7px;
    & > svg {
      width: 24px;
      height: 24px;
      margin: -2px 0 0;
    }
  }
}
.form {
  flex-grow: 1;
}

.title {
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #060531;
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
    border-bottom: none;
    padding: 0px;
    margin: 0 0 26px;
  }
}
