@import '../../styles/customMediaQueries.css';

.root {
  display: block;
  position: relative;
  height: auto;
  border: 0;
  padding: 0;

  /* Borders */
  border-radius: 10px;
  /* @media (min-width: 768px) { */
  box-shadow: var(--boxShadowPopupLight);

  transition: var(--transitionStyleButton);
  /* } */

  &:hover {
    cursor: pointer;
    box-shadow: var(--boxShadowPopup);
  }
}

.anchor {
  display: flex;
  overflow: hidden;
  width: 100%;

  &:hover {
    text-decoration: none;
  }

  /* @media (max-width: 767px) {
    max-width: 40%;
    max-height: 108px;
    width: 100%;
    height: 100%;
    border-radius: 10px 0 0 10px !important;
  } */
}

.dayText {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #1F403B;

  @media (max-width: 767px) {
    font-size: 11px;
    line-height: 18px;
  }
}

.card {
  composes: h5 from global;
  /**
   * Since caret is absolutely positioned,
   * label must have relative to be included to the same rendering layer
   */
  position: relative;

  min-height: 120px;

  /* Font */
  color: var(--matterColor);

  background-color: var(--matterColorLight);

  /* Dimensions */
  margin-top: 0;
  margin-bottom: 0;
  transition: var(--transitionStyleButton);
  width: 100%;

  /* Overwrite dimensions from font styles */
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 767px) {
    width: 270px;
  }
}

/* Overwrite Mapbox's specific font rules */
.root .card {
  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightSemiBold);
}

.card {
  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}

.infoMain {
  /* Layout */
  padding: 16px;
  flex: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media (max-width: 767px) {
    padding: 4px 12px 8px 8px;
  }

  & h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #1fd1bc;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1f403b;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }
  }

  & .locationContent {
    & p {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1f403b;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.03em;
        color: #000000;
      }
    }
  }

  & h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    text-align: left;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  align-items: center;
  text-align: left;
  gap: 5px;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 71.6667%;
  /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative);

  /* Loading BG color */
  @media (max-width: 767px) {
    padding-bottom: 92.6667%;
    height: 100%;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px !important;
}

.name {
  flex-grow: 1;
}

.paginationInfo {
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
}

.paginationPage {
  /* Font */
  composes: h4 from global;
  font-weight: var(--fontWeightSemiBold);
  margin-top: 0;
  margin-bottom: 0;

  /* Overwrite dimensions from font styles */
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.price {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    fill: #fff;

    @media (max-width: 767px) {
      width: 16px;
      height: 16px;
    }
  }
}

.priceContent {
  display: flex;
  align-items: baseline;
  gap: 3px;

  @media (max-width: 767px) {
    gap: 2px;
  }

  & img {
    @media (max-width: 767px) {
      width: 11px;
      height: 12px;
    }
  }

  & p {
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    color: #1f403b;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 11px;
      line-height: 11px;
      letter-spacing: 0.02em;
      color: #000000;
    }
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceValue {
  /* Font */
  composes: h2 from global;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}


.locationContent {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 6px;
  /* margin: 16px 0 16px; */

  @media (max-width: 767px) {
    gap: 3px;
  }

  & svg {
    fill: transparent;
    min-width: 14px;

    @media (max-width: 767px) {
      width: 10px;
      height: 14px;
    }
  }
}

& .locationContent {
  & p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1f403b;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.03em;
      color: #000000;
    }
  }
}

.ratingStar {
  display: flex;
  align-items: center;
  gap: 4px;

  & svg {
    @media (max-width: 767px) {
      height: 10px;
      width: 10px;
    }
  }
}

.paginationPrev {
  display: inline-block;
  width: 36px;
  height: 40px;
  border: 0;
  background-image: url('data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M5.667 1c.094 0 .188.04.254.118.12.14.102.35-.038.47L1.85 5l4.032 3.412c.14.12.158.33.04.47-.12.14-.33.158-.47.04L1.117 5.254C1.043 5.19 1 5.098 1 5s.043-.19.118-.255L5.45 1.08c.064-.054.14-.08.217-.08" stroke="%23FFF" fill="%23FFF" fill-rule="evenodd"/></svg>');
  background-position: center;
  border-bottom-left-radius: inherit;
  cursor: pointer;
  opacity: 0.5;
  transition: var(--transitionStyleButton);

  &:hover {
    opacity: 1;
  }
}

.paginationNext {
  display: inline-block;
  width: 36px;
  height: 40px;
  border: 0;
  background-image: url('data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M1.333 9c-.094 0-.188-.04-.254-.118-.12-.14-.102-.35.038-.47L5.15 5 1.118 1.588c-.14-.12-.158-.33-.04-.47.12-.14.33-.158.47-.04l4.334 3.667C5.957 4.81 6 4.902 6 5s-.043.19-.118.255L1.55 8.92c-.064.054-.14.08-.217.08" stroke="%23FFF" fill="%23FFF" fill-rule="evenodd"/></svg>');
  background-position: center;
  border-bottom-right-radius: inherit;
  cursor: pointer;

  opacity: 0.5;
  transition: var(--transitionStyleButton);

  &:hover {
    opacity: 1;
  }
}

.caretShadow {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same box-shadow as label */
  box-shadow: var(--boxShadowPopupLight);
}

.caret {
  @media (min-width: 767px) {
    /* Caret / arrow dimensions and position */
    width: 6px;
    height: 6px;
    position: absolute;
    bottom: -3px;
    left: 50%;
    margin-left: -3px;
    transform: rotate(45deg);

    /* Caret should have same bg-color and border as label */
    background-color: var(--matterColorLight);
    border-right-style: solid;
    border-right-color: var(--matterColorNegative);
    border-right-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: var(--matterColorNegative);
    border-bottom-width: 1px;
  }
}

.caretWithCarousel {
  background-color: var(--marketplaceColor);
  border-right-color: var(--marketplaceColor);
  border-bottom-color: var(--marketplaceColor);
}

.borderRadiusInheritTop {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  border-radius: inherit;
}

.borderRadiusInheritBottom {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  /* width: 300px; */

  @media (max-width: 767px) {
    border-radius: 10px;
  }
}

.author {
  display: flex;
  gap: 10px;
}

.authorName {
  align-self: center;
}