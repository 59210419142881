@import '../../styles/customMediaQueries.css';

.root {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    height: 57px;
    align-items: flex-end;
    padding: 13px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;

  &:first-child {
    margin-left: 0;
  }
}
.collapsibleLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: relative; */
  & svg {
    /* position: absolute;
    top: 0;
    right: -25px; */
    @media (min-width: 768px) {
      display: none;
    }
  }
}
.locationContent {
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 8px 0 0;
  @media (max-width: 767px) {
    gap: 3px;
    margin: 3px 0 0;
  }
  & svg {
    fill: transparent;
    @media (max-width: 767px) {
      min-width: 10px;
      height: 14px;
    }
  }
  & span {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #1f403b;
    @media (max-width: 1100px) {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
    }
  }
}
.icon{
  z-index: 999999 !important;
}
.iconMain{
  display: flex;
  gap: 10px;
}
.mainMenu {
  display: flex;
  position: relative;
  padding: 0 0 24px;
  margin: 0 0 24px;
  @media (max-width: 767px) {
    padding: 0px 0 22px;
    margin: 0;
  }
  & a {
    width: 100%;
  }
  &:last-child {
    border: none;
  }
  & .arrowIcon {
    position: absolute;
    right: 25px;
    top: -3px;
  }
  & h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #1f403b;
    @media (max-width: 1100px) {
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 110%;
    }
  }
  & :global(.Collapsible__contentInner) {
    margin: 16px 0 1px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 16px;
    @media (max-width: 767px) {
      width: 88%;
    }
  }
  & :global(.is-closed) {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      height: 8px;
      width: 15px;
      top: 9px;
      right: 25px;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.5129 0.286937C14.6815 0.464184 14.7729 0.701146 14.7669 0.945734C14.7609 1.19032 14.658 1.42252 14.4809 1.59129L8.02067 7.74511C7.84903 7.90873 7.621 8 7.38387 8C7.14674 8 6.91871 7.90873 6.74708 7.74511L0.286859 1.59252C0.109486 1.42379 0.00640371 1.19152 0.000288388 0.946788C-0.00582693 0.70206 0.0855259 0.464925 0.254251 0.287552C0.422976 0.110179 0.655251 0.00709615 0.89998 0.000980825C1.14471 -0.0051345 1.38184 0.0862184 1.55922 0.254943L7.38449 5.80335L13.2085 0.253713C13.3859 0.0852194 13.623 -0.00593054 13.8676 0.000299601C14.1122 0.00652974 14.3443 0.10963 14.5129 0.286937Z' fill='%23060531'/%3E%3C/svg%3E%0A");
    }
  }
  & :global(.is-open) {
    position: relative;
    &:after {
      position: absolute;
      content: '';
      height: 8px;
      width: 15px;
      top: 9px;
      right: 25px;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='8' viewBox='0 0 15 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.254455 7.71306C0.0858036 7.53582 -0.00556441 7.29885 0.000434941 7.05427C0.00643429 6.80968 0.10931 6.57748 0.286448 6.40871L6.74667 0.254893C6.9183 0.0912746 7.14633 -2.44911e-08 7.38346 -2.44911e-08C7.62059 -2.44911e-08 7.84862 0.0912746 8.02026 0.254893L14.4805 6.40748C14.6578 6.57621 14.7609 6.80848 14.767 7.05321C14.7732 7.29794 14.6818 7.53507 14.5131 7.71245C14.3444 7.88982 14.1121 7.9929 13.8674 7.99902C13.6226 8.00513 13.3855 7.91378 13.2081 7.74506L7.38285 2.19665L1.5588 7.74629C1.3814 7.91478 1.14434 8.00593 0.899751 7.9997C0.655161 7.99347 0.423054 7.89037 0.254455 7.71306V7.71306Z' fill='%23060531'/%3E%3C/svg%3E%0A");
    }
  }
  & :global(.NamedLink_active) {
    margin: 0;
  }
  & :global(.NamedLink_active) h4 {
    background: #1fd1bc;
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    padding: 16px 46px;
    min-width: 224px;
    white-space: pre;
    margin: -18px 0px -18px -46px;
    @media (max-width: 1300px) {
      width: 80%;
    }
    @media (max-width: 1100px) {
      /* width: 131%; */
      font-size: 14px;
      line-height: 18px;
      /* padding: 10px; */
    }
    @media (max-width: 767px) {
      width: 112%;
      background: #1fd1bc21;
      color: #1f403b;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      padding: 11px 16px;
    }
  }
  & :global(.NamedLink_active) {
    @media (max-width: 767px) {
      border-radius: 6px;
      padding: 11px 10px;
      margin: -5px -10px;
      width: 111%;
      margin-right: -30px;
    }
  }
  & :global(.Collapsible) {
    width: 100%;
  }
  & :global(.Collapsible__trigger) {
    display: block;
    background: #ffffff;
    border: none;
    margin-bottom: 0;
    padding: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #060531;
    position: relative;
    z-index: 10;
    background: transparent;
    padding-left: 16px;
    @media (max-width: 1100px) {
      /* padding: 15px 48px 15px 18px; */
      height: auto;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
.profileContent {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 0 32px;
  & a {
    width: 84px;
    height: 84px;
    @media (max-width: 1150px) {
      min-width: 64px;
      width: 64px;
      height: 64px;
    }
  }
  & p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #060531;
    @media (max-width: 1100px) {
      font-size: 16px;
    }
  }
}
