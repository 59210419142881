@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  composes: defaultBackgroundImage from global;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  font-size: 36px;
  line-height: 45px;
  color: var(--matterColor);
  font-weight: var(--fontWeightBold);
  margin: 0 0 48px;
  display: inline-block;
  composes: animation;
  @media (max-width: 1023px) {
    margin: 0 0 32px;
    font-size: 34px;
    line-height: 46px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
    line-height: 26px;
  }

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.textColor {
  color: #1fd1bc;
}

.sectionContainer {
  composes: marketplaceSectionWrapper from global;
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 110px;

  @media (max-width: 1500px) {
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 1280px) {
    gap: 80px;
    max-width: 100%;
    width: 90%;
  }

  @media (max-width: 1023px) {
    gap: 60px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 40px;
  }

  & .heroContentLeft {
    width: 60%;

    @media (max-width: 767px) {
      width: 100%;
      text-align: center;
    }

    .heroMainTitle {}

    & .heroSubTitle {
      font-size: 24px;
      line-height: 34px;
      color: #1f403b;
      opacity: 0.8;
      margin: 24px 0 48px;

      @media (max-width: 767px) {
        margin: 16px 0 24px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    & .searchInput {
      position: relative;
      max-width: 608px;

      & .searchIcon {
        position: absolute;
        top: 15px;
        left: 30px;
        @media (max-width: 1023px) {
          top: 19px;
          left: 16px;
          width: 18px;
        }

        & svg {
          width: 18px;
          height: 18px;
        }
      }

      & input {
        background: #ffffff;
        border: 1px solid #e5efff;
        box-shadow: 0px 36px 60px rgba(193, 193, 193, 0.12);
        border-radius: 100px;
        padding: 27px 0 27px 56px;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #1f403b;
        opacity: 0.4;
        height: 64px;
        @media (max-width: 1023px) {
          height: 60px;
          padding: 23px 0 23px 35px;
          font-size: 14px;
          line-height: 14px;
        }
      }

      & button {
        position: absolute;
        top: 8px;
        right: 8px;
        background: #fd4e50;
        border-radius: 100px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        width: 148px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        @media (max-width: 1023px) {
          width: 102px;
          height: 44px;
          font-size: 14px;
          line-height: 16px;
          top: 9px;
          right: 6px;
        }
      }
    }
  }

  & .heroContentRight {
    width: 46%;

    @media (max-width: 767px) {
      width: 100%;
    }

    & svg {
      fill: transparent;

      @media (max-width: 1200px) {
        width: 380px;
        height: 300px;
      }

      @media (max-width: 1100px) {
        width: 300px;
        height: 200px;
      }

      @media (max-width: 1100px) {
        width: 300px;
        height: 200px;
        margin: 0 auto;
        display: block;
      }

      @media (max-width: 1100px) {
        width: 220px;
        height: 150px;
      }
    }
  }
}