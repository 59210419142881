@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
  background: #ffffff;
  border: 1px solid #d8dad9;
  box-shadow: 0px 0px 46px rgb(31 209 188 / 5%);
  border-radius: 6px;
  padding: 16px;
  @media (max-width: 767px) {
    padding: 0;
    border: 0;
  }
}

.submitButtonWrapper {
  display: flex;
  justify-content: space-between;
  & button {
    margin: 0;
  }
  & .hourText {
    font-size: 14px;
    line-height: 14px;
    color: #060531;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 13px;
      line-height: 22px;
      color: #000000;
      margin: 4px 0 0;
    }
  }
  & .priceText {
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    color: #1fd1bc;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 24px;
      color: #000000;
    }
  }
}

.learnMoreSection {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #d8dad9;
  & .learnMoreSectionLink {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1fd1bc;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & a {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1fd1bc;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & .freeCancellation {
    display: flex;
    align-items: center;
    gap: 8px;
    @media (max-width: 767px) {
      gap: 9.28px;
    }
    & svg {
      @media (max-width: 767px) {
        width: 17.5px;
        height: 17.5px;
      }
    }
    & h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #060531;
      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
.bookingHeading {
  display: flex;
  align-items: center;
  max-width: 450px;
  justify-content: center;
  padding: 0 0 16px;
  @media (max-width: 767px) {
    display: flex !important;
    justify-content: center;
    gap: 11px;
  }
}
.desktopPriceContainer {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 80px;
  gap: 4px;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 31.2%;
    height: 72px;
  }
}
.desktopPriceValue {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #060531;
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
  }
}
.error {
  color: red;
}
.sectionDescription {
  border-bottom: 1px solid #d8dad9;
  border-top: 1px solid #d8dad9;
  padding: 16px 0;
  margin: 0 0 16px;
  & h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    margin: 16px 0 0;
    @media (max-width: 767px) {
      margin: 6px 0 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
.desktopPerUnit {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #1f403b;
}
.activeLink {
  border: 1px solid #1fd1bc;
  border-radius: 6px;
  & .desktopPriceValue {
    color: #1fd1bc;
  }
  & .desktopPerUnit {
    color: #1fd1bc;
  }
}

.officialBrandSection {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  & a {
    & svg {
      @media (max-width: 767px) {
        width: 32px;
        height: 32px;
      }
    }
  }
  & .officialBrand {
    display: flex;
    align-items: center;
    gap: 8px;
    @media (max-width: 767px) {
      gap: 9.28px;
    }
    & svg {
      @media (max-width: 767px) {
        width: 24px;
        height: 13px;
      }
    }
    & h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #060531;
      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.priceBreakdownContainer {
  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 6px;
  margin-bottom: 12px;
  @media (--viewportMedium) {
    margin-top: 12px;
    margin-bottom: 16px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 42px 0;
  @media (max-width: 767px) {
    margin: 0;
    padding-bottom: 100px;
  }
}

.error {
  color: var(--failColor);
  margin: 6px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  font-size: 16px;
  line-height: 20px;
  color: var(--failColor);
  margin: 6px 24px 6px 24px;

  @media (--viewportMedium) {
    margin: 6px;
  }
}

.smallPrint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}
.bookingPanelMobile {
  & .submitButtonWrapper {
    @media (max-width: 767px) {
      padding: 16px 20px;
      align-items: center;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      background: #fbfbfb;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    }
  }
}
.deliveredWrapper {
  border-top: 1px solid #d8dad9;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 24px 0;
  @media (max-width: 767px) {
    padding: 16px 0;
  }
  & .desktopLine {
    @media (max-width: 767px) {
      display: none;
    }
  }
  & .mobileLine {
    @media (min-width: 768px) {
      display: none;
    }
  }
  & .offText {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    @media (max-width: 767px) {
      letter-spacing: -0.02em;
    }
  }
  & .onText {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #259f46;
    @media (max-width: 767px) {
      letter-spacing: -0.02em;
    }
  }
  & div {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 180px;
    @media (max-width: 767px) {
      width: 146px;
      flex-direction: column;
    }
  }
  & h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.025em;
    }
  }
}
