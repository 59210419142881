.profileContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin: 16px 0 16px;
  background: #fcffff;
  border-radius: 6px;
  padding: 16px;
  @media (max-width: 767px) {
    background: transparent;
    padding: 16px 0;
    margin: 0;
    border-top: 1px solid #D8DAD9;
    border-bottom: 1px solid #D8DAD9;
  }
  & .profileContentLeft {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  & .ownedBy {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1f403b;
    opacity: 0.6;
    @media (max-width: 767px) {
      display: none;
    }
  }
  & .ownedByMobile {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1f403b;
    opacity: 0.6;
    @media (min-width: 768px) {
      display: none;
    }
  }
  & .mobileAvatar{
    display: flex;
    flex-direction: column;
    gap: 5.5px;
  }
  & .ownerContent{
    @media (max-width: 767px) {
      margin-left: -28px;
      margin-bottom: -22px;
    }
  }
  & .listingMessage {
    & svg {
      @media (max-width: 767px) {
        width: 32px;
        height: 32px;
      }
    }
  }
  & a {
    width: 80px;
    height: 80px;
    @media (max-width: 1150px) {
      min-width: 48px;
      width: 48px;
      height: 48px;
    }
  }
  & .ratingText {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #1f403b;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }
  }
  & p {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    margin: 4px 0 4px;
    @media (max-width: 1100px) {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
    }
  }
}

.locationContent {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 4px 0 0;
  @media (max-width: 767px) {
    gap: 2px;
  }
  & svg {
    fill: transparent;
    @media (max-width: 767px) {
      min-width: 10px;
      height: 14px;
    }
  }
  & span {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #1f403b;
    @media (max-width: 1100px) {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
    }
  }
}
.ratingStar {
  display: flex;
  align-items: center;
  gap: 4px;

  & svg {
    @media (max-width: 767px) {
      height: 10px;
      width: 10px;
    }
  }
}
