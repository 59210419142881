@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}
.hourRentals {
  display: flex;
  align-items: center;
  max-width: 400px;
  justify-content: space-between;
  & .rentalsText {
    font-weight: 700;
    font-size: 16px;
    line-height: 137%;
    color: #060531;
    @media (max-width: 767px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 137%;
      color: #060531;
    }
  }
}
.dayRentals {
  display: flex;
  align-items: center;
  max-width: 400px;
  justify-content: space-between;
  & .rentalsText {
    font-weight: 700;
    font-size: 16px;
    line-height: 137%;
    color: #060531;
    @media (max-width: 767px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 137%;
      color: #060531;
    }
  }
}
.optionalText {
  color: rgba(31, 64, 59, 0.6);
  font-weight: 600;
  font-size: 16px;
  line-height: 137%;
  @media (max-width: 767px) {
    color: rgba(31, 64, 59, 0.8);
    font-weight: 500;
    font-size: 16px;
    line-height: 137%;
  }
}
.thisArticleText{
  margin: 72px 0 16px;
  @media (max-width: 767px) {
    margin: 0px 0 16px;
  }
}
.thisArticleText, .textWrapper{
  @media (max-width: 420px) {
    max-width: 303px;
    margin-left: auto;
    margin-right: auto;
  }
}

.textWrapper span span {
  color: #1fd1bc;
  cursor: pointer;
}
.error {
  color: var(--failColor);
}
.liveChat {
  font-weight: 700;
  color: #1fd1bc;
}
.ball {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 4px;
  left: 4px;
  transition: transform 0.5s ease;
  background: #FFFFFF;

  border-radius: 13.5px;
  @media (max-width: 767px) {
    width: 18px;
    height: 18px;
    top: 3px;
    left: 3px;
  }
  &.toggled {
    transform: translateX(167%);
    background: #1FD1BC;
    @media (max-width: 767px) {
    transform: translateX(135%);
    }
  }
}
.checkboxWrapper {
  cursor: pointer;
  position: relative;
  font-family: var(--font-familyLite);
  width: 72px;
  height: 32px;
  background: #D8DAD9;
  opacity: 0.72;
  border-radius: 20px;
  @media (max-width: 767px) {
    width: 48px;
    height: 24px;
  }
}
.listDot {
  background: rgba(31, 64, 59, 0.6);
  min-height: 3px;
  max-height: 3px;
  min-width: 3px;
  border-radius: 100px;
  display: inline-block;
  margin: 10px 7px 3px;
}
.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  /* top: 0; */
  z-index: 10;
  padding: 24px 0;
  background: #ffffff;
  box-shadow: 0px 1px 24px rgb(31 209 188 / 10%);
  @media (max-width: 767px) {
    padding: 0;
    gap: 0;
  }
  & button:first-child {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    color: #1f403b;
    opacity: 0.6;
  }
  & button {
    margin: 0;
    @media (max-width: 767px) {
      height: 56px;
      width: 50%;
      border-radius: 0;
    }
  }
}
.priceInput {
  flex-shrink: 0;
  width: 100%;
  position: relative;
  &:after {
    content: '$';
    position: absolute;
    height: 44px;
    width: 21px;
    top: 36px;
    left: 0;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    color: rgba(31, 64, 59, 0.3);
    background: #fff;
    @media (max-width: 767px) {
      top: 51px;
    }
  }
  & input {
    color: #1fd1bc;
    border: none;
    font-weight: 600;
    font-size: 36px;
    line-height: 45px;
    &:focus {
      padding-left: 22px;
      color: #1fd1bc;
      &::placeholder {
        color: #1fd1bc;
        padding-left: 0px;
      }
    }
    &::placeholder {
      color: #1fd1bc;
      padding-left: 22px;
    }
  }
  & label {
    font-weight: 500;
    font-size: 18px;
    line-height: 137%;
    color: #060531;
    margin: 0 0 8px;
    @media (max-width: 767px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin: 24px 0 0;
    }
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (min-width: 768px) {
    display: inline-block;
    width: 186px;
    margin-top: 100px;
  }
}
