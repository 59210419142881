.root {
  outline: none;
}

.contentWrapper {
  & label {
    display: none;
  }
  & fieldset {
    padding: 0;
  }
}

.buttonsWrapper {
  display: flex;
  padding: 0 52px 48px 52px;
  align-items: center;
  justify-content: center;
  gap: 24px;
  @media (max-width: 767px) {
    padding: 24px 20px 32px 20px;
    gap: 11px;
    background: #fff;
    border-radius: 0 0 6px 6px;
  }
  & button {
    border: none;
    background: #1fd1bc !important;
    border-radius: 6px;
    width: 144px;
    height: 40px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-transform: uppercase;
    @media (max-width: 767px) {
      width: 138px;
      font-size: 14px;
      line-height: 18px;
    }
    &:first-child {
      background: #ffffff !important;
      color: #060531;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
    }
  }
}

.clearButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.cancelButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
    transition: width var(--transitionStyleButton);
  } */
}
