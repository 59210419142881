.contentWrapper {
  max-width: 1306px;
  width: 100%;
  margin: 0 auto 96px auto;
  @media (max-width: 1500px) {
    max-width: 1216px;
  }
  @media (max-width: 1336px) {
    padding: 0 60px;
  }

  @media (max-width: 1250px) {
    padding: 0 40px;
  }

  @media (max-width: 900px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    margin-bottom: 44px;
  }

  & .mobileHeadingBox {
    display: none;

    @media (max-width: 460px) {
      display: block;
      margin-top: 24px;
      display: flex;
      align-items: center;
      height: 28px;
      & .backButton {
        width: 20px;
        height: 20px;
        z-index: 2;
      }

      & .categoryTitleMobile {
        font-weight: 700;
        font-size: 22px;
        line-height: 28px;
        color: #060531;
        text-align: center;
        left: 0;
        right: 0;
        position: absolute;
        z-index: -1;
      }
    }
  }

  & .categoryTitle {
    margin-top: 24px;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #060531;

    @media (max-width: 768px) {
      margin: 20px 0;
    }

    @media (max-width: 460px) {
      display: none;
    }
  }

  & .categoryGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-between;
    column-gap: 24px;
    row-gap: 24px;
    text-align: center;

    @media (max-width: 1100px) {
      column-gap: 18px;
      row-gap: 18px;
    }

    @media (max-width: 1023px) {
      column-gap: 14px;
      row-gap: 14px;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 767px) {
      margin-top: 24px;
      column-gap: 11px;
      row-gap: 16px;
      grid-template-columns: repeat(2, 1fr);
    }

    & a:hover {
      text-decoration: none;
    }

    & > div {
      & a {
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        width: 100%;
        min-height: 154px;
        overflow: hidden;
        position: relative;
        @media (max-width: 767px) {
          border-radius: 10px;
        }
        & img {
          width: 100%;
          max-height: 388px;
          position: relative;
          @media (max-width: 390px) {
            max-height: 168px;
            height: 168px;
          }
          &:after {
            position: relative;
            content: '';
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              179.82deg,
              rgba(0, 0, 0, 0) 58.09%,
              rgba(0, 0, 0, 0.7) 87.61%
            );
          }
        }

        & .categoryText {
          position: absolute;
          bottom: 40px;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          padding: 40px 0;
          background: linear-gradient(
            179.82deg,
            rgba(0, 0, 0, 0) 58.09%,
            rgba(0, 0, 0, 0.7) 87.61%
          );

          @media (max-width: 767px) {
            justify-content: space-between;
            height: 100%;
            bottom: 0;
            padding: 32px 0 30px;
          }

          @media (max-width: 450px) {
            padding: 24px 0 24px;
          }

          & .categoryName {
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            text-align: center;
            color: #ffffff;
            margin-top: 16px;

            @media (max-width: 768px) {
              font-size: 14px;
              line-height: 18px;
            }

            @media (max-width: 460px) {
              position: absolute;
              bottom: 32px;
              left: 0;
              right: 0;
            }
            @media (max-width: 390px) {
              bottom: 24px;
            }
          }

          & button {
            font-weight: 700;
            font-size: 14px;
            line-height: 14px;
            color: #ffffff;
            height: 36px;
            width: auto;
            display: inline;
            padding: 11px 18px;
            border-radius: 100px;
            white-space: pre;
            @media (max-width: 767px) {
              font-weight: 600;
              font-size: 12px;
              height: 25px;
              padding: 0px 15px;
            }
          }

          & .betaText {
            background: #fea515;
            width: auto;
          }

          & .commingText {
            background: #fd4e50;
            width: auto;
          }

          & .newText {
            width: auto;
            background: #1fd1bc;
          }
        }
      }
    }
  }
}
.routeLink {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  background: #f9f9f9;
  @media (max-width: 767px) {
    /* background: #f9f9f9; */
    gap: 8px;
    height: 24px;
  }

  & svg {
    @media (max-width: 767px) {
      width: 11px;
      height: 11px;
    }
  }

  & a {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #060531;
    text-decoration: none;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 12px;
    }
  }

  & .accountSetting {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1fd1bc;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
}
