/* @import "../../styles/propertySets.css"; */

.formContent {
  margin-top: 35px;
  position: relative;

  @media (max-width: 767px) {
    margin-top: 26px;
  }

  & .redText {
    color: var(--failColor);
    margin: 6px 0 10px 0;
  }

  & span {
    position: absolute;
    top: -10px;
    left: 20px;
    padding: 0 5px 0;
    background: #fbfbfb;
    z-index: 1;
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 20px;
    color: var(--matterColor);

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 15px;
      top: -7px;
    }
  }

  & :global(.PhoneInput) {
    /* border: 1px solid #414b3b; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    position: relative;

    & input {
      border: 1px solid #d8dad9;
      height: 56px;
      padding-left: 63px;
      border-radius: 6px;

      @media (max-width: 767px) {
        height: 48px;
      }
    }

    & :global(.PhoneInputCountry) {
      width: 65px;
      padding: 14px 12px;
      overflow: hidden;
      position: absolute;
      top: -3px;
      left: 0;

      @media (max-width: 767px) {
        width: 65px;
      }

      & :global(.PhoneInputCountrySelect) {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        left: 5px;
        width: 53px;
        height: 100%;
      }

      & select {
        color: #2c2e2b;
        overflow: hidden;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        text-indent: 0.01px;
        width: 215px;
        background-image: none;
      }

      & select::-ms-expand {
        display: none;
      }
    }
  }
}