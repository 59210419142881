@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.card {
  composes: marketplaceInputStyles from global;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;

  /* Border */
  border-bottom-color: var(--attentionColor);

  @media (--viewportMedium) {
    height: 38px;
  }
  @media (--viewportLarge) {
    height: 38px;
    padding: 6px 0 14px 0;
  }
}
.messageContainer{
  & textarea{
    padding: 16px;
    box-sizing: border-box;
    height: 100px !important;
  }
}
.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: rgb(31 64 59 / 80%);
  @media (max-width: 767px) {
    font-weight: 700;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.02em;
color: #060606;
  }
}

.paymentMethodSelector {
  margin-bottom: 36px;

  @media (max-width: 767px) {
    margin-bottom: 16px !important;
  }
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
    margin-bottom: 48px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}
.saveForLaterUse input[type='checkbox'] + label:before{
margin-right: 6px;
padding-left: 4px;
@media (max-width: 767px) {
  padding-left: 1px;
}
}
.saveForLaterUseLabel {
  composes: h5 from global;

  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  composes: h5 from global;

  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 31px;
  margin-top: -2px;
  margin-bottom: 2px;
@media (max-width: 767px) {
  padding: 0px 0 0px 24px;
}
  @media (--viewportMedium) {
    margin-top: -1px;
    margin-bottom: 1px;
  }
}

.billingHeading {
  margin: 0 0 12px 0;
  color: var(--matterColorAnti);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    margin-top: 0px;
    margin-bottom: 2px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 36px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 41px;
  }
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px 20px 32px;
    z-index: 100;
    background: #ffffff;
  }
}
.addPaymentBtn{
  font-weight: 700;
font-size: 16px;
line-height: 20px;
color: #1FD1BC;
background: transparent;
margin-left: 0;
text-align: left;
white-space: pre;
text-transform: capitalize;
width: 100%;
@media (max-width: 767px) {
  font-weight: 700;
font-size: 14px;
line-height: 18px;
color: #1FD1BC;
height: 24px;
margin: 0 0 12px;
}
&:hover{
  background-color: unset;
  color: #1FD1BC;
  box-shadow: unset;
}
}
.subTitle{
  font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #1F403B;
opacity: 0.8;
margin: 16px 0px 10px;
text-transform: capitalize;
 @media (max-width: 767px) {
  font-weight: 700;
font-size: 16px;
line-height: 20px;
letter-spacing: 0.02em;
color: #060606;
 }
}
.paymentInfo {
  composes: h5 from global;

  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 0 0;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }
}

.submitButton {
  margin-top: 12px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #FFFFFF;
  @media (--viewportLarge) {
    margin-top: 17px;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}

.missingStripeKey {
  color: var(--failColor);
}
