@import '../../styles/customMediaQueries.css';

.leaveAtDoor input {
  margin-left: 5px;
  border: 2px solid var(--marketplaceColor);
  background-color: white;
}

.deliveryPopup {
  position: absolute;

  background: #ffffff;
  padding: 42px 22px 35px;
  z-index: 10;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
  width: 416px;
  border-radius: 35px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & svg {
    color: #c1c1ca;
    width: 16px;
    height: 16px;
  }

  & label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-family: var(--axiformaregular);
    > div {
      margin-top: -2px;
    }
  }

  & hr {
    color: #c1c1ca;
  }

  & input[type='radio'] {
    appearance: none;
    width: 20px;
    height: 20px !important;
    border-radius: 50%;
    background-clip: content-box;
    border: 2px solid #c1c1ca;
    background-color: white;
    flex-shrink: 0;
  }

  & input[type='radio']:checked {
    background-color: var(--marketplaceColor);
    padding: 4px;
    border: 2px solid var(--marketplaceColor) !important;
  }

  & .listingStep2 {
    & .container {
      @media (max-width: 767px) {
        min-height: auto !important;
        height: auto !important;
      }
    }
  }

  & .deliveryContent {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    height: 501px;

    &:after {
      position: absolute;
      content: '';
      top: -47px;
      right: -29px;
      height: 40px;
      width: 40px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='m7 14l5-5l5 5Z'/%3E%3C/svg%3E");
    }
  }

  & hr {
    border: none;
    background: #c1c1ca;
    height: 2px;
  }

  @media (max-width: 767px) {
    top: auto;
    bottom: 0;
    right: 0;
    width: 100%;
    left: auto;
    transform: none;

    & .deliveryContent {
      height: 100%;

      &:after {
        display: none;
      }
    }
  }

  & > h4 {
    display: flex;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 35px;
    align-items: center;
    justify-content: space-between;
    font-family: var(--axiformasemibold);

    & button {
      background-color: white;
      width: 30px;
      height: 30px;
      margin: 0;
    }

    & button:hover {
      box-shadow: none;
    }

    & svg {
      cursor: pointer;
      stroke: none;
      width: 19px;
      height: 19px;
      color: #fd4e50;
    }
  }
}

/* Delivery Popup */
.deliveryPopup input {
  accent-color: var(--marketplaceColor);
}

.deliveryPopup input::placeholder {
  color: #c1c1ca;
}

.deliveryPopup .h4 {
  margin: 20px 0;
}

.inputWrapper {
  border: solid 2px #c1c1ca;
  border-radius: 4px;
  padding: 19px 13px 17px 15px;
  display: flex;
  gap: 10px;
  height: 60px;
  align-items: center;
  p {
    flex: 1;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding-top: 5px;
    font-family: var(--axiformasemibold);
  }
}

.inputWrapper input {
  padding: 5px 0 0;
  border: none;
  font-size: 16px;
  height: auto !important;
  font-family: var(--axiformaregular);
}

.method,
.leaveAtDoor {
  margin-top: 15px;
}

.method label {
  display: flex;
  align-items: center;
  gap: 5.5px;
  color: #060531;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: -8px 0 0 11px;
  padding: 0 3px 0 5px;
  font-family: var(--axiformaregular);
}

.method label > div {
  margin-top: -2px;
}

.method input:focus {
  border: none !important;
}

.leaveAtDoor {
  display: flex;
  justify-content: space-between;
}

.leaveAtDoor div {
  display: flex;
  gap: 7px;
}

.leaveAtDoor div input {
  margin-top: -2px;
}

.leaveAtDoor div p {
  font-size: 14px;
  padding-top: 1px;
  font-family: var(--axiformaregular);
}

.saveButton {
  width: 100%;
  border: none;
  background-color: var(--marketplaceColor);
  color: white;
  margin-top: 20px;
  font-size: 18px;
  padding: 10px 0;
  font-weight: 500;
}

.primaryColor {
  color: var(--marketplaceColor);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-top: 5px;
  font-family: var(--axiformaregular);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.divider {
  h4 {
    display: flex;
    color: #c1c1ca;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 16px;
    font-family: var(--axiformaregular);
    span {
      padding: 0 10px;
      background: #fff;
    }
    &::after {
      content: '';
      z-index: -1;
      width: 100%;
      height: 1.5px;
      position: absolute;
      background: #c1c1ca;
    }
  }
}

.modalContainer {
  & .modalSubTitle {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 24px 0 0;
  }

  & .paragraphList {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  & h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #060531;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & .firstParagraph {
    margin: 16px 0 8px;

    @media (max-width: 767px) {
      margin: 8px 0 16px;
    }
  }

  & h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: rgba(31, 64, 59, 0.8);

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  & h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #1f403b;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & .modalTitle {
    display: flex;
    gap: 16px;

    @media (max-width: 767px) {
      gap: 7px;

      & > svg {
        width: 24px;
        height: 24px;
        margin: -2px 0 0;
      }
    }
  }
}

.addressInput {
  flex: 1;

  & form {
    height: auto !important;
  }

  & form > div {
    height: auto !important;

    & .desktopPredictions {
      display: none !important;
    }
  }

  & form > div > div:first-child {
    display: none;
  }

  & form > div > div:last-child {
    padding: 10px;

    & li {
      border-bottom: 1px solid #c1c1ca;
    }

    & li:hover {
      background-color: #f5f5f5;
    }
  }

  & form input {
    padding: 5px 0 0 !important;
    background: white !important;
    color: black;
    font-family: var(--axiformasemibold);
  }
}

.method label {
  position: absolute;
  background: white;
}

.infoModal {
  gap: 10px;
  display: flex;
}
.infoModal .infoIcon {
  display: flex;
}
.infoModal .infoIcon svg {
  width: 25px;
  height: 25px;
}
