.root {
  position: unset;
  display: inline-block;
}

.label {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  padding: 0;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  position: absolute;
  top: 10px;
  right: 104px;
  z-index: 0;
  @media (max-width: 1023px) {
    top: 9px;
    right: 84px;
  }
  & svg {
    @media (max-width: 1023px) {
      width: 32px;
      height: 32px;
    }
  }
  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.labelApple{
  border: 1px solid #EAEAEA;
  padding: 4px;
  aspect-ratio: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-width: 100% !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 10px !important;
  margin: initial !important;
}

.labelSelected {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}
.labelEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  min-width: 416px;
  /* max-width: 416px; */
  margin-top: 7px;
  background-color: var(--matterColorLight);
  left: unset !important;
  right: 106px;
  top: 49px;
  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
  @media (max-width: 1023px) {
    right: 83px;
    top: 44px;
  }
  @media (max-width: 767px) {
    min-width: 300px;
    left: 0px !important;
    top: 43px;
    position: fixed;
    top: 0;
    right: 0 !important;
    left: 0 !important;
    bottom: 0;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.518);
    z-index: 110;
    margin: 0;
    border: none;
    border-radius: 0;
  }
  /* &:after{
position: absolute;
top: 0;
right: 0;
left: 0;
bottom: 0;
background: gray;
content: "";
  } */
}

.popupSize {
  padding: 15px 30px 17px 30px;
}

.isOpen {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  & form {
    position: relative;
    background: #fff;
    border-radius: 10px;
    &:after {
      @media (max-width: 767px) {
        display: none;
      }
      position: absolute;
      content: '';
      top: -23px;
      right: -2px;
      height: 40px;
      width: 40px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='m7 14l5-5l5 5Z'/%3E%3C/svg%3E");
    }
  }
}
