@import '../../styles/customMediaQueries.css';

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}
.checkboxWrapper {
  cursor: pointer;
  position: relative;
  font-family: var(--font-familyLite);
  width: 72px;
  height: 32px;
  background: #d8dad9;
  opacity: 0.72;
  border-radius: 20px;
  @media (max-width: 767px) {
    width: 48px;
    height: 24px;
  }
}
.hourRentals {
  display: flex;
  align-items: center;
  max-width: 400px;
  justify-content: space-between;
  margin: 0 0 8px;
  @media (max-width: 767px) {
    margin: 0 0 12px;
  }
  & .rentalsText {
    font-weight: 700;
    font-size: 16px;
    line-height: 137%;
    color: #060531;
    @media (max-width: 767px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 137%;
      color: #060531;
    }
  }
}
.ball {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 4px;
  left: 4px;
  transition: transform 0.5s ease;
  background: #ffffff;

  border-radius: 13.5px;
  @media (max-width: 767px) {
    width: 18px;
    height: 18px;
    top: 3px;
    left: 3px;
  }
  &.toggled {
    transform: translateX(167%);
    background: #1fd1bc;
    @media (max-width: 767px) {
      transform: translateX(135%);
    }
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (min-width: 768px) {
    display: inline-block;
    width: 186px;
    margin-top: 12px;
  }
}

.description {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1f403b;
  opacity: 0.8;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: rgba(31, 64, 59, 0.6);
  }
}
.noteContents {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #060531;
  opacity: 0.8;
  margin: 12px 0 24px;
  @media (max-width: 767px) {
    letter-spacing: -0.02em;
    margin: 16px 0 24px;
    color: rgba(31, 64, 59, 0.6);
  }
  & .noteText {
    font-weight: 700;
    color: #060531;
  }
}
.inputsContents{
  display: flex;
  align-items: center;
  gap: 24px;
  @media (max-width: 767px) {
    flex-direction: column;
    gap: 16px;
  }
  & svg{
    top: 50px;
    right: 24px;
  }
  & label{
    position: unset;
    margin: 0 0 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    background: #ffffff;
    @media (max-width: 767px) {
      margin: 0 0 8px;
      font-size: 14px;
      line-height: 18px;
    }
  }
  &>div{
    width: 100%;
  }
}
.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  /* top: 0; */
  z-index: 10;
  padding: 24px 0;
  background: #ffffff;
  box-shadow: 0px 1px 24px rgb(31 209 188 / 10%);

  @media (max-width: 767px) {
    padding: 0;
    gap: 0;
  }

  & button:first-child {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    color: #1f403b;
    opacity: 0.6;
  }

  & button {
    margin: 0;

    @media (max-width: 767px) {
      height: 56px;
      width: 50%;
      border-radius: 0;
    }
  }
}
