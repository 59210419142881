@import '../../styles/customMediaQueries.css';

.root {
  display: none;

  /* Borders */
  outline: none;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  padding: 15px 30px 20px 30px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.isOpenAsPopup {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.plain {
  width: 100%;
  @media (max-width: 767px) {
    max-width: 100% !important;
    min-width: 250px !important;
  }
}

.isOpen {
  display: block;
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0;
  padding: 0;
  & .minInput{
    & input{
      text-align: right;
      margin-left: unset;
    }
    position: relative;
    margin-right: 12px;
    &:after{
      position: absolute;
      content: "$";
      top: -6px;
      right: -10px;
      color: #1F403B;
      z-index: 100;
      @media (max-width: 767px) {
        top: 0px;
        right: -8px;
        font-size: 12px !important;
        line-height: 15px !important;
      }
    }
  }
  & .maxInput{
    position: relative;
    margin-right: 12px;
    &:after{
      position: absolute;
      content: "$";
      top: -6px;
      right: -10px;
      color: #1F403B;
      z-index: 100;
      @media (max-width: 767px) {
        top: 0px;
        right: -8px;
        font-size: 12px !important;
        line-height: 15px !important;
      }
    }
  }
}

.label {
  composes: h5 from global;

  font-weight: var(--fontWeightSemiBold);
  padding: 11px 0 13px 0;
  margin: 0 18px 0 0;
}
@media (--viewportMedium) {
  .label {
    padding: 8px 0 16px 0;
  }
}

.inputsWrapper {
  display: flex;
  justify-content: space-between;
 width: 100%;
 & input{
  width: 100% !important;
  font-weight: 500 !important;
font-size: 16px !important;
line-height: 20px !important;
opacity: unset !important;
color: #1F403B !important;
padding-left: 0 !important;
padding: 0;
@media (max-width: 767px) {
  font-size: 12px !important;
line-height: 15px !important;
}
&:hover, &:focus{
  border: unset;
}
 }
}

.minPrice {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  width: 48px;
  text-align: left;
  border: none;

  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}
.maxPrice {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  width: 48px;
  text-align: right;
  border: none;

  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.priceSeparator {
  margin: 4px 8px 0 8px;
}

@media (--viewportMedium) {
  .priceSeparator {
    margin: 2px 8px 0 8px;
  }
}

.sliderWrapper {
  display: flex;
  padding: 0px 0 8px 0;
  @media (max-width: 767px) {
    padding: 0px 0 3.8px 0;
  }
}

.buttonsWrapper {
  display: flex;
}

.clearButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}
.inputWrapper{
  border: 1px solid var(--attentionColor);
  border-radius: 6px;
  padding: 4px 8px 4px 0;
}
.cancelButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
    transition: width var(--transitionStyleButton);
  }
}
