@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  background: transparent;
  @media (--viewportMedium) {
    position: sticky;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 11;
  }
  /* @media (max-width: 767px) {
    display: none;
  } */
}
.mapTopbar{
  display: none;
}
.mapPanel {
  display: block;
}

.searchBoxWrapper {
  margin-top: 75px;
}

.modalButtons {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    gap: 11px;
  }

  & button {
    max-width: 144px;
    height: 40px;
    border: none;
    background: #1fd1bc;
    border-radius: 6px;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: #ffffff;

    @media (max-width: 767px) {
      max-width: 162px;
      font-size: 14px;
      line-height: 18px;
    }

    &:first-child {
      color: #060531;
      background: #ffffff;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }
  }
}

.filterMapButton {
  position: absolute;
  top: 10px;
  right: 60px;

  @media (max-width: 1023px) {
    top: 9px;
    right: 48px;
  }

  & svg {
    @media (max-width: 1023px) {
      width: 32px;
      height: 32px;
    }
  }
}

.filterMapButtonApple {
  border: 1px solid #EAEAEA;
  padding: 4px;
  aspect-ratio: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-width: 100% !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 10px !important;
  margin: initial !important;
}


.mainContainer {}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;
  gap: 22px;
  margin: auto;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  margin: 0 20px;
  padding: 80px 0 82px 0;
  max-width: 1306px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1500px) {
    max-width: 1216px;
  }

  @media(max-width: 1336px) {
    max-width: 100%;
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (max-width: 1250px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 900px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 767px) {
    padding: 0;
  }
}
.mapView{
  padding: 0 !important;
}
.mapMainContainer{
  overflow: hidden;
  max-width: 1920px;
  min-height: 100vh;
  @media (max-width: 767px) {
    overflow: unset;
  }
  & .container{
    max-width: 100%;
    @media (min-width: 768px) {
      margin-left: 20px;
      margin-right: 0;
    }
  
    @media (min-width: 900px) {
      margin-left: 40px;
      margin-right: 0;
    }
  
    /* @media (min-width: 1250px) {
      margin-left: 60px;
      margin-right: 0;
    } */
    @media (min-width: 1336px) {
      margin-left: 0px;
      margin-right: 0;
    }
  
    @media (min-width: 1501px) {
      padding: 80px 0 82px calc((100% - 1305px) / 2);
    }
    @media (max-width: 1500px) {
      padding: 80px 0 82px calc((100% - 1220px) / 2);
    }
  
    @media (max-width: 1023px) {
      grid-column-gap: 11px;
      grid-row-gap: 16px;
    }
    @media (max-width: 767px) {
      padding: 0;
      padding-top: 0;
      margin: 0;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* max-width: 596px; */
  /* width: 50%; */
  max-width: 100%;
  width: 100%;


  @media (min-width: 768px) {
   /* overflow: hidden; */
  }
  @media (max-width: 767px) {
    padding: 0 20px 20px;
  }
}
.showListContainer{
  max-width: 596px;
  width: 45%;
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 20px 20px;
    margin: 0 0 72px;
  }
}
.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersMobile {
  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  position: absolute;
  top: 100%;
  background: transparent;
  z-index: 10000;
  flex-grow: 1;
  display: flex;
  right: 20px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
  border-radius: 6px;
  @media (max-width: 1023px) {
    right: 11px;
  }
  @media (max-width: 767px) {
    box-shadow: none;
    position: unset;
    padding: 0;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.96;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  width: 49.5%;
  flex-basis: 50%;

  /* Own layout settings */
  display: flex;
  height: 100%;
  position: fixed;
  right: 0;
  top: 80px;
  @media (max-width: 767px) {
    top: 68px;
    width: 100%;
  }
}

.sortBy {
  margin-right: 9px;
  @media (max-width: 767px) {
    height: 18px;
  }
  & button{
    font-weight: 500;
    font-size: 16px;
    color: #1F403B;
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
  & :global(.mapboxgl-ctrl-top-left) {
   @media (max-width: 767px) {
    display: none;
   }
}
  & .listButton {
    position: fixed;
    right: 0;
    left: 0;
    margin: 0 auto;
    bottom: 32px;
    background: #1fd1bc;
    box-shadow: 0px 0px 8px 6px rgb(0 0 0 / 3%);
    border-radius: 10px;
    max-width: 80px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
   z-index: 10;
    @media (min-width: 768px) {
      display: none;
    }

    & p {
      font-weight: 700;
      font-size: 14px;
      line-height: 12px;
      color: #ffffff;
    }
  }
}

.modalContainer {
  composes: marketplaceModalBaseStyles from global;
  min-height: 100vh;
  width: 100%;
  min-height: 557px;
  padding: 24px 24px 48px;
  background-color: #ffffff;
  flex-basis: 416px;
  min-height: auto;

  @media (max-width: 767px) {
    min-height: 469px;
    padding: 38px 20px 32px;
    border-radius: 16px 16px 0px 0px;
    max-width: 95%;
  }

  &>button {
    padding: 18px 20px;

    & svg {
      color: #fd4e50;
    }
  }
}
.searchBarMain{
  overflow: visible;
}
.map {
  width: 100%;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    /* position: fixed; */
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 63.3vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  & canvas {
    max-width: 100%;
  }
}
.locationDropdown {
  position: absolute;
  top: 56px;
  right: 64px;
  background: #ffffff;
  padding: 24px 24px 32px;
  z-index: 10;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 30%);
  width: 416px;
  border-radius: 6px;
  @media (max-width: 1023px) {  
    top: 52px;
    right: 49px;
  }
  & .locationContent{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    height: 501px;
    @media (max-width: 1300px) {
      height: 410px;
    }
    &:after{
      position: absolute;
      content: "";
      top: -47px;
      right: -29px;
      height: 40px;
      width: 40px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='m7 14l5-5l5 5Z'/%3E%3C/svg%3E");
    }
  }
}
.searchContents {
  & h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    color: #060606;
    margin: 0 0 16px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.isLandingPage{
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
:global(.gmnoprint){
  display: none !important;
}

.locationModal {
  max-width: unset;
  margin-top: auto;
}