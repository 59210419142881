@import '../../styles/customMediaQueries.css';

.root {
  display: none;

  /* Borders */
  outline: none;
  margin: 0 0 24px;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  padding: 15px 30px 20px 30px;
  margin-top: 7px;
  background-color: var(--matterColorLight);

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.isOpenAsPopup {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.plain {
  width: 100%;
  @media (max-width: 767px) {
    max-width: 100% !important;
    min-width: 250px !important;
  }
}

.isOpen {
  display: block;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 0;
  padding: 0;
& .minInput{
  & input{
    text-align: right;
  }
  margin-right: 44px;
  position: relative;
  &:after{
    position: absolute;
    content: "Miles";
    top: -6px;
    right: -40px;
    color: #1F403B;
    z-index: 100;
    @media (max-width: 767px) {
      top: 0px;
    right: -31px;
      font-size: 12px !important;
      line-height: 15px !important;
    }
  }
}
& .maxInput{
  position: relative;
  
  margin-right: 44px;
@media (max-width: 767px) {
  margin-right: 34px;
}
  &:after{
    position: absolute;
    content: "Miles";
    top: -6px;
    right: -41px;
    color: #1F403B;
    z-index: 100;
    @media (max-width: 767px) {
      top: 0px;
      right: -31px;
      font-size: 12px !important;
      line-height: 15px !important;
    }
  }
}
  & input {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    opacity: unset;
    color: #1f403b;
    padding-left: 0;
    width: fit-content;
  }
}

.label {
  composes: h5 from global;

  font-weight: var(--fontWeightSemiBold);
  padding: 11px 0 13px 0;
  margin: 0 18px 0 0;
}
@media (--viewportMedium) {
  .label {
    padding: 8px 0 16px 0;
  }
}

.inputsWrapper {
  display: flex;
  justify-content: space-between;
  & input{
    padding: 0;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    opacity: unset !important;
    color: #1F403B !important;
    @media (max-width: 767px) {
      font-size: 12px !important;
    line-height: 15px !important;
    }
    &:hover, &:focus{
      border: unset;
    }
  }
}

.minPrice {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  width: 48px;
 border: none;
  text-align: left;
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}
.maxPrice {
  composes: marketplaceSearchFilterSublabelFontStyles from global;
  width: 48px;
  text-align: right;
  border: none;

  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media (--viewportMedium) {
    padding: 1px 0 4px 0;
  }
}

.priceSeparator {
  margin: 4px 8px 0 8px;
}

@media (--viewportMedium) {
  .priceSeparator {
    margin: 2px 8px 0 8px;
  }
}

.sliderWrapper {
  display: flex;
  padding: 0px 0 8px 0;
  @media (max-width: 767px) {
    padding: 0px 0 3px 0;
  }
}
.inputWrapper{
  border: 1px solid var(--attentionColor);
  border-radius: 6px;
  padding: 4px 8px 4px 0;
}
.buttonsWrapper {
  display: flex;
}

.clearButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.cancelButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitButton {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
    transition: width var(--transitionStyleButton);
  }
}
