.main {
  background: #ffffff;  
  border-radius: 6px;
}
.mainVocation {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  gap: 24px;
  @media (max-width: 767px) {
    gap: 16px;
    margin: 24px 0 32px;
  }
}

.totalAmount {
  display: flex;
}
.icon {
  margin-right: 20px;
}
.view {
  border: 1px solid #1fd1bc;
  border-radius: 6px;
  color: #1fd1bc;
}
.earnings {
  width: 30%;
  margin-right: 35px;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
  padding: 30px;
}

.mainEarnings {
  display: flex;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
}

.monthly {
  width: 70%;
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
}
.monthly p {
  padding: 30px 0px 30px 0px;
}
.mainCalender {
  display: flex;
  width: 100%;
}

.monthOne {
  width: 100%;

  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
}

.monthTwo {
  width: 100%;

  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
}

.viewAll {
  text-align: center;
}
.viewAll button {
  border: none;
  color: white;
  width: 474px;
  height: 40px;
  background: #1fd1bc;
  border-radius: 6px;
}
.bottomCard {
  display: flex;
  width: 100%;
}
.card {
  padding: 40px;
  width: 500px;
  height: 162px;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
}
.ball {
  position: absolute;
  border-radius: 50px;
  width: 21px;
  height: 21px;
  top: 3px;
  left: 5px;
  transition: transform 0.5s ease;
  background-color: #ffffff;
  @media (max-width: 768px) {
    /* width: 90px;
    height: 45px;
    top: -3px;
    left: -1px; */
  }
  &.toggled {
    transform: translateX(140%);
  }
}
.checkboxWrapper {
  cursor: pointer;
  position: relative;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 62px;
  height: 30px;
  border: 1px solid #1fd1bc;
  background: #1fd1bc;
  @media (max-width: 768px) {
    /* height: 40px; */
  }
}
.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
  height: 100%;
}
