.root {
  position: relative;
}
.root label {
  position: absolute;
  top: -10px;
  left: 20px;
  padding: 0 5px 0;
  background: #fbfbfb;
  z-index: 1;
  @media (max-width: 767px) {
    top: -7px;
  }
}

.searchBar {
  border: 1px solid #EAEAEA;
  /* margin: 10px 0; */
  height: 40px;
}


.input {
  border: 1px solid var(--attentionColor);
  border-radius: 6px;
  background: #fbfbfb;
  height: 56px;
  padding: 0 0 0 16px;
  color: #1f403b;
  @media (max-width: 767px) {
    height: 48px;
  }
  &::placeholder { 
    color: #1F403B;
    opacity: 0.8;
    font-size: 16px;
  }
  &:hover, &:focus {
    border: 2px solid #F2B358;
    outline: none;
  }
}

.inputApple {
  border-bottom: 0px;
  &::placeholder { 
    color: #000;
    opacity: 0.3;
    font-size: 10px;
  }
  &:hover, &:focus {
    outline: none;
  }
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}
