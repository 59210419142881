@import '../../styles/customMediaQueries.css';

/* ModalInMobile is visible by default */
.root {
  width: 100%;
}

/* Content is hidden in Mobile layout */
.modalHidden {
  display: block;
  @media (max-width: 767px) {
   overflow: unset;
    position: relative;
    top: unset;
    padding: unset;
  }
}
.modalshow {
  display: block;
  @media (max-width: 767px) {
   display:none !important
  }
}
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}
.isOwnListing{
 &>div{
  padding: 0 !important;
 }
}
.modalContent {
  width: 100%;

  @media (--viewportMedium) {
    height: 100%;

    /* ModalInMobile content should work with box-shadows etc. */
    overflow: visible;
  }
}
