@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  margin: 0px auto 0px auto;
  @media (max-width: 1336px) {
    max-width: 100%;
    padding-left: 60px;
  }
  @media (max-width: 1250px) {
    padding-left: 56px;
  }
  @media (max-width: 1023px) {
    padding-left: 40px;
  }
  @media (max-width: 900px) {
    padding-left: 20px;
  }

  @media (max-width: 767px) {
    position: relative;
    /* z-index: 0; */
    padding: 0px;
    margin: 0px auto 88px auto;
  }
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;

  display: flex;
    flex-direction: row;
    justify-content: center;
}

.nav {
  /* Layout */
    min-width: 296px;
    padding-top: 48px;
    @media (min-width: 768px) {
      width: 32%;
      padding: 48px 0 160px calc((100% - 1184px) / 2);
    }
    @media (min-width: 1024px) {
      width: 22%;
      min-width: 200px;
    }
    @media (min-width: 1336px) {
      width: 32%;
    }
    @media (min-width: 1500px) {
      width: 30%;
      padding: 48px 0 82px calc((100% - 1270px) / 2);
    }
    @media (min-width: 1860px) {
      width: 37%;
    }
    @media (max-width: 1023px) {
      min-width: 200px;
      max-width: 240px;
    }
    /* @media (max-width: 767px) {
      display: none;
    } */
  @media (max-width: 1023px) {
    display: none;
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;
  position: relative;
  & a {
    margin: 0;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: capitalize;
    color: #060531;
    color: rgb(6, 5, 49, 0.8);
    background: none;
    &:hover {
      font-weight: 700;
      color: #060531;
      background-image: unset;
    }
  }
  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.panel {
  /* padding: 24px 32px; */
  background: #fff;
  @media (min-width: 768px) {
   padding-bottom: 100px;
  }
  @media (min-width: 1023px) {
    width: 75%;
    padding: 24px calc((100% - 1249px) / 2) 279px 32px;
  }
  @media (min-width: 1336px) {
    width: 68%;
  }
  @media (max-width: 767px) {
    padding: 0px 20px;
    width: 100%;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}
