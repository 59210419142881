@import '../../styles/customMediaQueries.css';

.inputRoot {
  /* Override react-dates default styles to match input styles */

  & :global(.DayPicker_transitionContainer) {
   @media (max-width: 767px) {
    overflow: visible !important;
   }
  }
  & :global(.SingleDatePicker) {
    display: block;
  }
  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 36px !important;
    width: 100%;
    min-height: calc(100vh - 252px);
    background-color: transparent;
z-index: 20;
    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      min-height: auto;
      /* box-shadow: var(--boxShadowPopup); */
      border-radius: 0 0 2px 2px;
    }
  }

  & :global(.DateInput_input) {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;

    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 0;

    /* Borders */
    border: none;
    border-radius: 0;
    /* border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: var(--attentionColor); */

    transition: border-bottom-color var(--transitionStyle);
    transition: all 0.15s ease-out;
    background: none;
    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke="%234A4A4A" transform="translate(1 1)"><rect stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" x=".292" y="1.459" width="13.417" height="12.25" rx="1"/><path d="M3.208.292V3.21M10.792.292V3.21M.292 4.376h13.416" stroke-linecap="round" stroke-linejoin="round"/><rect x="3.5" y="6.5" width="1" height="1" rx=".5"/><rect x="6.5" y="6.5" width="1" height="1" rx=".5"/><rect x="9.5" y="6.5" width="1" height="1" rx=".5"/><rect x="3.5" y="9.5" width="1" height="1" rx=".5"/><rect x="6.5" y="9.5" width="1" height="1" rx=".5"/><rect x="9.5" y="9.5" width="1" height="1" rx=".5"/></g></g></svg>');
    background-repeat: no-repeat;
    background-position: 0 10px;
    padding-left: 24px;

    &::placeholder {
      color: var(--matterColorAnti);
    }
    @media (--viewportMedium) {
      line-height: 32px;
      padding: 4px 0 1px 26px;
      border-bottom-width: 3px;
    }
  }

  & :global(.SingleDatePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }
  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 6px;
  @media (max-width: 767px) {
    box-shadow: none;
  }
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 25px;
    position: absolute;
    cursor: pointer;
    display: inline;
    background-size: contain;
    height: 27px;
    width: 27px;
    &:first-of-type {
      left: 46px;
      @media (max-width: 767px) {  
        right: -6px;
    left: auto;
      }
      background: url('https://api.iconify.design/material-symbols/chevron-left.svg?width=40') no-repeat center center / contain;
    }

    &:last-of-type {
      right: 44px;
      @media (max-width: 767px) {
        right: -34px;
      }
      background: url('https://api.iconify.design/material-symbols/chevron-right.svg?width=40') no-repeat center center / contain;
    }
  }

  & :global(.DayPickerNavigation_button) {
    color: var(--matterColorLight);
    border: 0;
  }

  & :global(.CalendarMonthGrid) {
@media (max-width: 767px) {
  width: max-content;
  display: grid;
}
  }
  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: #ffffff;
    padding-bottom: 80px;
    /* transition: transform 50ms cubic-bezier(0.67, 1.04, 0.54, 1.04) 0s !important; */
  }
  & :global(.DateInput) {
    display: block;
    width: 100%;
  }
  & :global(.DayPicker_weekHeader) {
    color: #060531;
    top: 57px;
  }
  & :global(.DayPicker_weekHeader) {
    &:not(:first-child){
      display: none !important;
    }
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    @media (max-width: 767px) {
      width: 49px !important;
    }
  }
  & :global(.DayPicker_weekHeader_ul) {
    @media (max-width: 767px) {
      width: 345px;
    }
  }

  & :global(.CalendarMonth_caption) {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #060531;

    margin: 1px 0 14px;
    padding-bottom: 24px;
    @media (--viewportMedium) {
      line-height: 32px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__default) {
    /* background-color: unset; */
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    color: #888888 !important;
  }
  & :global(.CalendarDay__default_2) {
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    color: #060531 !important;
  }
  & :global(.CalendarDay__today) {
    color: #000000 !important;
  }
  & :global(.CalendarDay) {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;

    color: var(--matterColorLight);
    border: 0;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 100px;
    @media (max-width: 767px) {
      width: 49px !important;
      height: 45px !important;
    }
    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23060531' d='M19 13H5v-2h14v2Z'/%3E%3C/svg%3E");
    background-position: 7px 17px;
    @media (max-width: 767px) {
      background-position: 13px 17px;
    }
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-image: transparent;
    background-color: transparent;
  }
  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: #1FD1BC;
    transition: all 0.2s ease-out;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected) {
    background-color: transparent;
    background: transparent !important;
    background-image: none;
  }
  & :global(.CalendarDay__selected .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: #1FD1BC;
    border-radius: 100px;
    &:hover{
      background-color: #1FD1BC !important;
    }
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__after-hovered) {
    background-color: transparent;
  }
  & :global(.CalendarDay__after-hovered .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    /* background-color: var(--DateInput_hoveredOverlayColor); */
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: rgba(31, 64, 59, 0.8);
    border: 0;
  }
  &
    :global(.CalendarDay__blocked_out_of_range
      .CalendarDay__blocked_calendar
      .renderedDay) {
    background-color: transparent;
  }
  & :global(.DateInput_fang) {
    display: none;
  }
  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
    @media (max-width: 767px) {
      text-align: left;
      margin-left: 16px;
    }
  }

  & :global(.DateInput__disabled) {
    background: none;
  }
  & :global(.DateInput_input__disabled) {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;

    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;
    padding: 5px 0 5px 24px;

    /* Borders */
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: var(--matterColorNegative);
    border-radius: 0;

    /* Effects */
    transition: border-bottom-color var(--transitionStyle);
    transition: all 0.15s ease-out;

    background: none;
    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke="%23E6E6E6" transform="translate(1 1)"><rect stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" x=".292" y="1.459" width="13.417" height="12.25" rx="1"/><path d="M3.208.292V3.21M10.792.292V3.21M.292 4.376h13.416" stroke-linecap="round" stroke-linejoin="round"/><rect x="3.5" y="6.5" width="1" height="1" rx=".5"/><rect x="6.5" y="6.5" width="1" height="1" rx=".5"/><rect x="9.5" y="6.5" width="1" height="1" rx=".5"/><rect x="3.5" y="9.5" width="1" height="1" rx=".5"/><rect x="6.5" y="9.5" width="1" height="1" rx=".5"/><rect x="9.5" y="9.5" width="1" height="1" rx=".5"/></g></g></svg>');
    background-repeat: no-repeat;
    background-position: 0 10px;

    &:hover {
      border-bottom-color: var(--matterColorNegative);
    }

    @media (--viewportMedium) {
      line-height: 32px;
      padding: 4px 0 9px 24px;
      border-bottom-width: 3px;
    }
  }

  & :global(.DateInput_input__disabled::placeholder) {
    color: var(--matterColorNegative);
    font-style: normal;
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
  & :global(.SingleDatePickerInput) {
    /* width: calc(100vw - 48px); */
    /* margin: 0 24px; */

    @media (--viewportMedium) {
      width: 100%;
      margin: 0;
    }
  }

  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 34px !important;
    /* border-top: 2px solid var(--matterColorDark); */
    min-height: calc(100vh - 252px + 2px);
@media (max-width: 767px) {
  top: -14px !important;
  left: -4% !important;
  min-height: auto;
  background: #ffffff;
}
@media (max-width: 450px) {
  left: -7% !important;
}
@media (max-width: 400px) {
  left: -12% !important;
}
@media (max-width: 370px) {
  left: -15% !important;
}
    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      border-top: 0;
      min-height: auto;
    }
  }

  /* Create gutter between inputs */
  & :global(.DateInput) {
    width: 100%;
    background: none;
  }
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}
