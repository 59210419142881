@media screen and (min-width: 1340px) {
  .main_wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    gap: 30px;
    width: 100%;
  }
  .left_boxes {
    min-width: fit-content;
  }
}

.profileHeader {
  display: flex;
  gap: 20px;
  box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
  padding: 20px;
  border-radius: 6px;

  @media (max-width: 1340px) {
    display: none;
  }
}

.profilePic {
  width: 100px;
  min-width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;

  @media (max-width: 768px) {
    width: 85px;
    min-width: 85px;
    height: 85px;
  }
}

.profileTitle {
  padding: 0;
  margin: 0;
}

.ratingBox {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;

  & span {
    font-weight: bold;
  }
}

.verifiedBox {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.verifiedIcon {
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #cff3ef;
  padding: 3px 7px;
  font-size: 12px;
  border-radius: 3px;
}

.verifiedIcon > span {
  color: #1fd1bc;
  font-weight: 500;
}

.social_icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;

  @media (max-width: 768px) {
    gap: 5px;
  }
}

.main {
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
  width: 100%;
  border-radius: 6px 6px 0 0;
  padding: 24px 48px;
  margin: 0 0 48px;
  @media (max-width: 1340px) {
    margin: 30px 0 20px;
  }
  border-bottom: 5px solid #ffdc96;
  @media (max-width: 767px) {
    margin: 20px 0 16px;
    padding: 12px;
  }
  & .totalIncomeWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    & .icon {
      /* background: #ffdc96; */
      background: #fea515;
      height: 30px;
      width: 30px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  & .view {
    border: 1px solid #1fd1bc;
    border-radius: 6px;
    color: #1fd1bc;
    height: 40px;
    width: 161px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    @media (max-width: 1023px) {
      width: 92px;
      height: 30px;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.02em;
    }
  }
  & .totalAmount {
    display: flex;
    gap: 16px;
    @media (max-width: 1023px) {
      gap: 8px;
    }
    & h6 {
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #1f403b;
      margin: 0 0 2px;
      @media (max-width: 1023px) {
        color: #7d7c82;
        font-family: Poppins;
        font-size: 8px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0;
      }
    }
    & h2 {
      font-weight: 900;
      font-size: 24px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #060531;
      margin: 0;
      @media (max-width: 1023px) {
        font-size: 15px;
        line-height: normal;
        text-align: left;
      }
    }
  }
}
.barChartWrapper {
  position: relative;
  & .dollarOne,
  & .dollarTwo,
  & .dollarThree,
  & .dollarFour,
  & .dollarFive {
    position: absolute;
    top: 7px;
    left: 30px;
    color: rgba(14, 18, 62, 0.4);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    @media (max-width: 767px) {
      left: 34px;
      top: 50px;
      font-size: 10px;
    }
  }
  & > .dollarTwo {
    top: 86px;
    @media (max-width: 767px) {
      top: 5px;
    }
  }
  & > .dollarThree {
    top: 176px;
    @media (max-width: 767px) {
      top: 103px;
    }
  }
  & > .dollarFour {
    top: 266px;
    @media (max-width: 767px) {
      top: 155px;
    }
  }
  & > .dollarFive {
    top: 356px;
    left: 42px;
    @media (max-width: 767px) {
      top: 208px;
      left: 44px;
    }
  }
  /* & svg{
    &>g:last-child{
      display: none;
    }
  } */
  height: 400px;
  @media (max-width: 767px) {
    height: 250px;
    margin-left: -34px;
    margin-right: -6px;
  }
  & :global(.recharts-default-tooltip) {
    background-color: #04ab8b !important;
    border-radius: 8px;
    padding: 5px 9px 5px 5px !important;
  }
  & :global(.recharts-tooltip-label) {
    display: none;
  }
  & :global(.recharts-tooltip-item) {
    padding: 0 !important;
    color: #fff;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 7.5px */
  }
  & :global(.recharts-cartesian-axis-tick) {
    position: relative;
    &:before {
      position: absolute;
      content: '$';
      height: 10px;
      width: 10px;
      top: 0;
      z-index: 10;
    }
  }
  & :global(.recharts-cartesian-axis-tick-value) {
    color: red;
  }
  & :global(.recharts-bar-rectangle) {
    &:hover {
      & path {
        fill: #04ab8b;
      }
    }
  }
  & :global(.recharts-tooltip-cursor) {
    /* &:hover{ */
    fill: transparent !important;
    /* } */
  }
}
.mainEarnings {
  display: flex;
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  gap: 24px;
  margin: 0 0 48px;
  @media (max-width: 767px) {
    flex-direction: column;
    margin: 0 0 16px;
  }
  & :global(.recharts-wrapper) {
    margin-right: -30px !important;
  }
  & .earningChart {
    height: 64px;
    width: 114%;
    @media (max-width: 767px) {
      width: 104.5%;
    }
    @media (max-width: 420px) {
      width: 108.5%;
    }
  }
  & .monthlyChart {
    height: 62px;
    width: 114%;
    @media (max-width: 767px) {
      width: 125%;
      height: 50px;
      max-width: 300px;
    }
    @media (max-width: 450px) {
      width: 121%;
    }
    @media (max-width: 420px) {
      width: 124%;
    }
  }
  & .earnings {
    display: none;
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
    border-radius: 6px;
    padding: 24px;
    width: 32.5%;
    @media (max-width: 767px) {
      width: 100%;
    }
    & .earningsMonth {
      @media (max-width: 767px) {
        display: flex;
        justify-content: space-between;
      }
    }
    & button {
      border: 1px solid #1fd1bc;
      border-radius: 6px;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #1fd1bc;
      height: 40px;
      max-width: 161px;
      width: 100%;
      padding: 0;
      @media (max-width: 1023px) {
        width: 92px;
        height: 30px;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.02em;
      }
    }
    & h2 {
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      letter-spacing: 0.02em;
      color: #060531;
      margin: 18px 0 50px;
      @media (max-width: 1023px) {
        margin: 4px 0 24px;
        font-size: 22px;
        line-height: 26px;
      }
    }
    & h6 {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #1f403b;
      @media (max-width: 1023px) {
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.02em;
      }
    }
    @media (max-width: 767px) {
      padding: 16px 12px;
    }
  }
  & .monthly {
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
    border-radius: 6px;
    width: 64%;
    @media (max-width: 767px) {
      padding: 16px;
      width: 100%;
    }
    & h5 {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #1f403b;
      margin: 0 0 16px;
      @media (max-width: 1023px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
    & .viewAll button {
      cursor: pointer;
      border: none;
      color: white;
      width: 100%;
      height: 40px;
      background: #1fd1bc;
      border-radius: 6px;
      margin: 24px 0 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
    & .mainCalender {
      display: flex;
      width: 100%;
      gap: 24px;
      @media (max-width: 1023px) {
        gap: 11px;
      }
      & h6 {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #060531;
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 14px;
          color: #1f403b;
        }
      }
      & .monthOne {
        width: 50%;

        padding: 16px;
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(6, 5, 49, 0.1);
        border-radius: 6px;
        & h2 {
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: #1fd1bc;
          margin: 8px 0 16px;
          @media (max-width: 1023px) {
            margin: 4px 0 16px;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.02em;
            color: #060531;
          }
        }
      }

      & .monthTwo {
        width: 50%;

        padding: 16px;
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(6, 5, 49, 0.1);
        border-radius: 6px;
        & h2 {
          font-weight: 700;
          font-size: 24px;
          line-height: 30px;
          color: #fd4e50;
          margin: 8px 0 16px;
          @media (max-width: 1023px) {
            margin: 4px 0 16px;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.02em;
            color: #fd4e50;
          }
        }
      }
    }
  }
}

.monthly p {
  padding: 30px 0px 30px 0px;
}

.viewAll {
  text-align: center;
}
.viewDetails {
  & .menuLabel {
    color: #cbcbcb;
    font-family: Poppins;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #f6f6f6;
  }
}
.menuLabel {
  margin-left: 5px;
  & svg {
    width: 14px !important;
    height: 14px !important;
    @media (max-width: 767px) {
      width: 11px !important;
      height: 11px !important;
    }
  }
}
.menuLabelComing {
  display: flex;
  & svg {
    width: 14px !important;
    height: 14px !important;
    @media (max-width: 767px) {
      width: 11px !important;
      height: 11px !important;
    }
  }
}
.menuContent {
  position: absolute;
  right: 0;
  left: unset !important;
  z-index: var(--zIndexPopup);
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
  border-radius: 6px;
  max-width: 106px;
  min-width: 106px !important;
  @media (max-width: 767px) {
    padding: 12px;
  }
  & > div {
    display: none;
  }
  & ul {
    & li {
      padding: 0 0 4px;
      border-bottom: 1px solid #ebebeb;
      margin: 0 0 4px;
      color: #cbcbcb;
      font-family: Poppins;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &:last-child {
        border-bottom: none;
        padding: 0;
        margin: 0;
      }
    }
  }
}
.menuContentComing {
  position: absolute;
  z-index: var(--zIndexPopup);
  left: unset !important;
  min-width: 85px !important;
  padding: 10px 6px 6px;
  right: 9px !important;
  top: 20px;
  max-width: 85px;
  border-radius: 3px 0px 3px 3px;
  border: 0.4px solid #7b7b7b;
  background: #fff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  @media (max-width: 767px) {
    max-width: 75px;
    top: 16px;
  }
  & > div {
    display: none;
  }
  & ul {
    & li {
      color: #7b7b7b;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      padding: 3px 6px 2px;
      @media (max-width: 767px) {
        font-size: 9px;
      }
    }
  }
}
.menuContentGenAvail {
  position: absolute;
  z-index: var(--zIndexPopup);
  left: unset !important;
  min-width: 100px !important;
  padding: 5px 5px;
  right: 9px !important;
  top: 20px;
  max-width: 85px;
  border-radius: 3px 0px 3px 3px;
  border: 0.4px solid #7b7b7b;
  background: #fff;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
  @media (max-width: 767px) {
    max-width: 75px;
    top: 16px;
  }
  & > div {
    display: none;
  }
  & ul {
    & li {
      color: #7b7b7b;
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      padding: 3px 6px 2px;
      @media (max-width: 767px) {
        font-size: 9px;
      }
    }
  }
}
.menuContentVacation {
  position: absolute;
  right: unset;
  left: 0 !important;
  z-index: var(--zIndexPopup);
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
  border-radius: 6px;
  max-width: 250px;
  width: 100%;
  min-width: 250px !important;
  bottom: 28px;
  @media (max-width: 767px) {
    bottom: 24px;
    right: 0;
    left: unset !important;
    max-width: 210px;
    min-width: 210px !important;
    padding: 10px 6px 6px;
    left: -20px !important;
  }
  & > div {
    display: none;
  }
  & ul {
    & li {
      padding: 0 0 8px;
      color: #cbcbcb;
      &:nth-child(1) {
        color: #9e9e9e;
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.1px;
        @media (max-width: 767px) {
          font-size: 8px;
        }
      }
      &:last-child {
        border-bottom: none;
        padding: 0;
        margin: 0;
      }
    }
  }
}
.reviewCard {
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
  margin: 22px 0 14px;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  @media (max-width: 767px) {
    padding: 16px 13px 16px 12px;
  }
  & .leftContent {
    display: flex;
    align-items: center;
    gap: 14px;
  }
  & .reviewIcon {
    background: #fea515;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  & .reviewContents {
    color: #fea515;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 19.5px */
    @media (max-width: 767px) {
      font-size: 14px;
    }
    & .reviewText {
      color: #000;
      font-weight: 400;
    }
  }
}
.arrowRight {
  background: #f6f6f6;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}
.cardWrapper {
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
  padding: 24px;
  margin-top: 24px;
  @media (max-width: 767px) {
    padding: 14px 12px 13px 17px;
    margin-top: 0;
    margin-bottom: 14px;
    border-bottom: 1px solid #ececec;
    &:last-child {
      border: none;
    }
  }
  & .settingText {
    border-radius: 5px;
    border: 1px solid #f6f6f6;
    background: #fff;
    color: #6c6c6c;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 12px */
    letter-spacing: -0.1px;
    padding: 2px 5px;
    @media (max-width: 767px) {
      font-size: 8px;
    }
  }
}
.mainContents {
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
}

.totalAmount {
  display: flex;
  align-items: center;
  & .accountStaus {
    display: flex;
    align-items: center;
    gap: 4px;
    background: rgb(31 209 188 / 5%);
    padding: 4px 8px;
    border-radius: 20px;
  }
  & .activeCircle {
    background: #15e24d;
    height: 14px;
    width: 14px;
    border-radius: 100px;
    @media (max-width: 767px) {
      height: 12px;
      width: 12px;
    }
  }
  & .accountPaused {
    display: flex;
    align-items: center;
    gap: 4px;
    background: rgb(254 165 21 / 5%);
    padding: 4px 8px;
    border-radius: 20px;
  }
  & .pausedCircle {
    background: #fea515;
    height: 14px;
    width: 14px;
    border-radius: 100px;
    @media (max-width: 767px) {
      height: 12px;
      width: 12px;
    }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
  & h6 {
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
.paragraph {
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 158%;
    color: #1f403b;
    margin: 16px 0 0;
    @media (max-width: 767px) {
      margin: 12px 0 0;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
.viewDetails {
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 767px) {
    gap: 8px;
  }
  & h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    @media (max-width: 767px) {
    }
  }
}
.ball {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 4px;
  left: 4px;
  transition: transform 0.5s ease;
  background: #ffffff;

  border-radius: 13.5px;
  @media (max-width: 767px) {
    width: 18px;
    height: 18px;
    top: 3px;
    left: 3px;
  }
  &.toggled {
    transform: translateX(167%);
    background: #1fd1bc;
    @media (max-width: 767px) {
      transform: translateX(135%);
    }
  }
}
.checkboxWrapper {
  cursor: pointer;
  position: relative;
  font-family: var(--font-familyLite);
  width: 72px;
  height: 32px;
  background: #d8dad9;
  opacity: 0.72;
  border-radius: 20px;
  @media (max-width: 767px) {
    width: 48px;
    height: 24px;
  }
}

.bottomCard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 24px;
  row-gap: 24px;
  width: 100%;
  @media (max-width: 1023px) {
    column-gap: 11px;
    row-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 1280px) {
    margin-top: 30px;
  }
  & .card {
    padding: 16px;
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    @media (max-width: 767px) {
      padding: 13px 8px 13px 12px;
    }
    & .iconWrapper {
      display: flex;
      justify-content: space-between;
      position: relative;
      & > svg {
        height: 11px;
        width: 11px;
      }
      & .comingText {
        position: absolute;
        top: 16px;
        right: 4px;
        border-radius: 3px 0px 3px 3px;
        border: 0.4px solid #7b7b7b;
        background: #fff;
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
        color: #7b7b7b;
        font-family: Poppins;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 12px */
        padding: 2px 6px;
      }
    }
    & .clockIcon {
      border-radius: 5px;
      background: #060531;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        height: 16px;
        width: 16px;
      }
    }
    & .eyeIcon {
      border-radius: 5px;
      background: #060531;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        height: 16px;
        width: 16px;
      }
    }
    & .thumbsUp {
      border-radius: 5px;
      background: #060531;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        height: 16px;
        width: 16px;
      }
    }
    & .dashboardStar {
      border-radius: 5px;
      background: #060531;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        height: 16px;
        width: 16px;
      }
    }
    & h2 {
      margin: 0;
    }
    & div {
      margin: 0;
      padding: 0;
    }
    & h5,
    & h2 {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #b4b4b4;
      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
    }
    & h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: #060531;
      margin: 16px 0 24px;
      @media (max-width: 767px) {
        margin: 15px 0 8px;
        font-size: 14px;
        line-height: normal;
      }
    }
    & svg {
      fill: transparent;
      @media (max-width: 767px) {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.bottomCardText {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-top: 10px !important;
  }
}

.ratingStar {
  display: flex;
  align-items: center;
  gap: 4px;

  & svg {
    @media (max-width: 767px) {
      height: 10px;
      width: 10px;
    }
  }
}

.customTooltip {
  background: #04ab8b !important;
  border-radius: 8px;
  border: 1px solid #fff;
  padding: 4px 6px !important;
  & p {
    color: #fff;
  }
}

.tooltipContent {
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}
