@import '../../styles/customMediaQueries.css';

.inputRoot {
  /* Override react-dates default styles to match input styles */

  & :global(.DateRangePicker) {
    display: block;
  }

  & :global(.DateRangePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: -10px !important;
    left: -70px !important;
    right: 0;
    margin: auto;
    width: 100%;
    min-height: calc(100vh - 252px);
    max-width: 340px;
    background-color: white;
    z-index: 999;
    /* background-color: var(--marketplaceColor); */

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 42px !important;
      min-height: auto;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 2px 2px;
    }
  }

  & :global(.DateInput_input) {
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;

    padding: 11px 10px;
    margin: 0;
    border: 0;
    background: none;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
    }
  }

  & :global(.DateRangePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }

  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    /* background-color: var(--marketplaceColor); */
    box-shadow: none;
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    display: inline;

    &:first-of-type {
      right: 20px;
    }

    &:last-of-type {
      right: -8px;
    }
  }

  & :global(.DayPickerNavigation_button) {
    color: #000000;
    border: 0;
  }

  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: #ffffff;
    padding-bottom: 80px;
  }

  & :global(.CalendarMonthGrid) {
    left: 0%;
    display: flex;
    flex-direction: column;
  }

  & :global(.DateInput) {
    display: block;
    width: 100%;

    @media (--viewportMedium) {
      padding: 6px 0 9px 0;
    }
  }

  & :global(.DayPicker_weekHeader) {
    color: #000000;
    top: 57px;
    left: 4% !important;
    padding: 0 !important;
    width: 340px;

    &:not(:first-child) {
      display: none !important;
    }
  }

  & :global(.DayPicker_weekHeaders__horizontal) {
    margin-left: 0 !important;
    width: 340px;
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: 700;
    width: 48.4px !important;
    height: 45px !important;
    font-size: 16px;
  }

  & :global(.DayPicker__withBorder) {
    border-radius: 0;
  }

  & :global(.CalendarMonth_caption) {
    font-family: 'Quicksand', Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin: 1px 0 14px;
    text-align: left;
    background: #ffffff;
    margin-left: 18px;
    padding-bottom: 27px;

    @media (--viewportMedium) {
      line-height: 32px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__default) {
    /* background-color: var(--marketplaceColor); */
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  & :global(.DayPicker_transitionContainer) {
    width: 100% !important;
    overflow: visible;
    transition: unset !important;
    -webkit-transition: unset !important;
  }

  & :global(.CalendarDay) {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;

    color: #000000;
    border: 0;
    margin-top: 0;
    margin-bottom: 0;
    width: 40px !important;
    height: 40px !important;
    outline: 0;
    aspect-ratio: 1;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23060531' d='M19 13H5v-2h14v2Z'/%3E%3C/svg%3E");
    background-position: 7px 17px;

    @media (max-width: 767px) {
      background-position: 13px 17px;
      height: auto !important;
    }
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-image: transparent;
    background-color: #1fd1bc3d !important;
  }

  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }

  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: transparent !important;
    transition: all 0.2s ease-out;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */

  & :global(.CalendarDay__selected) {
    background-color: transparent !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  & :global(.CalendarDay__selected_start) {
    background-color: #1fd1bc3d !important;
    padding: 4px;
    border-top-left-radius: 99px;
    border-bottom-left-radius: 99px;
    background-image: none;
  }

  & :global(.CalendarMonth_table) {
    width: 100%;
  }

  & :global(.CalendarDay__selected_start .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: #1FD1BC;
    /* border-top-left-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    border-bottom-left-radius: calc(var(--DateRangeInput_selectionHeight) / 2); */
    border-radius: 99px;
    aspect-ratio: 1;
    @media (max-width: 767px) { 
      height: auto !important;
    }
    &:hover {
      background-color: #1FD1BC !important;
    }
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__after-hovered_start) {
    background-color: transparent;
  }

  & :global(.CalendarDay__after-hovered_start .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }

  & :global(.CalendarDay__selected_end):hover {
    background-color: transparent !important;
    background: transparent !important
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected_end) {
    background: #1fd1bc3d !important;
    padding: 4px;
    border-top-right-radius: 99px;
    border-bottom-right-radius: 99px;
    /* background: transparent !important; */
  }

  & :global(.CalendarDay__selected_end .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: #1FD1BC !important;
    /* border-top-right-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    border-bottom-right-radius: calc(var(--DateRangeInput_selectionHeight) / 2); */
    border-radius: 99px;
    color: #000000;
    aspect-ratio: 1;
    @media (max-width: 767px) { 
      height: auto !important;
    }
  }

  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }

  & :global(.CalendarDay:hover) {
    background: #1fd1bc3d !important;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
    text-decoration: line-through;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
    text-decoration: line-through;
  }

  & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
    background-color: transparent;
  }

  & :global(.DateInput_fang) {
    display: none;
  }

  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
  }

  & :global(.DateInput_input__disabled) {
    font-style: normal;
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
  & :global(.DateRangePickerInput) {
    margin: 0;

    @media (--viewportMedium) {
      width: 100%;
      margin: 0;
    }
  }

  /* Create gutter between inputs */
  & :global(.DateInput) {
    width: calc(50% - 6px);
    background: none;
    border: 1px solid #d8dad9;
    border-radius: 6px;
    height: 48px;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    text-transform: capitalize;

    @media (--viewportMedium) {
      width: calc(50% - 12px);

    }
  }

  & :global(.DateInput:first-of-type) {
    margin-right: 12px;

    @media (--viewportMedium) {
      margin-right: 24px;
    }
  }
}

.arrowIcon {
  stroke: #000000;
  fill: #000000;
}