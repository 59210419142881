@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.name {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.firstNameRoot {
  width: calc(34% - 9px);
}

.lastNameRoot {
  width: calc(66% - 9px);
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.addressOption {
  margin-top: 24px;
}

.locationAddress{
  flex-wrap: wrap;
  &>div{
    border-bottom: 0;
    & svg{
      display: none;
    }
  }
  & input{
    border: 1px solid var(--attentionColor);
    border-radius: 6px;
    background: #fbfbfb;
    height: 56px;
    padding: 0 0 0 16px;
  }
}
.addressBox{
  margin-top: 24px;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 26px;
  }
  & label{
    position: absolute;
    top: -10px;
    left: 20px;
    padding: 0 5px 0;
    background: #fbfbfb;
    z-index: 1;
  }
}

.errorText {
  color: red;
}
