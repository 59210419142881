@import '../../styles/customMediaQueries.css';

.root {
}

.newPasswordSection {
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  display: grid;
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 26px;
  }
}

.confirmChangesSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  padding: 0;
  /* opacity: 0; */
}

.confirmChangesSectionVisible {
  opacity: 1;
}

.confirmChangesTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 13px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
.updatePasswordSubTitle{
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
color: #060531;
margin: 49px 0 27px;
@media (max-width: 767px) {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
margin: 32px 0 42px;
}
}
.confirmChangesInfo {
  margin-top: 0;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }
}

.bottomWrapper {
  margin-top: 32px;

  @media (--viewportMedium) {
    margin-top: 48px;
  }
}

.error {
  composes: h4 from global;
  color: var(--failColor);
  text-align: center;
  display: inline-block;
  width: 100%;
  margin: 24px 0;

  @media (--viewportMedium) {
    margin: 24px 0;
  }
}

.helperLink {
  composes: a from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  text-decoration: underline;
}

.emailStyle {
  font-weight: var(--fontWeightBold);
  word-wrap: break-word;
}

.passwordContainer {
  position: relative;
}
.passwordContainer svg {
  position: absolute;
  top: 17px;
  right: 18px;
  @media (max-width: 767px) {
    top: 13px;
  }
}
.passwordErrorMessage{
  font-size: 18px;
  margin-top: 30px;
}