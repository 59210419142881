@import '../../styles/customMediaQueries.css';

.root {
  background-color: var(--matterColorLight);
}

.content {
  /* composes: marketplaceSectionWrapper from global; */
  width: 100%;
  margin: 0px auto 0px auto;
  @media (max-width: 1336px) {
    max-width: 100%;
    padding-left: 60px;
  }
  @media (max-width: 1250px) {
    padding-left: 56px;
  }
  @media (max-width: 900px) {
    padding-left: 36px;
  }

  @media (max-width: 767px) {
    padding: 0px;
    margin: 0px auto 28px auto;
  }
}
.informationContents {
  width: 100%;
  /* @media (min-width: 768px) {
    padding-right: 20px;
  } */
  /* @media (min-width: 1024px) {
    padding-right: 40px;
  }
  @media (min-width: 1250px) {
    padding-right: 60px;
  }
  @media (min-width: 1336px) {
    padding-right: 20px;
  } */
  /* @media (max-width: 1600px) {
    max-width: 828px;
  } */
  & a {
    &:hover {
      text-decoration: none;
    }
  }
}
.headingContainer {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d8dad9;
  margin-left: -16px;
  @media (max-width: 767px) {
    margin-left: 0;
    background: #eaeaea;
    border-radius: 6px;
    border: none;
    overflow: hidden;
  }

  & button {
    margin: 0;
    background: #eaeaea;
    color: #060531;
    height: 38px;
    text-transform: capitalize;
    max-width: 198px;
    width: 100%;
    &:hover {
      background: #eaeaea;
    }

    @media (max-width: 767px) {
      height: 40px;
      max-width: 100%;
    }
  }

  & button:focus {
    background-color: #1fd1bc !important;
    color: #fff;
  }

  & button:first-child {
    border-radius: 6px 0 0 0px;

    @media (min-width: 768px) {
      margin-left: 16px;
    }
  }

  & button:last-child {
    border-radius: 0 6px 0px 0;

    @media (max-width: 767px) {
      border-radius: 6px;
    }
  }
}

.arrowBack {
  & svg {
    margin-bottom: -51px;
    @media (min-width: 767px) {
      display: none;
    }
  }
}

.heading {
  margin-right: 24px;
  hyphens: auto;
}

.navMainContainer {
  display: flex;
  gap: 36px;
  /* justify-content: center; */
  @media (max-width: 1350px) {
    gap: 20px;
  }
  @media (max-width: 1200px) {
    gap: 10px;
  }
  @media (max-width: 1023px) {
    gap: 5px;
  }
}

.informationContainer {
  width: 100%;
  padding: 24px 32px;
  flex-grow: 1;
  background: #fff;
  @media screen and (min-width: 1300px) {
    border-left: 2px solid #eaeaea;
  }
  /* @media (min-width: 768px) {
    width: 75%;
    padding: 24px calc((100% - 1249px) / 2) 82px 32px;
  } */
  /* @media (min-width: 1250px) {
    width: 68.5%;
  } */
  @media (max-width: 767px) {
    padding: 0px 20px;
    width: 100%;
  }

  & .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #060531;
    margin-bottom: 16px;

    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      margin: 24px 0 24px;
    }
  }
  & .borderTitle {
    background: #d8dad9;
    height: 1px;
    width: 104%;
    margin: 16px -16px;
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.navContainer {
  @media screen and (min-width: 1300px) {
    padding-left: 40px;
  }
  @media screen and (max-width: 1300px) {
    min-width: 296px;
  }
  /* min-width: 296px; */
  padding-top: 48px;
  /* @media (min-width: 768px) {
    width: 32%;
    padding: 64px 0 82px calc((100% - 1184px) / 2);
  } */
  /* @media (min-width: 1024px) {
    width: 25%;
    min-width: 242px;
  }
  @media (min-width: 1250px) {
    width: 29%;
  }
  @media (min-width: 1500px) {
    width: 34%;
  }
  @media (min-width: 1860px) {
    width: 37%;
  } */
  @media (max-width: 1023px) {
    min-width: 200px;
    max-width: 240px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.routeLink {
  composes: marketplaceSectionWrapper from global;
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1336px) {
    padding: 0;
  }
  @media (max-width: 767px) {
    background: #f9f9f9;
    display: none;
  }

  & a:first-child {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #060531;
    text-decoration: none;
    @media (max-width: 1023px) {
      font-size: 12px;
      line-height: 12px;
    }
  }

  & .accountSetting {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1fd1bc;
    @media (max-width: 1023px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.activeBtn {
  background: #1fd1bc !important;
  color: #fff !important;

  @media (max-width: 767px) {
    border-radius: 6px !important;
  }
}

.sectionWrapper {
  /* background: #f9f9f9; */

  @media (max-width: 767px) {
    background: #fff;
  }
}

.profileLink {
  composes: button buttonText buttonBordersSecondary buttonColorsSecondary from global;

  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }

  display: inline-block;
  width: auto;
  min-height: auto;
  height: 41px;

  float: right;
  flex-shrink: 0;

  margin: 19px 0 0 auto;
  padding: 12px 16px 5px 16px;

  @media (--viewportMedium) {
    margin: 37px 0 0 auto;
  }
}
