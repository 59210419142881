@import '../../styles/customMediaQueries.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }

  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
}
.activeText{
  border-bottom: 1px solid gray;
  background: rgba(128, 128, 128, 0.212);
  border-radius: 10px;
}
.addNewCardMobile {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 10;
  @media (min-width: 768px) {
    display: none;
  }
}
.addNewCard {
  /* background-image: url("../../assets/ListingCard/ListingAddNew.png"); */
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 6px;
  cursor: pointer;
  min-width: 286px;
  min-height: 362px;

  @media (max-width: 1370px) {
    min-height: 330px;
    min-width: 241px;
  }

  @media (max-width: 767px) {
    display: none;
  }

  & img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  & .addNewText {
    background: rgba(6, 5, 49, 0.75);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    text-transform: capitalize;

    @media (max-width: 1200px) {
      font-size: 20px;
      line-height: 26px;
    }
  }
}
.aside {
  box-shadow: none;
}
.verifiedContents {
  display: flex;
  align-items: center;
}
.asideContent {
  width: 100%;
}

.avatar {
  margin: 30px 26px 29px 0;
  flex-shrink: 0;

  @media (--viewportLarge) {
    margin: 0 96px 44px 0;
  }
}
/* .mobileStar {
  @media (min-width: 768px) {
    display: none;
  }
} */
.reviewContents {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
  margin: 24px 0 0px;
  @media (max-width: 1023px) {
    gap: 35px;
    margin: 16px 0 0px;
    grid-template-columns: repeat(1, 1fr);
  }
  & .reviewNumber {
    text-align: center;
    & p {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #1f403b;
      margin: 0 0 26px;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    & .progressBarWrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      @media (max-width: 767px) {
        gap: 14px;
      }
      & .oneStar {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 53px;
        & p {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #060531;
          margin: 0;
          @media (max-width: 767px) {
            color: #18191f;
          }
        }
        & svg {
          width: 16px;
          height: 16px;
          margin: 0 12px 0 4px;
        }
        & > div {
          width: 100%;
          max-width: 182px;
          position: relative;
          & span {
            position: absolute;
            top: -7px;
            right: -50px;
            color: rgba(6, 5, 49, 0.3);
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 15px;
            }
          }
        }
      }
    }
    & :global(.star-ratings) {
      margin: 12px 0;
      @media (max-width: 767px) {
        margin: 16px 0;
      }
    }
    & h2 {
      font-weight: 700;
      font-size: 48px;
      line-height: 61px;
      color: #060531;
      text-align: center;
      @media (max-width: 767px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
  }
  & .reviewComment {
    & .commentCard {
      background: #f5f5f5;
      border-radius: 6px;
      padding: 16px;
      margin: 0 0 16px;
      @media (max-width: 767px) {
        margin: 0 0 12px;
        padding: 12px 10px 12px 12px;
      }
      & p {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #1f403b;
        padding-left: 44px;
        @media (max-width: 767px) {
          padding-left: 0px;
          font-size: 14px;
          line-height: 18px;
        }
      }
      & .commentCardTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        & .imageContent {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        & .starReview {
          display: flex;
          gap: 4px;
          & svg {
            width: 16px;
            height: 16px;
            @media (max-width: 767px) {
              width: 12px;
              height: 12px;
            }
          }
        }
        & h4 {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
          @media (max-width: 767px) {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
          }
        }
        & h6 {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #1f403b;
          @media (max-width: 767px) {
            font-size: 14px;
            line-height: 14px;
          }
        }
      }
    }
  
  }
}
.text{
  cursor: pointer;
  padding: 0 10px;
}
.reviewTitle {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8dad9;
  padding: 0 0 16px;
  margin: 0 0 16px;
  @media (max-width: 767px) {
    justify-content: center;
    border-bottom: none;
    margin: 0px;
    /* display: none; */
  }
  & .starReviewTitle {
    display: flex;
    align-items: center;
    gap: 4px;
    & svg{
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
  & h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #060531;
    margin: 0;
    margin-left: 24px;
    @media (max-width: 767px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-left: 12px;
    }
  }
  & h6 {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
.mobileHeading {
  flex-shrink: 0;
  margin: 47px 0 0 0;

  @media (--viewportMedium) {
    margin: 49px 0 0 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  & svg {
    @media (max-width: 767px) {
      width: 34px;
      height: 30px;
    }
  }
}

.desktopHeading {
  display: block;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #060531;
  text-transform: capitalize;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;

  @media (--viewportMedium) {
    margin: 4px 0 51px 0;
  }

  @media (--viewportLarge) {
    margin: 0 0 56px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  composes: clearfix from global;
  border-top: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  composes: h3 from global;
  color: var(--matterColorAnti);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }

  @media (--viewportLarge) {
    margin-top: 58px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--matterColorNegative);
  }
}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.listing {
  width: 100%;

  /* Single column in mobile */
  margin-top: 34px;

  &:first-of-type {
    margin-top: 18px;
  }

  @media (--viewportMedium) {
    /* Two columns in desktop */
    width: calc(50% - 12px);
    margin-bottom: 36px;

    /* Horizontal space */
    &:nth-of-type(odd) {
      margin-right: 12px;
    }

    &:nth-of-type(even) {
      margin-left: 12px;
    }

    /* Vertical space */
    margin-top: 0;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-top: 20px;
    }
  }
}

.mobileReviewsTitle {
  composes: h3 from global;
  color: var(--matterColorAnti);
  margin: 24px 0 26px 0;
}

.mobileReviews {
  margin-bottom: 36px;
  overflow-y: scroll;
  max-height: 248px;
  @media (max-width: 767px) {
    max-height: 300px;
  }
  & > ul {
    gap: unset;
    max-height: unset;
    flex-direction: column;
    gap: 16px;
    overflow: auto;
    & > li > div {
      width: 100%;
    }
  }
}
.mobileReviews::-webkit-scrollbar {
@media (min-width: 768px) {
  width: 5px;
}
}


/* Track */
.mobileReviews::-webkit-scrollbar-track {
  background: #FBFBFB;
}


/* Handle */
.mobileReviews::-webkit-scrollbar-thumb {
  background: #D8DAD9;
    border-radius: 5px;
}


/* Handle on hover */
.mobileReviews::-webkit-scrollbar-thumb:hover {
  background: #D8DAD9;
}
.desktopReviews {
  & .customerAbout {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    padding: 16px 0 0 16px;
    max-width: 1120px;
    @media (max-width: 767px) {
      padding: 16px 0 0 0px;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.desktopReviewsTitle {
  composes: h3 from global;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  position: relative;
  border-bottom: 1px solid #d8dad9;
  padding-left: 16px;
  @media (max-width: 767px) {
    background: #eaeaea;
    border: none;
    padding-left: 0;
    border-radius: 10px;
  }
  /* &:after {
    content: "";
    position: absolute;
    background: #D8DAD9;
    height: 1px;
    width: 100%;
    bottom: 0;
  } */
  & > div:first-child {
    & button {
      border-radius: 6px 0 0;
    }
  }
  & > div:last-child {
    & button {
      border-radius: 0px 6px 0 0;
    }
  }
  & > div {
    background-color: #eaeaea;
    border-radius: 6px;
    width: 198px;
    @media (max-width: 767px) {
      width: 100%;
    }
    & button {
      width: 198px;
      height: 38px;
      @media (max-width: 767px) {
        width: 100%;
        height: 40px;
      }
      & h1 {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #060531;
        margin-top: 0;
      }
    }
  }
}
.routeLink {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  max-width: 1216px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1280px) {
    padding: 0 20px;
  }
  @media (max-width: 767px) {
    background: #f9f9f9;
  }

  & a:first-child {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #060531;
    text-decoration: none;
    @media (max-width: 1023px) {
      font-size: 12px;
      line-height: 12px;
    }
  }

  & .accountSetting {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1fd1bc;
    @media (max-width: 1023px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
}
.otherProfileWrapper {
  margin: 0 0 120px;
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
  & .profileContainer {
    display: flex;
    align-items: center;
    width: 100%;
    background: #ffffff;
    border-radius: 6px;
    padding: 48px 50px;
    margin-top: 48px;
    @media (max-width: 1023px) {
      margin-top: 24px;
    }
    @media (max-width: 767px) {
      margin-top: 0px;
      padding: 32px 0;
    }
    & .profileAvtar {
      margin-right: 24px;
      @media (max-width: 767px) {
        margin-right: 12px;
      }
      & > div {
        & > div {
          height: 224px;
          width: 224px;
          margin: 0;
          @media (max-width: 1300px) {
            height: 180px;
            width: 180px;
          }
          @media (max-width: 1100px) {
            height: 150px;
            width: 150px;
          }
          @media (max-width: 1023px) {
            height: 120px;
            width: 120px;
          }
          @media (max-width: 767px) {
            height: 64px;
            width: 64px;
          }
        }
      }
    }

    & .profileDataRight {
      & .locationBox {
        display: flex;
        align-items: center;
        gap: 7px;
        margin: 16px 0 24px;
        @media (max-width: 1100px) {
          margin: 12px 0 16px;
        }
        @media (max-width: 767px) {
          margin: 2px 0 12px;
          gap: 3px;
        }
        & svg {
          @media (max-width: 767px) {
            width: 10px;
            height: 14px;
          }
        }
        & p {
          font-weight: 500;
          font-size: 16px;
          line-height: 10px;
          color: #1f403b;
          @media (max-width: 767px) {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: -0.03em;
            color: #000000;
          }
        }
      }

      & .profileBottomBox {
        & .varifiedBox {
          background: rgba(37, 159, 70, 0.1);
          border-radius: 6px;
          /* padding: 8px 11px; */
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 24px;
          height: 48px;
          width: 142px;
          @media (max-width: 767px) {
            margin-right: 12px;
            height: 30px;
            width: 95px;
          }
          & .varifiedIcon {
            margin-right: 10px;
            @media (max-width: 767px) {
              margin-right: 4px;
            }
            & svg {
              @media (max-width: 767px) {
                width: 15px;
                height: 18px;
              }
            }
          }

          & .varifiedText {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #259f46;
            @media (max-width: 767px) {
              font-size: 12px;
              line-height: 15px;
            }
          }
        }

        & .messageIcon {
          background: #1fd1bc;
          border-radius: 6px;
          padding: 12px;
          cursor: pointer;
        }
      }
    }
  }
}
.listingCardsWrapper {
  & h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #060531;
    margin: 18px 0 24px;
    @media (max-width: 767px) {
      margin: 16px 0 16px;
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.listingCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  margin-top: 18px;
  @media (max-width: 1023px) {
    grid-template-columns: repeat(3, 1fr);
    margin-top: 16px;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 11px;
    grid-row-gap: 16px;
  }
}
.otherCards {
  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.listingCard {
  flex-basis: 100%;

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex-basis: calc(50% - 12px);
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}
