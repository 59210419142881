@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  height: auto;
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

.form {
  flex-grow: 1;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #060531;
  margin: 0;
  @media (max-width: 767px) {
    margin: 0 0 24px;
    font-size: 22px;
    line-height: 28px;
    color: #060531;
  }
}
.dividerLine {
  width: 104%;
  height: 1px;
  margin: 16px 0px 16px -16px;
  background: #d8dad9;
  @media (max-width: 767px) {
    display: none;
  }
}
.informationContents {
  composes: marketplaceRightPadding from global;
  max-width: 808px;
  @media (min-width: 1500px) {
    max-width: 100%;
  }
}