@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
@media (min-width: 768px) {
  justify-content: space-between;
  min-height: 215px;
}
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  composes: h4 from global;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 16px;
  @media (max-width: 767px) {
    margin: 0 0 12px;
  }
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  composes: h5 from global;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  composes: buttonFont from global;
  margin: 6px 0;
}

.subTotalLineItem {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  margin: 0px -16px 0;
  padding: 16px 16px 17px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  background: #F5F5F5;
  border: 1px solid #D8DAD9;
  border-radius: 0px 0px 6px 6px;
  border-bottom: none;
  @media (max-width: 767px) {
    margin: 0 -20px;
    padding: 0px 20px 0px;
    background: transparent;
    border: none;
    border-radius: 0px;
  }
}

.smallFontStyles {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #060531;
  @media (max-width: 767px) {
    font-size: 14px;
line-height: 18px;
  }
}

.itemLabel {
  flex: 1;
  font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #1F403B;
@media (max-width: 767px) {
  font-size: 14px;
line-height: 18px;
}
}

.itemValue {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #060531;
  margin: 0 0 0 10px;
  @media (max-width: 767px) {
    font-size: 16px;
line-height: 20px;
font-weight: 500;
  }
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 8px 0 16px;

  border: none;
  background-color: var(--matterColorNegative);
  @media (max-width: 767px) {
    margin: 10px 0 12px;
  }
}
.totalDividerMobile {
  /* dimensions */
  width: 100%;
  height: 1px;

  border: none;
  background-color: var(--matterColorNegative);
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 767px) {
    margin: 0px 0 12px;
  }
}
.totalDividerMobile2 {
  /* dimensions */
  width: 100%;
  height: 1px;

  border: none;
  background-color: var(--matterColorNegative);
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 767px) {
    margin: 12px 0 12px;
  }
}

.totalLabel, .totalPrice {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #060531;
  @media (max-width: 767px) {
    font-size: 16px;
line-height: 20px;
  }
}


.feeInfo {
  composes: marketplaceTinyFontStyles from global;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}
/* .subTotalContainer{

} */
.subTotalPrice{
  font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #060531;
@media (max-width: 767px) {
  font-size: 14px;
line-height: 18px;
}
}
.greenTotal{
  /* color: #1FD1BC; */
}
.totalEarning{
  border: none;
  /* background: none; */
  border-top: 1px solid #D8DAD9;
    margin-top: 16px;
  @media (max-width: 767px) {
        margin-top: 0px;
    border: none;
  }
}
.serviceFee{
  font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #060531;
@media (max-width: 767px) {
  font-size: 14px;
line-height: 18px;
}
}
