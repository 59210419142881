@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 0 0px;
}

.form {
  flex-grow: 1;
}
.informationContents {
  @media (min-width: 768px) {
    padding-right: 20px;
  }
  @media (min-width: 1024px) {
    padding-right: 40px;
  }
  @media (min-width: 1250px) {
    padding-right: 60px;
  }
  @media (min-width: 1336px) {
    padding-right: 20px;
  }
}
.modalContainer {
  display: flex;
  gap: 16px;
  @media (max-width: 767px) {
    gap: 7px;
  }
  & .modalTitle {
      & > svg {
        margin-top: -11px;
        @media (max-width: 767px) {
        width: 24px;
        height: 24px;
        margin-top: -20px;
      }
    }
  }
  & h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
    color: #060531;
    margin: 0 0 16px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  & p {
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
  & h4 {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: -0.02em;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
.titleContent {
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 767px) {
    margin: 0 0 24px;
    gap: 6px;
  }
  & .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #060531;
    margin: 0;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
}
.dividerLine {
  width: 100%;
  height: 1px;
  margin: 16px 0px 24px -16px;
  background: #d8dad9;
  @media (max-width: 1023px) {
    width: 100%;
  margin: 16px 0px 24px 0px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
.brandStoreIcon {
  /* position: absolute;
  top: -17px;
  left: 289px;
  z-index: 1;
  background: #fff;
  width: 23px;
  @media (max-width: 767px) {
    top: -12px;
    left: 257px;
    width: 17px;
  } */
  & .mobileIcon{
    @media (min-width: 768px) {
      display: none;
    }
  }
  & .desktopIcon{
    @media (max-width: 767px) {
      display: none;
    }
  }
  & svg {
    fill: transparent;
  }
}
/* ================ Modal ================ */

.modal {
  min-height: unset !important;
  height: auto !important;
}

.modalHeaderWrapper {
  margin-top: 58px;
  margin-bottom: 36px;
  padding: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 34px;
    padding: 0;
  }
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* This is the title for the Edit Photos component */
