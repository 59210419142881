@import '../../styles/customMediaQueries.css';

.sectionContainer {
  margin: 0 0px 96px;

  @media (max-width: 767px) {
    margin: 0 0px 48px;
  }

  & h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #060531;
    margin-bottom: 0px;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  & .imageContent {

    /* & .productContent{
      width: 100%;
      min-height: 154px;
      object-fit: cover;
    } */
    &>div {
      @media (max-width: 767px) {
        box-shadow: 0px 0px 6px 3px rgba(215, 215, 215, 0.25);
        border-radius: 10px;
      }
      & a{
     @media (min-width: 768px) {
      max-width: 286px;
      max-height: 364px;
     }
      }
    }

    & :global(.slick-prev:before) {
      content: unset;
    }

    & :global(.slick-next):before,
    & :global(.slick-prev):before {
      content: unset;
    }

    & :global(.slick-slide)>div>div {
      padding: 0 12px;

      @media (max-width: 767px) {
        padding: 0 5.5px;
      }
    }

    & :global(.slick-disabled) svg {
      border: 1px solid black;
      border-radius: 100px;
    }

    & :global(.slick-disabled) path {
      stroke: #000000;
    }

    & :global(.slick-disabled) rect {
      fill: #ffffff;
      stroke: #ffffff;
    }

    & :global(.slick-arrow) {
      right: 40%;
      top: unset;
      bottom: -52px;
    }

    & :global(.slick-next) {
      right: 40%;
    }

    & :global(.slick-prev) {
      left: 40%;
      /* bottom: -64px; */
      z-index: 32;
    }

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    text-align: center;
   
    @media (max-width: 1100px) {
      gap: 18px;
    }

    @media (max-width: 1023px) {
      gap: 14px;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 767px) {
      gap: 18px;
      grid-template-columns: repeat(1, 1fr);
    }

    & a:hover {
      text-decoration: none;
    }

    /* & .productContent {
      border: 1px solid #e5efff;
      border-radius: 6px;
      width: 100%;
      min-height: 154px;
      object-fit: cover;
      overflow: hidden;
      & h3{
        font-weight: 600;
font-size: 24px;
line-height: 24px;
color: #060531;

      }
      & img {
        width: 100%;
        min-height: 154px;
        object-fit: cover;
      }
      &:hover {
        & p {
          color: var(--marketPlaceColor);
          text-decoration: none;
        }
      }
      & p {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: var(--matterColor);
        @media (max-width: 767px) {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
        }
      }
    } */
  }
}

.heading {
  font-weight: bold;
  font-size: 22px;
  margin: 15px 0;
}

.reservationsPage {
  & .titleWrapper {
    display: none;
  }

  & .imageContent {
    max-width: 807px;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 1023px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
    }
    &>div{
      &>a{
      @media (min-width: 768px) {
        max-width: 253px;
        max-height: 317px;
      }
      }
    }
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @media (max-width: 767px) {
    margin-bottom: 24px;
  }
}

.titleWrapper a {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1fd1bc;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.imageContent {
  /* display: grid;
grid-template-columns: repeat(4, 1fr);
align-items: center;
justify-content: space-between;
gap: 24px;
text-align: center;
@media (max-width: 1100px) {
gap: 18px
}
@media (max-width: 1023px) {
gap: 14px;
grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 767px) {
gap: 11px;
grid-template-columns: repeat(2, 1fr);
} */

  /* & .productContent{
   width: 100%;
   min-height: 154px;
   object-fit: cover;
 } */
}