.popupSize {
  padding: 0 10px 7px 10px;
  @media (max-width: 767px) {
    height: 296px;
    background: #fff;
    border-radius: 6px 6px 0 0;
  }
}

.duration {
  padding: 0 24px 24px 24px;
}
