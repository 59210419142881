@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 0;
}
.sectionMain {
  composes: marketplaceSectionWrapper from global;
}
.sectionContainer {
  margin: 155px auto 0;
  padding: 96px 0;

  @media (max-width: 1500px) {
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 1280px) {
    padding: 80px 0;
    max-width: 100%;
    margin: 155px 0px 0;
  }
  @media (max-width: 1023px) {
    padding: 60px 0;
  }

  @media (max-width: 767px) {
    margin-top: 150px;
    padding: 32px 0 32px;
  }

  & h1 {
    text-align: center;
    margin-top: 0;

    @media (max-width: 767px) {
      line-height: 32px;
      margin: 0 auto 24px;
      max-width: 280px;
    }

    & .textColor {
      color: var(--marketplaceColor);
    }
  }

  & .cardContent {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(6, 1fr);
    justify-content: space-around;

    @media (max-width: 1023px) {
      grid-template-columns: repeat(5, 1fr);
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(3, 1fr);
      column-gap: 16px;
      row-gap: 24px;
    }

    & div {
      text-align: center;
      min-width: 95px;
      & a{
        text-decoration: none;
      }
      &:hover {
        & h4 {
          font-weight: 700;
        }
        & svg {
          & rect {
            fill: #1FD1BC;
            opacity: 1;
          }
          & path{
            fill: #ffffff;
            stroke: #ffffff;
          }
        }
      }
      & h4 {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        margin: 16px 0 6px;
        color: #060531;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 1023px) {
          margin: 8px 0 6px;
          height: 36px;
          font-size: 14px;
          line-height: 18px;
          height: 64px;
        }

        @media (max-width: 767px) {
          margin: 8px 0 6px;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          height: 36px;
        }

        @media (max-width: 460px) {
          height: unset;
        }
      }

      & svg {
        width: 120px;
        height: 120px;
        @media (max-width: 1200px) {
          width: 100px;
          height: 100px;
        }

        @media (max-width: 1023px) {
          width: 90px;
          height: 90px;
        }

        @media (max-width: 767px) {
          width: 72px;
          height: 72px;
        }
        & rect{
          opacity: 0.08;
        }
        & path{
          fill: #1FD1BC;
          stroke: #1FD1BC;
        }
      }

      & button:last-child {
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: #ffffff;
        height: 36px;
        width: auto;
        display: inline;
        padding: 11px 18px;
        border-radius: 100px;
        white-space: pre;

        @media (max-width: 1023px) {
          height: 32px;
          font-size: 11px;
          padding: 8px 12px;
        }
        @media (max-width: 767px) {
          height: 26px;
          font-size: 10px;
          line-height: 10px;
          padding: 8px 12px;
        }
      }

      & .betaText {
        background: #fea515;
      }

      & .commingText {
        background: #fd4e50;
      }

      & .newText {
        background: #1fd1bc;
      }
    }
  }
}
.betaText {
  background: #fea515;
}
