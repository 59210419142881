@import "../../styles/customMediaQueries.css";

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: 0px 1px 24px rgba(31, 209, 188, 0.1);

  @media (--viewportLarge) {}
}

.editListing {
  & .menuContainer {
    width: 100%;
    gap: 0;

    & .menuList {
      width: 100%;
      justify-content: center;
      padding-right: 84px;
    }
  }
}

.activeLink>span {
  font-weight: 700 !important;
  color: #1fd1bc !important;
}

.navMain {
  composes: marketplaceSectionWrapper from global;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;

  /* @media (max-width: 1300px) {
    max-width: 100%;
    margin: 0 36px;
  } */
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  padding: 21px 24px 21px 24px;
  height: 100%;

  @media (max-width: 1023px) {
    padding: 0;
  }

  @media (--viewportLarge) {
    padding: 16px 0;
  }

  & svg {
    @media (max-width: 1100px) {
      width: 265px;
    }
  }
}

.logoLink:hover {
  border-radius: 0;
  text-decoration: none;
}

.avatarMenu {
  display: flex;
  align-items: center;
  justify-content: center;

  & button {
    padding-right: 0;
  }
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 40px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--matterColor);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  color: var(--marketplaceColor);
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.topbarDesktopLabel {
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    margin: 28px 0;
  }
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 3px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;

  & svg {
    margin-right: 4px;
  }

  &:hover {
    /* border-bottom: 4px solid var(--marketplaceColor); */
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  /* margin: 16px 0; */
  width: 48px;
  height: 48px;
}

.profileMenuContent {
  min-width: 214px;
  max-width: 214px;
  padding: 8px 16px 8px 16px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  top: 58px;
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  display: contents;

  color: var(--matterColor);

  &:hover {
    text-decoration: none;
  }
}

.loginLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  display: contents;

  color: var(--matterColor);

  &:hover {
    color: var(--matterColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.signup,
.login {
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 16px;

  display: inline-block;
  text-decoration: inherit;

  @media (max-width: 1023px) {
    font-size: 14px;
  }
}
/* 
.signup {
  text-decoration: underline !important;
} */

.login {
  border: solid 1px var(--marketplaceColor);
  border-radius: 20px;
  padding: 8px 15px;
  color: var(--marketplaceColor);
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.myListing {
  & svg {
    fill: transparent;
    margin: 0 10px 0 3px;
  }
}

.profileSettingsLink,
.messageLink,
.yourListingsLink {
  composes: h6 from global;
  font-weight: 500;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);
  cursor: pointer;

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;
    font-weight: 600;

    & .menuItemBorder {
      width: 6px;
      /* background-color: var(--marketplaceColor); */
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;
  text-decoration: none;
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  letter-spacing: 0;
  position: relative;
  width: 100%;
  min-width: 214px;
  margin: 0;
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border: none;
  max-height: 21px;
  padding: 0;

  &:hover {
    cursor: pointer;
    color: var(--matterColorDark);
    text-decoration: none;
    font-weight: 600;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.menuContainer {
  display: flex;
  justify-content: flex-end;
  gap: 128px;

  @media (max-width: 1150px) {
    gap: 48px;
  }

  @media (max-width: 1023px) {
    gap: 12px;
  }
}

.menuList {
  display: flex;
  justify-content: flex-end;
  gap: 48px;

  @media (max-width: 1150px) {
    gap: 24px;
  }

  @media (max-width: 1023px) {
    gap: 12px;
  }
}

.menuList a {
  display: inline-block;
  padding: 30px 0px;
  font-size: 16px;
  line-height: 16px;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColor);
}

.listItem {
  font-size: 16px;
  line-height: 16px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);
  height: 48px;
  width: 160px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 1150px) {
    height: 42px;
    width: 150px;
  }

  @media (max-width: 1023px) {
    height: 36px;
    width: 110px;
  }
}

.lastMenuList {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 18px;

  @media (max-width: 1023px) {
    gap: 0px;
  }
}

.lastMenuListBeforeLogin {
  gap: 24px;

  @media (max-width: 1023px) {
    gap: 12px;
  }
}

.NamedLink_active {
  color: red;
}

.buttonNo {
  width: 144px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
}

.buttonYesCancel {
  width: 144px;
  height: 40px;
  background: #ff4242;
  border-radius: 6px;
  cursor: pointer;
}

.buttonYes {
  width: 144px;
  height: 40px;
  background: #1fd1bc;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 20px;
}

.ListingMessages {
  margin-bottom: 22px;
}