@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;
  @media (--viewportMedium) {
    margin-top: 32px;
  }
  &>div>button{
    max-width: 186px;
    @media (max-width: 767px) {
      width: 152px;
    }
  }
  & label{
    background: #ffffff;
  }
  & input{
    background: #ffffff;
  }
}

.sectionContainer {
  padding: 0;
  margin-bottom: 35px;
  @media (max-width: 767px) {
    margin-bottom: 26px;
  }
}

.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
.lastSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 26px;
  }
}
/* .navMainContainer{
  display: flex;
  gap: 48px;
} */
.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 42px;
  margin-bottom: 51px;
  width: 144px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 767px) {
    width: 124px;
    margin-top: 32px;
    margin-bottom: 41px;
  }
}

.label {
  margin: 0 auto;
  & .avatarContainer {
    margin: 0 auto;
  }
}
.bioContent {
  margin-bottom: 48px;
  @media (max-width: 767px) {
    margin-bottom: 55px;
  }
  & textarea {
    height: 136px !important;
    padding: 19px 16px;
    box-sizing: border-box;
    font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #1F403B;
    @media (max-width: 767px) {
      padding: 15px 16px;
      height: 160px !important;
      font-size: 14px;
line-height: 18px;

    }
  }
}
.phoneContent {
  position: relative;
  & select {
    border: none;
    padding: 0;
    height: 36px;
  }
  & .field {
    position: absolute;
    top: 9px;
    left: 10px;
    z-index: 10;
    width: 90px;
    border-right: 1px solid rgba(128, 128, 128, 0.6);
    padding-right: 5px;
  }
  & .phone {
    & input {
      padding-left: 105px;
    }
  }
}
.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: 144px;
  height: 144px;

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;
 margin: 0 auto;
  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: 100px;
  cursor: pointer;
  @media (max-width: 767px) {
    width: 124px;
    height: 124px;
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  composes: h4 from global;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  min-width: 144px;
  height: 144px;
  @media (max-width: 767px) {
    min-width: 100%;
  height: 100%;
   }
}

.changeAvatar {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  position: absolute;
  bottom: 14px;
  right: -12px;
  color: #1fd1bc;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 767px) {
    bottom: 7px;
    right: -5px;
  }
  & svg{
   @media (max-width: 767px) {
    width: 40px;
    height: 40px;
   }
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: 144px !important;

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  composes: marketplaceDefaultFontStyles from global;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.fileInfo {
  composes: h5 from global;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 26px;
  }
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  margin-top: 24px;
}
.numberInputBox{
  &>div{
    margin-top: 0;
    & input{
      border-bottom-color: var(--successColor) !important;
      &:hover{
        border: 1px solid #060531;
        border-bottom-color: #060531 !important;
      }
      &:focus{
        border: 1px solid #060531;
        border-bottom-color: #060531 !important;
      }
    }
  }
}