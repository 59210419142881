.cardWrapper {
  box-shadow: 0px 36px 48px rgba(119, 118, 156, 0.06);
  border-radius: 24px;
  background: #ffffff;
  max-width: 190px;
  /* overflow: hidden; */
}
.cardImage {
  background: #e5efff;
  height: 112px;
  position: relative;
  border-radius: 24px 24px 0 0;
  @media (max-width: 450px) {
    height: 97px;
  }

}
p,
h5,
h6 {
  margin: 0;
}
.cardContent {
  padding: 16px 10px;
  @media (max-width: 450px) {
  padding: 10px;
  }
  & p {
    font-size: 8px;
    line-height: 8px;
    color: #4b5574;
    opacity: 0.8;
    @media (max-width: 450px) {
      font-size: 8px;
      line-height: 8px;
    }
  }
  & h5 {
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    @media (max-width: 450px) {
      font-size: 10px;
      line-height: 10px;
    }
  }
  & h6 {
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    color: #1fd1bc;
    white-space: pre;
    @media (max-width: 450px) {
      font-size: 10px;
      line-height: 10px;
    }
  }
  & svg {
    width: 100%;
  }
}
.cardTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  @media (max-width: 450px) {
    margin: 5px 0;
  }
}
