@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;
  max-width: 807px;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  margin: auto;
}

.imagesField {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin: 0;
  @media (max-width: 767px) {
    gap: 11px;
   }
}
.uploadImageBox{
  display: flex;
  justify-content: center;
  margin-bottom: 36px;

  @media (max-width: 1200px) {
    margin-bottom: 32px;
   }
   @media (max-width: 430px) {
    margin-bottom: 24px;
  }
   & .addImageWrapper{
    background: #F5F5F5;
    width: 253px;
    height: 200px;
    
    @media (max-width: 767px) {
      width: 167px;
      height: 124px;
    }
    @media (max-width: 430px) {
      width: 116.67px;
      height: 96px;
    }
   }
}
.imageBox{
  background: #F5F5F5;
  width: 253px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  @media (max-width: 1200px) {
    /* height: 100%; */
    width: 100%;
   }
   @media (max-width: 767px) {
    height: 150px;
   }
   @media (max-width: 580px) {
    height: 120px;
   }
   @media (max-width: 450px) {
    height: 96px;
   }
   & svg{
    @media (max-width: 767px) {
      height: 32px;
    width: 32px;
    }
   }
}
.thumbnail, .addImageWrapper {
  border-radius: 6px;
 height: 200px;
 width: 253px;
 @media (max-width: 1200px) {
  /* height: 100%; */
  width: 100%;
 }
 @media (max-width: 767px) {
  height: 150px;
 }
 @media (max-width: 580px) {
  height: 120px;
 }
 @media (max-width: 450px) {
  height: 96px;
 }
  & img{
    height: auto;
    aspect-ratio: 4 / 3.2;
    @media (max-width: 1200px) {
      height: 200px;
     }
    @media (max-width: 767px) {
      height: 150px;
     }
     @media (max-width: 580px) {
      height: 120px;
     }
     @media (max-width: 450px) {
      height: 96px;
     }
  }
 & button{
  border-radius: 100px;
    background: rgba(251, 251, 251, 0.6);
    backdrop-filter: blur(6.7957px);
    top: 16px;
    right: 16px;
    @media (max-width: 767px) {
      top: 8px;
      right: 8px;
      height: 18px;
      width: 18px;
     }
    & svg{
      color: #ffffff;
      fill: #fff;
      stroke: #fff;
      height: 8.5px;
      width: 8.5px;
      @media (max-width: 767px) {
        height: 7px;
        width: 7px;
        top: 6px;
        left: 6px;
       }
    }
 }
}
.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  /* top: 0; */
  z-index: 10;
  padding: 24px 0;
  background: #ffffff;
  box-shadow: 0px 1px 24px rgb(31 209 188 / 10%);
  @media (max-width: 767px) {
    padding: 0;
    gap: 0;
  }
  & button:first-child {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
    color: #1f403b;
    opacity: 0.6;
  }
  & button {
    margin: 0;
    max-width: 186px;
    @media (max-width: 767px) {
      max-width: 100%;
      height: 56px;
      width: 50%;
      border-radius: 0;
    }
  }
}
.addImageWrapper {
  float: left;
  position: relative;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  background: #F5F5F5;
  border: 1px dashed #1F403B;
  border-radius: 6px;
  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);
  &>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    border-color: var(--matterColorAnti);
  }
  & svg{
    @media (max-width: 767px) {
      width: 32px;
      height: 32px;
    }
  }
  & p{
    font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #1F403B;
margin: 8px 0 0;
@media (max-width: 767px) {
  margin: 5px 0 0;
  font-size: 14px;
  line-height: 18px;
}
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  composes: h5 from global;

  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 24px;
  @media (--viewportLarge) {
    display: inline-block;
    margin-top: 100px;
  }
}
