@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}
.tabsMain{
 position: relative;
 &:after{
  z-index: 1;
  position: absolute;
  content: "";
  height: 98%;
  width: 2px;
  opacity: 0.4;
  border-left: 2px dashed #1F403B;
  bottom: 0;
  top: 0;
  left: -2px;
 }
}
.menuCircleWrapper{
  background: #ffffff;
}
.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;

  color: var(--matterColor);
  padding-bottom: 10px;
  padding-top: 10px;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);
  background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
  background-position: calc(var(--TabNav_linkWidth) + 4px) center; /* SelectedLink's width (200px) + border thickness (4px) = 204px */

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    line-height: 24px;

    width: var(--TabNav_linkWidth);
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px;

    padding-top: 6px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 2px;

    &:hover {
      background-image: url("data:image/svg+xml;utf8,<svg width='4' height='32' viewBox='0 0 4 32' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h4v32H0z' fill='var(--marketplaceColorEncoded)' fill-rule='evenodd'/></svg>");
      background-position: right center;
    }
  }
}


.editListNav{
  display: flex;
  flex-direction: column;
  gap: 90px;
  /* border-left: 2px dashed #1f403b4d; */
  padding-left: 23px;
  & .menuCircle{
    position: absolute;
    top: 0;
    left: -40px;
    height: 32px;
    z-index: 9;
    width: 32px;
    background: #fff;
    border: 2px solid #1FD1BC;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & .disabled {
    & svg{
      display: none !important;
    }
    & .menuCircle{
      border: 2px solid rgba(31, 64, 59, 0.4);
    }
    font-weight: 500;
    color: #1F403B;
  }
  & .selectedLink {
    font-weight: 700 !important;
    background: none !important;
    color: #1FD1BC !important;
    & svg{
      display: none !important;
    }
    & .menuCircle{
      border: 2px solid #1FD1BC;
      & .menuCircleDot{
        width: 10px;
  height: 10px;
  background: #1FD1BC;
  border-radius: 100px;
      }
    }
  }
}
.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}
.inboxPage{
  & .link{
    display: flex;
    border: none;
    width: 176px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover, &:focus{
      background: none;
      color: unset;
      border: none;
    }
    &:hover{
      border: 1px solid #000 !important;
      border-radius: 10px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
      font-weight: 700;
    }
    @media (max-width: 767px) {
    width: 140px;
    height: 48px;
    }
    & .menuCircle{
      display: none;
    }
    & .menuCircleWrapper{
      background: #F9F9F9;
      width: 23px;
    }
  }
  & .selectedLink{
    
    border: 1px solid #000 !important;
    border-radius: 10px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    font-weight: 700;
    & .menuCircle{
      display: block;
    }
  }
}