@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;
  @media (max-width: 767px) {
    margin-top: 30px;
  }
  &>button{
    width: 186px;
    height: 40px;
    @media (max-width: 767px) {
      margin: 16px auto 32px;
      width: 152px;
      height: 48px;
    }
  }
}


.disabled {
  opacity: 0.5;
}

.sectionContainer {
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.radioButtonRow {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
}

.radioButtonRow > :first-child {
  margin-right: 36px;
}

.selectCountry {
  margin-bottom: 24px;
}
.accountDetailsTitle{
  font-weight: 700;
font-size: 24px;
line-height: 30px;
color: #060531;
margin: 13px 0 13px;
@media (max-width: 767px) {
  font-size: 16px;
line-height: 18px;
}
}
.payoutDetails{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
  }
  & .savedCountry, & button{
    font-weight: 500;
font-size: 16px;
line-height: 20px;
    color: #1F403B;
opacity: 0.8;
@media (max-width: 767px) {
  font-size: 14px;
line-height: 18px;
}
  }
  & h3{
    font-weight: 700;
font-size: 16px;
line-height: 20px;
color: #060531;
@media (max-width: 767px) {
  font-size: 14px;
line-height: 20px;
}
  }
}
.error {
  composes: marketplaceModalPasswordMargins from global;
}

.termsText {
  composes: marketplaceModalHelperText from global;
  margin-bottom: 12px;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.termsLink {
  composes: marketplaceModalHelperLink from global;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bankDetailsStripeField p {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 17px;
    margin-bottom: 15px;
  }
}

.missingStripeKey {
  color: var(--failColor);
}

.accountInformationTitle {
  composes: h3 from global;
  margin: 0;
  padding-top: 5px;
  padding-bottom: 1px;
}

@media (--viewportMedium) {
  .accountInformationTitle {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedCountry {
  padding-top: 6px;
  padding-bottom: 0px;
}
@media (--viewportMedium) {
  .savedCountry {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.savedInformation {
  margin-bottom: 24px;
}

.savedBankAccount {
  width: 100%;
  margin-bottom: 24px;
  color: var(--matterColor);
  text-align: left;
  background: #ffffff;
  border-radius: 6px;
@media (max-width: 767px) {
  margin-bottom: 8px;
}
  &:hover {
    cursor: text;
    text-decoration: none;
    border-color: var(--matterColor);
  }

  &:focus {
    outline: none;
    border-color: var(--matterColor);
  }
}
