@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  width: auto;
  height: auto;
  border: 0;
  padding: 0;

  &:hover {
    & .priceLabel,
    & .caret {
      cursor: pointer;

      /* Same as active */
      background-color: #000000;
      border-color: var(--marketplaceColor);
      color: #ffffff;
      box-shadow: var(--boxShadowPopup);
      & svg{
        & path{
          stroke: #ffffff;
        }
      }
    }
  }
}
.priceLabel {
  /**
   * Since caret is absolutely positioned,
   * label must have relative to be included to the same rendering layer
   */
  position: relative;

  /* Font */
  letter-spacing: 0.1px;

  box-shadow: var(--boxShadowPopupLight);

  /* Dimensions */
  padding: 6px 10px;
  margin-top: 0;
  margin-bottom: 0;
  transition: var(--transitionStyleButton);

  background: #ffffff;
  border-radius: 13px;
  width: 80px;
  height: 32px;
  font-weight: 500;
  font-size: 16px;
line-height: 20px;
  text-align: center;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  /* Overwrite dimensions from font styles */
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    width: 60px;
    height: 25px;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.013em;
  }
}
.locationLabel{
  font-weight: 600;
  gap: 3px;
  & svg{
    fill: transparent;
    @media (max-width: 767px) {
      width: 11px;
      height: 15px;
    }
  }
}
.priceLabelActive {
  /* Same as hover */
  background-color: #fff;
  width: 30px;
  border-color: var(--marketplaceColor);
  color: var(--matterColorLight);
  box-shadow: var(--boxShadowPopup);

}

.caretShadow {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same box-shadow as label */
  box-shadow: var(--boxShadowPopupLight);
}

.caret {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  transition: var(--transitionStyleButton);

  background: #ffffff;
  border: none;
  @media (max-width: 767px) {
    display: none;
  }
}

.caretActive {
  /* Same as hover */
  background-color: var(--marketplaceColor);
  border-color: var(--marketplaceColor);
}
