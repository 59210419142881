@import '../../styles/customMediaQueries.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  /* height: 96vh !important; */

  /* @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  } */

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}
.ownListing{
  &>div{
    padding: 0 !important;
  }
}
.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopPriceContainer {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 80px;
  gap: 4px;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 31.2%;
    height: 72px;
  }
}
.desktopPerUnit{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #1F403B;
}
.bookingHeading {
  display: flex;
  align-items: center;
  max-width: 450px;
  justify-content: center;
  /* padding: 0 0 16px; */
  @media (max-width: 767px) {
    display: flex !important;
    justify-content: center;
    gap: 11px;
  }
}

.deliveredWrapper {
  border-top: 1px solid #D8DAD9;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 24px 0;

  @media (max-width: 767px) {
    padding: 16px 0;
  }

  & .desktopLine {
    @media (max-width: 767px) {
      display: none;
    }
  }

  & .mobileLine {
    @media (min-width: 768px) {
      display: none;
    }
  }

  & .offText {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1F403B;

    @media (max-width: 767px) {
      letter-spacing: -0.02em;
    }
  }

  & .onText {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #259F46;

    @media (max-width: 767px) {
      letter-spacing: -0.02em;
    }
  }

  & div {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 180px;

    @media (max-width: 767px) {
      width: 146px;
      flex-direction: column;
    }
  }

  & h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.025em;
    }
  }
}

.sectionDescription {
  border-bottom: 1px solid #D8DAD9;
  border-top: 1px solid #D8DAD9;
  padding: 16px 0;
  margin: 0 0 16px;

  & h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1F403B;
    margin: 16px 0 0;

    @media (max-width: 767px) {
      margin: 6px 0 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.desktopPriceValue {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #060531;

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
  }
}

.activeLink {
  border: 1px solid #1fd1bc;
  border-radius: 6px;

  & .desktopPriceValue {
    color: #1fd1bc;
  }

  & .desktopPerUnit {
    color: #1fd1bc;
  }
}

.sectionDescription {
  border-bottom: 1px solid #D8DAD9;
  border-top: 1px solid #D8DAD9;
  padding: 16px 0;
  margin: 0 0 16px;

  & h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1F403B;
    margin: 16px 0 0;

    @media (max-width: 767px) {
      margin: 6px 0 0;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.title {
  /* Font */
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.titleBooking {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #060531;
  margin: 10px 0 32px;

  @media (min-width: 768px) {
    display: none;
  }
}

.author {
  width: 100%;
  composes: h4 from global;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: flex;
  justify-content: space-around;
  margin: 0 auto 16px;
}

.desktopPriceContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
}

.desktopPriceValue {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #060531;

  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
  }
}

.desktopPerUnit {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #1F403B;
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);
  text-transform: capitalize;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
    margin-left: 6px;
  }
}

.locationText {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1f403b;
  margin: 8px 0 0;
  margin-bottom: 10px;

  @media (max-width: 767px) {
    margin: 12px 0 0;
  }
}

.locationWrapper {
  display: flex;
  gap: 7.25px;
  align-items: center;
  border-bottom: 1px solid #D8DAD9;
  margin-bottom: 16px;
  padding: 0 0 16px;
  cursor: pointer;

  @media (max-width: 767px) {
    display: none;
    gap: 3px;
  }

  & svg {
    fill: transparent;

    @media (max-width: 767px) {
      width: 11px;
      height: 14px;
    }
  }

  & .locationText {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #1f403b;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.03em;
    }
  }
}

.bookingTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;
}

.bookingHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 16px 20px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  gap: 4px;
  align-items: center;
  flex-shrink: 0;
  /* padding: 5px 12px; */
}

.priceValue {
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #000000;
}

.perUnit {
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #000000;
  margin: 4px 0 0;
}

.bookButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  width: 100%;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}