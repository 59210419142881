@import '../../styles/customMediaQueries.css';
.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}
.informationContents {
  composes: marketplaceRightPadding from global;
  max-width: 808px;
  @media (min-width: 1500px) {
    max-width: 100%;
  }
}
.form {
  flex-grow: 1;
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: rgba(31, 64, 59, 0.6);
    margin: 8px 0 24px;
    display: flex;
    max-width: 655px;
    @media (max-width: 767px) {
      margin: 12px 0 32px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  & h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: #060531;
    letter-spacing: 0;
    @media (max-width: 767px) {
      text-align: center;
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #060531;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
  }
}
.dividerLine {
  width: 104%;
  height: 1px;
  margin: 16px 0px 16px -16px;
  background: #d8dad9;
  @media (max-width: 767px) {
    display: none;
  }
}
.priceCurrencyInvalid {
  color: var(--failColor);
}
.modalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  & svg{
   @media (max-width: 767px) {
    height: 80px;
    width: 80px;
    gap: 16px;
   }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: rgba(31, 64, 59, 0.8);
    text-align: center;
    @media (max-width: 767px) {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #1f403b;
      opacity: 0.8;
    }
  }
}
