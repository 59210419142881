@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.amaranth-regular {
  font-family: "Amaranth", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.amaranth-bold {
  font-family: "Amaranth", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.amaranth-regular-italic {
  font-family: "Amaranth", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.amaranth-bold-italic {
  font-family: "Amaranth", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.earnSection {
  background-color: #FFFBF2;
}

.imageCovise {
  display: inline-block;
  margin-top: -10px;
}

.laptopText {
  color: #48C0B3;
  background-image: url(../../assets/earnPage/text-line.svg);
  background-position-x: 0;
  background-position-y: 100%;
  background-repeat: no-repeat;
  background-size: 100% 10px;
  padding-bottom: 10px
}

.textBox {
  font-size: 1.6rem;
}

@media screen and (max-width: 768px) {
  .textBox {
    font-size: 1.2rem;
  }
  .imageCovise {
    width: 90px;
    margin-top: -5px;
  }
}

.signup,
.login {
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 16px;

  display: inline-block;
  text-decoration: inherit;

  @media (max-width: 1023px) {
    font-size: 14px;
  }
}


.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 40px;
  padding: 0 12px 0 12px;

  color: var(--matterColor);

  &:hover {
    text-decoration: none;
  }
}

.listItem {
  font-size: 16px;
  line-height: 16px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);
  background-color: var(--marketplaceColor);
  height: 48px;
  width: 160px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 1150px) {
    height: 42px;
    width: 150px;
  }

  @media (max-width: 1023px) {
    height: 36px;
    width: 110px;
  }
}


.underline {
  text-decoration: underline;
}
