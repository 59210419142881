.root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
    margin-top: 0 !important;
}

.scrollingDisabled {
  /* position: fixed and width are added to prevent iOS from scrolling content */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.paymentMethod {
  & .content {
    margin-top: 0 !important;
  }
}

.availability {
  margin-top: 0 !important;
}