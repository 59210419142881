@import '../../styles/customMediaQueries.css';

.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.coverImage {
  width: 100%;
  height: 528px;
  border-radius: 4px;
  object-fit: cover;
  margin: 32px 0 40px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 96px 0 0;

  @media (max-width: 767px) {
    padding: 48px 0 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
  }

  & svg {
    @media (max-width: 767px) {
      width: 135px;
      height: 120px;
    }
  }

  @media (max-width: 767px) {
    padding: 48px 0 0;
  }
}

.contentSide {
  font-style: italic;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
  }
}

.contentMain {
  max-width: 696px;
  text-align: center;

  @media (max-width: 767px) {
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.02);
    border-radius: 20px;
    margin: 0 0 32px;
    max-width: 335px;
    padding: 48px 24px 32px;
  }

  & button {
    max-width: 186px;
    height: 40px;

    @media (max-width: 767px) {
      max-width: 227px;
      width: 227px;
    }
  }

  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #1F403B;
    margin: 0 0 48px;

    @media (max-width: 767px) {
      margin: 0 0 32px;
      line-height: 22px;
    }
  }

  & h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #060531;
    margin: 24px 0 25px;

    @media (max-width: 767px) {
      margin: 24px 0 16px;
      font-size: 22px;
      line-height: 28px;
    }
  }
}

.subtitle {
  margin-top: 32px;
  margin-bottom: 16px;
}

.mainWrapper {
  position: relative;
}

.closeButton {
  display: none;

  @media(max-width: 767px) {
    display: block;
    position: absolute;
    right: 20px;
    top: 16px;
    width: 12.77px;
    height: 14.2px;

    & svg {
      width: 12.77px;
      height: 14.2px;
    }
  }
}