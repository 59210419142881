.mainContainer {
    height: calc(100dvh - 170px);
    max-width: 600px;
    width: 100%;
    padding-top: 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.mainContainer p {
    font-size: 16px;
    line-height: 20px;
}

.heading {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 0 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

.logoIcon {
    padding: 10px;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    border: 1px solid #ccc;
}

.chatContainer {
    flex: 1;
    overflow-y: auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.chatContainer::-webkit-scrollbar {
    width: 5px;
}

.chatContainer::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.chatContainer::-webkit-scrollbar-thumb {
    background: #888;
}

.chatContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.inputContainer {
    display: flex;
    position: fixed;
    bottom: 20px;
    left: 3%;
    align-items: center;
    gap: 10px;
    border-top: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 30px;
    border: 1px solid #ccc;
    width: 94%;
}

.inputContainer .input {
    flex: 1;
    border: none;
    outline: none;
    
}

.sendButton {
    border: none;
    cursor: pointer;
    padding: 10px;
}

.senderMessage {
    background-color: #1FD1BC;
    color: white;
    border-radius: 20px 0px 20px 20px;
    padding: 10px 20px;
    align-self: flex-end;
    max-width: 80%;
    overflow: hidden;
}

.senderMessage .messageTime {
    font-size: 12px;
    color: white;
}

.receiverMessage {
    background-color: whitesmoke;
    border-radius: 0px 20px 20px 20px;
    padding: 10px 20px;
    align-self: flex-start;
    max-width: 80%;
    overflow: hidden;
}

.receiverMessage .messageTime {
    font-size: 12px;
    color: gray;
}

.dateText { 
    font-size: 12px;
    color: gray;
    text-align: center;
    margin: 10px 0;
    background-color: #f1f1f1;
    border-radius: 10px;
    align-self: center;
    padding: 5px 10px;
}
