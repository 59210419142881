@import '../../styles/customMediaQueries.css';

.heading {
  display: 'flex';
  flex-direction: row;
  justify-content: 'space-between';
}

.desktopTitle {
  @media (max-width: 767px) {
    display: none;
  }
}
.sliderWrapper{
 @media (max-width: 767px) {
  margin-left: -5.5px;
 }
}
.mobileTitle {
  @media (min-width: 767px) {
    display: none;
  }
}
.sectionContainer {
  margin: 0 0px 96px;

  @media (max-width: 767px) {
    margin-bottom: 32px;
  }

  & h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #060531;
    margin-bottom: 0;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  & .imageContent {
    & .productContent {
      /* width: 100%;
      min-height: 154px;
      object-fit: cover; */
    }

    margin: 0 -10px;

    @media (max-width: 767px) {
      margin: 0;
    }

    &>div {
      &>div {
        &>div {
          &>div {
            @media(max-width: 768px) {
              /* width: 260px !important; */
              /* margin-right: -4px; */
            }

            /* @media(max-width: 560px) {
              width: 200px !important;
              margin-right: 2px;
            }

            @media(max-width: 460px) {
              width: 175px !important;
              margin-right: 2px;
            }

            @media(max-width: 414px) {
              width: 160px !important;
              margin-right: 2px;
            } */
          }
        }
      }
    }

    & :global(.slick-prev:before) {
      content: unset;
    }
    & :global(.slick-track) {
    display: flex;
  }
    & :global(.slick-next):before,
    & :global(.slick-prev):before {
      content: unset;
    }

    & :global(.slick-slide)>div>div {
      padding: 0 12px;

      @media (max-width: 767px) {
        padding: 0 5.5px;
      }
    }

    & :global(.slick-disabled) svg {
      border: 1px solid black;
      border-radius: 100px;
    }

    & :global(.slick-disabled) path {
      stroke: #000000;
    }

    & :global(.slick-disabled) rect {
      fill: #ffffff;
      stroke: #ffffff;
    }

    & :global(.slick-arrow) {
      right: 40%;
      top: unset;
      bottom: -52px;
    }

    & :global(.slick-next) {
      right: 40%;
    }

    & :global(.slick-prev) {
      left: 40%;
      /* bottom: -64px; */
      z-index: 32;
    }

    text-align: center;

    & a:hover {
      text-decoration: none;
    }

    & .productContent {
      border: 1px solid #e5efff;
      border-radius: 6px;
      width: 100%;
      min-height: 154px;
      object-fit: cover;
      overflow: hidden;
      position: relative;

      @media (max-width: 767px) {
        border-radius: 10px;
      }

      & .contentText {
        position: absolute;
        bottom: 40px;
        left: 0;
        right: 0;

        @media (max-width: 767px) {
          bottom: 24px;
        }

        & button {
          font-weight: 700;
          font-size: 14px;
          line-height: 14px;
          color: #ffffff;
          height: 36px;
          width: auto;
          display: inline;
          padding: 11px 18px;
          border-radius: 100px;
          white-space: pre;
          @media (max-width: 767px) {
            font-weight: 600;
            font-size: 12px;
            height: 25px;
            padding: 0px 15px;
          }
        }

        & .betaText {
          background: #fea515;
          width: auto;
        }

        & .commingText {
          background: #fd4e50;
          width: auto;
        }

        & .newText {
          width: auto;
          background: #1fd1bc;
        }
      }

      & .contextLabel {
        margin-top: 16px;
        @media (max-width: 767px) {
          margin-top: 10px;
        }
      }

      & h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #ffffff;
        margin: 0;

        @media (max-width: 1023px) {
          font-size: 20px;
          line-height: 26px;
        }

        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
        }

        @media(max-width: 460px) {
          font-size: 12px;
        }
      }

      & img {
        width: 100%;
        min-height: 154px;
        object-fit: cover;
        height: 304px;

        @media (max-width: 1300px) {
          height: 270px;
        }

        @media (max-width: 1200px) {
          height: 250px;
        }

        @media (max-width: 1100px) {
          height: 230px;
        }

        @media (max-width: 1023px) {
          height: 210px;
        }

        @media (max-width: 767px) {
          height: 162px;
        }
      }

      &:hover {
        & p {
          color: var(--marketPlaceColor);
          text-decoration: none;
        }
      }

      & p {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        margin: 0;

        @media (max-width: 767px) {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}

.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding-right: 4px;
  @media (max-width: 767px) {
    padding-right: 2px;
    margin-bottom: 16px;
  }
}

.titleWrapper a {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1fd1bc;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
}