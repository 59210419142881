.mainContainer {
    height: calc(100dvh - 100px);
    max-width: 600px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: #F4F6F8;
    overflow-y: auto;
}

.heading {
    max-width: 600px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.title {
    text-align: center;
    margin: 0;
}

.conversationList {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.conversation {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

a,
a:visited,
a:hover {
    text-decoration: none;
    
}

.conversationDetails {
    flex: 1;
}

.conversationDetails h3 {
    margin: 0;
    margin-bottom: 10px;
}

.conversationDetails p{
    color: #666;
}

.searchBar {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #ccc;
}

.searchBar input {
    flex: 1;
    border: none;
    outline: none;
}

.newMessage {
    font-weight: bold;
    color: black !important;
}

.newMessageIndicator {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #6ADF0A;
    color: white;
    float: right;
}
