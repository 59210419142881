@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  border-radius: 6px;
}

.darkSkin {
  background-color: var(--matterColor);
}

.tab {
  width: 50%;

  @media (max-width: 767px) {}

  &:first-child {
    margin-left: 0;
  }
}
.profilePage{
  & .selectedTabContent{
   @media (max-width: 767px) {
    border-radius: 10px !important;
   }
  }
}
.tabContent {
  composes: a from global;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EAEAEA;
  height: 48px;
  transition: var(--transitionStyleButton);

  & h1 {
    font-size: 24px;
    line-height: 24px;
    color: #060531;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    border-bottom-width: 3px;
  }
}

.selectedTabContent {
  background: #1FD1BC;

  & h1 {
    color: #fff !important;
    font-weight: 700;
  }
}

.button {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}



.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}

/* Dark skin */
.tabContentDarkSkin {
  color: var(--matterColorAnti);

  &:hover {
    color: var(--matterColorLight);
  }
}

.selectedTabContentDarkSkin {
  border-bottom-color: var(--matterColorLight);
  color: var(--matterColorLight);
}

.disabledDarkSkin {
  color: var(--matterColorDark);
  opacity: 0.3;
}