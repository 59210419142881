@import '../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 92.3vh;
  height: auto;
  & > a svg {
    height: 36px;
    width: 148px;
  }
}
.contentBorder {
  border-bottom: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 100%;
  &:last-child{
    margin-bottom: 10px;
  }
}
.content {
  flex-grow: 1;
  flex-shrink: 0;
  overflow-y: auto;
  max-height: calc(100dvh - 180px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 24px;
  /* 113 for footer and an extra 67 to get scrolling work in mobile safari */
  margin-bottom: 80px;
  &>button {
    border: none;
    margin: 10px 0 0;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 20px;
  width: 100%;
  max-width: 280px;
  padding: 0 0 32px;
  background-color: var(--matterColorLight);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  composes: h1 from global;
  margin-bottom: 1px;
  margin-top: 16px;
}

.navigationLink {
  /* Font */
  composes: h2 from global;
  color: var(--marketplaceColor);

  display: flex;
  align-items: center;
  gap: 10px;
  /* Layout */
  margin: 0;
  padding: 10px 0;
  & svg {
    color: #060531;
    width: 20px;
    fill: transparent;
  }
  & span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
  }
}
.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: button buttonText marketplaceButtonBorders buttonColors from global;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  max-width: 280px;
  width: 100%;
  height: 56px;
  margin: 0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}
.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}
.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}
