@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Container for side and content */
.container {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperMain {
  composes: marketplaceSectionWrapper from global; 
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background: #FBFBFB;
  min-height: 93vh;
}
.inboxPage{
 & .layoutWrapperMain {
  @media (max-width: 767px) {
    padding: 0px 20px;
    width: 100%;
  }
  }
  & .container {
    flex-direction: row;
    @media (max-width: 767px) {
      flex-direction: column;
      padding: 0;
    }
}
}