@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 0;
}

.locations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  flex-direction: column;
  gap: 56px;
  margin: 99px 0 120px;
  @media (max-width: 1200px) {
    margin: 80px 0 100px;
  }
  @media (max-width: 1100px) {
    gap: 45px;
    margin: 70px 0 90px;
  }
  @media (max-width: 1023px) {
    gap: 35px;
  }
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 26px;
    align-items: flex-start;
    margin: 32px 0 32px;
  }

  & svg {
    fill: transparent;
    @media (max-width: 1100px) {
      width: 70px;
    }
    @media (max-width: 1023px) {
      width: 60px;
    }
    @media (max-width: 767px) {
      min-width: 54px;
      height: 54px;
    }
  }
  & h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
    color: #060531;
    margin: 27px 0 8px;
    @media (max-width: 1200px) {
      font-size: 28px;
      line-height: 28px;
    }
    @media (max-width: 1100px) {
      font-size: 26px;
      line-height: 26px;
    }
    @media (max-width: 1023px) {
      font-size: 23px;
      line-height: 23px;
    }
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
      margin: 0px 0 4px;
    }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1f403b;
    opacity: 0.8;
    margin: 0;
    @media (max-width: 1200px) {
      font-size: 15px;
      line-height: 23px;
    }
    @media (max-width: 1023px) {
      font-size: 14px;
      line-height: 20px;
      height: 60px;
    }
    @media (max-width: 767px) {
      height: auto;
    }
  }

  & div {
    text-align: center;
  }
  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  @media (--viewportMedium) {
    margin-right: 40px;
    margin-bottom: 0;
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: calc(2 / 3 * 100%); /* 3:2 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  composes: h2 from global;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  color: var(--marketplaceColor);
}

.textContent {
  @media (max-width: 767px) {
    display: flex;
    gap: 15px;
  }
  & div {
    @media (max-width: 767px) {
      text-align: left;
    }
  }
}
