@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 24px 24px 48px;
  border-radius: 6px;
  position: relative;
  width: 416px;
  &:after {
    @media (max-width: 767px) {
      display: none;
    }
    position: absolute;
    content: '';
    top: -23px;
    right: -2px;
    height: 40px;
    width: 40px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='m7 14l5-5l5 5Z'/%3E%3C/svg%3E");
  }
  @media (max-width: 767px) {
    padding: 0;
    background-color: transparent;
  }
}

.filtersWrapper {
  & h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #060606;
    text-align: center;
    margin: 0 0 24px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  & button {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    background: #ffffff;
    padding: 0;
    margin-bottom: 7.5px;
    border: none;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 4px;
    &:focus,
    &:hover {
      background: none;
    }
    @media (max-width: 767px) {
      margin-bottom: 6px;
      font-size: 14px;
      line-height: 18px;
    }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    opacity: 0.8;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 24px;
  @media (max-width: 767px) {
    margin-top: 22px;
    gap: 11px;
  }
  & button {
    background: #1fd1bc;
    border-radius: 6px;
    height: 40px;
    width: 144px;
    background: #1fd1bc;
    border-radius: 6px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-transform: uppercase;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
      width: 138px;
    }
    &:first-child {
      color: #060531;
      background: #ffffff;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    }
  }
}
.sortByContent {
  margin-bottom: 24px;
  position: relative;
  z-index: 110;
  & p{
    @media (max-width: 767px) {
      font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #1F403B;
    }
  }
}
.browseByContent {
  margin-bottom: 24px;
  & p{
    @media (max-width: 767px) {
      font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #1F403B;
    }
  }
}
.filterButton {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}
.resetAllButton {
  composes: filterButton;
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.cancelButton {
  composes: filterButton;
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 24px 0 24px;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.applyButton {
  composes: filterButton;
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0;

  /* Override button styles */
  outline: none;

  /* &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  } */
}
