.cardWrapper {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
  padding: 24px;
  margin-top: 24px;
  @media (max-width: 767px) {
    padding: 0px;
    margin-top: 0;
    box-shadow: none;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ececec;
    &:last-child {
      border: none;
      padding-bottom: 0px;
    }
  }
}
.mainContents {
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
}

.totalAmount {
  display: flex;
  align-items: center;
  gap: 8px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  & .accountStaus {
    display: flex;
    align-items: center;
    gap: 4px;
    background: rgb(31 209 188 / 5%);
    padding: 4px 8px;
    border-radius: 20px;
  }
  & .activeCircle {
    background: #15e24d;
    height: 14px;
    width: 14px;
    border-radius: 100px;
    @media (max-width: 767px) {
      height: 12px;
      width: 12px;
    }
  }
  & .accountPaused {
    display: flex;
    align-items: center;
    gap: 4px;
    background: rgb(254 165 21 / 5%);
    padding: 4px 8px;
    border-radius: 20px;
  }
  & .pausedCircle {
    background: #fea515;
    height: 14px;
    width: 14px;
    border-radius: 100px;
    @media (max-width: 767px) {
      height: 12px;
      width: 12px;
    }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
  & h6 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #060531;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
.paragraph {
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 158%;
    color: #1f403b;
    margin: 16px 0 0;
    @media (max-width: 767px) {
      margin: 12px 0 0;
      font-size: 14px;
      line-height: 22px;
    }
  }
}
.viewDetails {
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 767px) {
    gap: 8px;
  }
  & h6 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    @media (max-width: 767px) {
    }
  }
}
.view {
  border: 1px solid #1fd1bc;
  border-radius: 6px;
  color: #1fd1bc;
}
.earnings {
  width: 30%;
  margin-right: 35px;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
  padding: 30px;
}

.mainEarnings {
  display: flex;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
}

.monthly {
  width: 70%;
  padding: 50px;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
}
.monthly p {
  padding: 30px 0px 30px 0px;
}
.mainCalender {
  display: flex;
  width: 100%;
}

.monthOne {
  width: 100%;

  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
}

.monthTwo {
  width: 100%;

  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
}

.viewAll {
  text-align: center;
}
.viewAll button {
  border: none;
  color: white;
  width: 474px;
  height: 40px;
  background: #1fd1bc;
  border-radius: 6px;
}
.bottomCard {
  display: flex;
  width: 100%;
}
.card {
  padding: 40px;
  width: 500px;
  height: 162px;
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(6, 5, 49, 0.1);
  border-radius: 6px;
}
.ball {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 4px;
  left: 4px;
  transition: transform 0.5s ease;
  background: #ffffff;

  border-radius: 13.5px;
  @media (max-width: 767px) {
    width: 18px;
    height: 18px;
    top: 3px;
    left: 3px;
  }
  &.toggled {
    transform: translateX(167%);
    background: #1fd1bc;
    @media (max-width: 767px) {
      transform: translateX(135%);
    }
  }
}
.checkboxWrapper {
  cursor: pointer;
  position: relative;
  font-family: var(--font-familyLite);
  width: 72px;
  height: 32px;
  background: #d8dad9;
  opacity: 0.72;
  border-radius: 20px;
  @media (max-width: 767px) {
    width: 48px;
    height: 24px;
  }
}
.buttonNo {
  width: 144px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
}

.buttonYesCancel {
  width: 144px;
  height: 40px;
  background: #ff4242;
  border-radius: 6px;
  cursor: pointer;
}
.buttonYes {
  margin-right: 20px;
  width: 144px;
  height: 40px;
  background: #1fd1bc;
  border-radius: 6px;
  cursor: pointer;
}
.ListingMessages {
  margin-bottom: 22px;
}
.settingCards {
  max-width: 808px;
}
.changeCards {
  display: flex;
  align-items: center;
  gap: 24px;
  @media (max-width: 1023px) {
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }
}
.textContents {
  & h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    @media (max-width: 1023px) {
      font-size: 16px;
      line-height: 18px;
    }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #1f403b;
    opacity: 0.72;
    margin: 0 0 8px;
    @media (max-width: 1023px) {
      font-size: 14px;
      line-height: 14px;
    }
  }
}
.addressDescription {
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
}
.changeCard {
  background: #f4f6f8;
  border: 1px solid #d8dad9;
  border-radius: 6px;
  padding: 28px 24px 28px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 368px;
  max-height: 76px;
  margin: 16px 0 0;
  @media (max-width: 767px) {
    padding: 16px;
    margin: 0;
  }
  & svg {
    fill: transparent;
    @media (max-width: 767px) {
      width: 10px;
      height: 14px;
    }
  }
  & .locationContent {
    display: flex;
    align-items: center;
    gap: 6.7px;
    @media (max-width: 767px) {
      gap: 4px;
    }
    & p {
      font-weight: 600;
      font-size: 14px;
      line-height: 10px;
      color: #1f403b;
      @media (max-width: 1023px) {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: -0.02em;
      }
    }
  }
  & button {
    border: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #1fd1bc;
    padding: 0;
    @media (max-width: 1023px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
.modalContainer {
  text-align: center;
  & a{
    font-weight: 700;
  }
  & h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #1f403b;
    margin: 0 0 6px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
      color: #060531;
    }
  }
}
