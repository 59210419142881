@import '../../styles/customMediaQueries.css';

.root {
  z-index: 110;
  & button{
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #1F403B !important;
    @media (max-width: 767px) {
      font-size: 14px !important;
    }
  }
}

.icon {
  position: relative;
  bottom: 1px;
  margin-right: 5px;

  @media (--viewportMedium) {
    bottom: 2px;
  }
}

.menuLabel {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  white-space: nowrap;
  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.menuContent {
  margin-top: 7px;
  padding-top: 12px;
  padding-bottom: 24px;
  min-width: 300px;
  border-radius: 4px;
  @media (max-width: 767px) {
    margin-top: -16px;
  }
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  /* height: calc(100% - 4px);
  width: 0;
  background-color: var(--marketplaceColor);
  transition: width var(--transitionStyleButton); */

}

/* left static border for selected element */
.menuItemBorderSelected {
  position: absolute;
  top: 2px;
  left: 0px;
  height: 30px;
  width: 100%;
  background-color: #0000000f;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.menuHeading {
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColor);
  margin: 0 !important;
}

.menuItem {
  composes: marketplaceListingAttributeFontStyles from global;
  color: var(--matterColor);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: -6px auto !important;
  padding: 4px 30px;

  line-height: 30px !important;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }

  &:disabled {
    color: var(--matterColorAnti);
    cursor: default;
  }
  &:disabled:hover .menuItemBorder {
    width: 0;
  }
}

.clearMenuItem {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  position: relative;
  min-width: 300px;
  margin: 0;
  padding: 32px 30px 18px 30px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  cursor: pointer;
  transition: width var(--transitionStyleButton);

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}
