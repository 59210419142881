@import '../../styles/customMediaQueries.css';

.root {
  position: relative;

  width: 100%;
  padding: 0 0 16px 0;
  background-color: var(--matterColorBright);

  @media (max-width: 767px) {
    padding: 0 0 32px 0;
  }

  @media (--viewportMedium) {
    padding: 0 0 24px;
  }

  @media (--viewportLarge) {
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  padding-top: 32px;
  border-top-style: dashed;
  border-top-width: 1.5px;
  border-top-color: #1f403b5e;

  @media (--viewportMedium) {
    padding-top: 49px;
  }

  @media (--viewportLarge) {
    padding-top: 48px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  composes: marketplaceSectionWrapper from global;
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  text-align: center;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    height: 32px;
    width: 32px;
    padding: 7px;
  }

  & svg {
  }
}

/* Footer links */
.links {
  /* Layout */

  width: 100%;
  margin-bottom: 18px;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-bottom: 24px;
  }

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: 240px;
    display: block;
    order: 1;
    margin-right: 12px;
  }

  @media (--viewportLarge) {
    flex-basis: 240px;
    margin-right: 57px;
  }

  @media (--viewportLargeWithPaddings) {
    flex-basis: 382px;
    margin-right: 12px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;

  @media (max-width: 767px) {
    margin-bottom: 12px;
  }
}

.logo {
  object-fit: contain;
  object-position: left center;
  width: 100%;

  & svg {
    @media (max-width: 1100px) {
      width: 160px;
    }

    @media (max-width: 767px) {
      width: 148px;
      height: 36px;
    }
  }
}

.organizationInfo {
}

.organizationDescription {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  color: #1f403b;
  opacity: 0.8;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}

.organizationCopyright {
  margin: 8px 0 0 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #060531;
}

.copyrightLink {
  color: var(--matterColor);
  transition: var(--transitionStyleButton);
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  margin-right: 24px;
  margin-bottom: 48px;

  @media (max-width: 767px) {
    margin-bottom: 0px;
    flex-basis: 162px;
    margin-right: 11px;
  }

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 22px;
  border-bottom: 1px solid black;

  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: black;
  &:hover {
    border-bottom: 1px solid var(--marketplaceColor);
  }
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.link {
  /* Font */
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
  }

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;

  /* margin-right: 24px;
  margin-bottom: 24px; */
  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }

  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  /* flex-grow: 1; */

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tosAndPrivacy {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 0;
  margin-bottom: 0;
  margin: 0 auto;
  & >li{
    border-bottom: 1px solid black;
  }

}

.legalLink,
.privacy,
.terms {
  /* Font */
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #060531;
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
    
    display: block;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}

.mobileApps {
  display: flex;
  flex-direction: column;
  gap: 9px;
  order: 6;

  @media (max-width: 767px) {
    flex-direction: row;
  }
}

.blogColor {
  color: #fd4e50;
}

.runtizeColor {
  color: #1fd1bc;
}

.socialMediaContainer {
  margin: 0 auto;
  width: 100%;
  margin-top: 8px;

  @media (--viewportSmall) {
    order: 5;
    width: 24%;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 2rem;

  }
}

.copyrightContainer {
  border-top: 1px solid var(--matterColorNegative);
  padding-top: 8px;
  text-align: center;

  @media (max-width: 767px) {
    padding-top: 0px;
  }
}

.linksContainer {
  order: 2;
  display: flex;
  margin-top: 32px;
  align-items: center;
  min-width: 280px;
  justify-content: space-between;

  @media (max-width: 767px) {
    width: 100%;
    display: none;
  }
}

.linksContainerMobile {
  order: 2;
  display: none;
  text-align: center;

  & > div {
    
    width: 100%;
    justify-content: space-between;

  }

  @media (max-width: 767px) {
    display: block;
  }
}
.organizationCopyrightMobile{
  margin-top: 1.5rem;
  font-size: 14px;
}