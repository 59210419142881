@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  background-color: var(--matterColorBright);
  position: relative;
}

.mobileFilterBack {
  @media (max-width: 768px) {
    display: none;
  }
}

.inputMain2 {
  position: absolute;
  top: -83px;
  background: #ffffff;
  padding: 16px 20px;
  width: 100%;
  z-index: 110;
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.06);

  @media (min-width: 768px) {
    display: none;
  }
}

.inputMainApple{
  position: fixed;
  top: 65px;
  background: #ffffff;
  padding: 16px 20px;
  width: 100%;
  z-index: 110;
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: center;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.06);

  @media (min-width: 768px) {
    display: none;
  }
}

.inputList {
  z-index: -1;
}

.longInfo {
  flex-wrap: wrap;
}

.searchFilterButton {
  position: absolute;
  right: 16px;
  top: 10px;

  @media (max-width: 1023px) {
    top: 9px;
    right: 12px;
  }

  & svg {
    @media (max-width: 1023px) {
      width: 32px;
      height: 32px;
    }
  }
}
.searchFilterButtonApple {
  border: 1px solid #EAEAEA;
  padding: 4px;
  aspect-ratio: 1;
  display: flex !important;
  align-items: center;
  justify-content: center;
  max-width: 100% !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 10px !important;
  margin: initial !important;
}

.resultText {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #060531;

  @media (max-width: 1360px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: 1023px) {
    font-size: 18px;
    line-height: 22px;
  }
}

.searchFilterBack {
  @media (max-width: 767px) {
    display: none;
  }

  & svg {
    @media (max-width: 1023px) {
      width: 35px;
      height: 35px;
    }
  }
}

.mobileFilterBack {
  @media (min-width: 768px) {
    display: none;
  }

  & svg {
    @media (max-width: 767px) {
      width: 35px;
      height: 35px;
    }
  }
}

.listButton {
  /* position: fixed;
  right: 0;
  left: 0; */
  cursor: pointer;
  background: #1fd1bc;
  box-shadow: 0px 0px 8px 6px rgb(0 0 0 / 3%);
  border-radius: 6px;
  max-width: 102px;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 1150px) {
    max-width: 85px;
    height: 48px;
  }

  @media (max-width: 767px) {
    display: none;
  }

  & p {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }
}

.routeLink {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;

  @media (max-width: 767px) {
    /* background: #f9f9f9; */
    display: none;
    gap: 8px;
    height: 24px;
  }

  & svg {
    @media (max-width: 767px) {
      width: 11px;
      height: 11px;
    }
  }

  & a {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #060531;
    text-decoration: none;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 12px;
    }
  }

  & .accountSetting {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1fd1bc;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.inputMain {
  margin: 16px 0 16px;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1023px) {
    gap: 12px;
  }

  @media (max-width: 767px) {
    gap: 6px;
    padding: 16px 0 16px;
    margin: 0;
    justify-content: center;
    width: 100%;
  }
}

.searchBarWrapper {
  position: sticky;
  z-index: 9;
  top: 78px;
  background: transparent;
  width: 100%;

  @media (max-width: 767px) {
    top: 0;
  }
  @media (min-width: 768px) {
    /* grid-template-columns: repeat(3, 1fr); */
    width: 47%;
  }
  @container (min-width: 768px) {
    width: 100% !important;
  }
}

.searchBarWrapper2 {
  z-index: 101;
  height: 0;
}

.inputWrapperMain {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #f4f6f8;
  border-radius: 10px;
  padding: 8px 16px 8px 16px;
  max-width: 592px;
  width: 100%;
  height: 56px;

  @media (max-width: 1023px) {
    padding: 8px 12px 8px 12px;
    max-width: 430px;
    height: 50px;
  }

  & > svg {
    position: absolute;
    top: 16px;
    left: 16px;

    @media (max-width: 1023px) {
      left: 12px;
      width: 18px;
      height: 18px;
    }
  }

  & button {
    border: none;
    background: #f5f5f5;
    margin: 0;
    padding: 0;

    /* &:focus {
      background: #f5f5f5;
      box-shadow: none;
      border-color: #f5f5f5;
    }

    &:hover {
      background: #f5f5f5;
      box-shadow: none;
      border-color: #f5f5f5;
    } */
  }

  & svg {
    fill: transparent;
  }

  & input {
    border: 0;
    padding-left: 30px;
    width: 100%;
    background: transparent;
    height: auto;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #1f403b;
    width: 100%;

    @media (max-width: 1023px) {
      padding-left: 22px;
      font-size: 14px;
      line-height: 18px;
      padding-bottom: 3px;
    }

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #1f403b;
      opacity: 0.4;
    }

    &:focus {
      outline: none;
      border: 0;
    }
  }

  position: relative;

  & .iconSearchBtn {
    position: absolute;
    left: 19px;
    top: 8px;
    z-index: 2;
    background: transparent;
    padding: 0;
    width: auto;

    @media (max-width: 767px) {
      top: 1px;
    }

    &:focus {
      outline: none;
      background: transparent;
    }

    &:active {
      outline: none;
      background: transparent;
    }
  }
}

.searchOptions {
  display: flex;
  flex-direction: row;
  margin: 0;

  @media (max-width: 767px) {
    /* margin: 8px 0 16px; */
    margin: 0;
  }
}

.searchResultSummary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 16px;

  & .resultsFound {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: rgba(31, 64, 59, 0.8);

    @media (max-width: 1360px) {
      font-size: 24px;
      line-height: 28px;
    }

    @media (max-width: 1023px) {
      font-size: 18px;
      line-height: 22px;
    }

    @media (max-width: 767px) {
      margin: 0;
    }
  }
}

.noSearchResults {
  composes: h4 from global;

  /* Reserves 48px of vertical space */
  line-height: 20px;
  margin-top: 16px;
  margin-bottom: 12px;

  /* parent uses flexbox: this defines minimum width for translated text "noResults" */
  flex-basis: 100%;
  flex-shrink: 0;
}

.loadingResults {
  composes: h4 from global;
  line-height: 20px;

  /* Cover parent element's space*/
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* Layout */
  padding: 9px 24px 0 24px;
  margin: 0;
  background-color: var(--matterColorBright);

  @media (--viewportLarge) {
    padding: 9px 36px 0 36px;
  }
}

.resultsFound {
  white-space: nowrap;
}

.searchFiltersPanelClosed {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  display: inline-block;
  padding: 0;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelOpen {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  display: inline-block;
  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.searchFiltersPanelAppleClosed {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;

  display: inline-block;
  padding: 0;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  &:focus {
    outline: none;
    border-color: transparent;
    text-decoration: none;
    box-shadow: var(--boxShadowFilterButton);
  }
}

.searchFiltersPanelAppleOpen {
  composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  display: inline-block;
  padding: 9px 16px 9px 16px;
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--marketplaceColor);

  &:hover,
  &:focus {
    border: 1px solid var(--marketplaceColorDark);
  }
}

.appleVisionWrapper {
  padding-top: 14px;
  padding-bottom: 22px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 17px 44px rgb(0 0 0 / 10%);
  margin: 1rem 0;
  border-radius: 1.1rem;
  @media (min-width: 768px) {
    /* grid-template-columns: repeat(3, 1fr); */
    width: 47%;
  }
  @container (min-width: 768px) {
    width: 100% !important;
  }
}

.cardWrapper {
  position: relative;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 20px;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  gap: 6px;
}

.cardWrapper h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;

  color: #ffffff;
}

.cardWrapper p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 11px;

  color: rgba(255, 255, 255, 0.7);

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  max-width: 61%;
}

.bgDarkLayer {
  position: absolute;
  border-radius: 12px;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.visionPro {
  position: absolute;
  border-radius: 12px;
  height: 100%;
  top: 0;
  right: 0;
  object-fit: cover;
  z-index: 1;
}
.linearGradient1 {
  position: absolute;
  border-radius: 12px;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 0;
}
.linearGradient2 {
  position: absolute;
  border-radius: 12px;
  height: 100%;
  bottom: 0;
  right: 0;
  object-fit: cover;
  z-index: 0;
}


.appleIconsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.appleVisionColor {
  color: #68cebc;
}

.appleVisionHeading {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  font-size: 22px;
}

.appleVisionParagraph {
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  font-size: 12px;
  padding-right: 5px;
  font-weight: normal;
}

.appleSearchBarWrapper {
  display: grid;
  grid-template-columns: 4fr repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 0px;
  margin: 1rem auto;
}

.searchBar {
  border: 1px solid #EAEAEA;
  /* margin: 10px 0; */
  height: 40px;
}
