@import '../../styles/customMediaQueries.css';

.root {
}

.verificiationBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 16px;
  margin-bottom: 48px;

  border-radius: 4px;
  @media (max-width: 767px) {
    margin-bottom: 32px;
    padding: 12px 16px;
  }
}

  .verificiationBox {
    flex-wrap: nowrap;
  }


.verificiationBoxTextWrapper {
  display: flex;
  flex-wrap: wrap;
}

@media (--viewportMedium) {
  .verificiationBoxTextWrapper {
    margin-bottom: 0px;
  }
}

.verificationBoxTitle {
  & svg{
  @media (max-width: 767px) {
    width: 20px;
    height: 20px;
  }
  }
  display: flex;
  align-items: center;
  gap: 12px;
& p{
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #1F403B;
  opacity: 0.8;
  @media (max-width: 767px) {
    font-size: 14px;
line-height: 18px;
      }
  @media (max-width: 450px) {
max-width: 157px;
      }
}

  align-self: center;

  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.verificationBoxText {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #1F403B;
  opacity: 0.8;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 767px) {
font-size: 12px;
line-height: 16px;
    
  }
}

.getVerifiedButton {
  font-weight: 600;
font-size: 18px;
line-height: 22px;
  border-radius: 6px;
  min-height: 40px;
  min-width: 144px;
  @media (max-width: 767px) {
    font-size: 14px;
line-height: 18px;
min-height: 36px;
min-width: 124px;
max-height: 36px;
max-width: 124px;
  }
}

/* Verification required box */
.verficiationNeededBox {
  background: rgba(254, 165, 21, 0.12);
box-shadow: -6px 0px 0px #FEA515;
border-radius: 6px;
gap: 17px;
}

/* Verification error box */
.verficiationErrorBox {
  background: var(--failColorLight);
  border: 1px solid var(--failColor);
}

/* Verification success box */
.verficiationSuccessBox {
  padding: 8px 16px;
  height: 72px;
  background: rgba(37, 159, 70, 0.12);
  box-shadow: -6px 0px 0px #259f46;
  border-radius: 6px;
  @media (max-width: 767px) {
    height: 60px;
  }
}

.verificationBoxSuccessTextWrapper {
  margin-bottom: 0;
}
.editVerificationButton {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightNormal);
  min-height: 46px;
  margin: 0;
  @media (max-width: 767px) {
    min-height: 30px;
  }
  & svg{
    @media (max-width: 767px) {
      width: 24px;
      height: 24px;
    }
  }
  &:hover,
  &:focus {
    outline: none;
    color: var(--matterColorDark);
  }
}

.icon {
  margin-right: 10px;
}

.iconEditPencil {
  stroke: var(--matterColor);
}

.spinner {
  width: 24px;
  height: 24px;
  stroke: var(--successColor);
  stroke-width: 3px;
}
