.Topheading {
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
  @media (max-width: 767px) {
    margin: 0 0 24px;
    flex-direction: column;
    align-items: center;
  }
  & p {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #1f403b;
    opacity: 0.6;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
    }
  }
  & .totalAmount {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #1fd1bc;
    @media (max-width: 767px) {
      font-weight: 700;
      font-size: 22px;
      line-height: 100%;
      color: #000000;
    }
  }
  & h2 {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    color: #060531;
    @media (max-width: 767px) {
      margin: 24px 0 23px;
      font-size: 36px;
      line-height: 48px;
    }
  }
}
.amountTable tr th {
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
}
.amountTable {
  margin: 0 -16px;
  @media (max-width: 767px) {
    margin: 0 -20px;
  }
  & th {
    width: 80px;
  }
  & td {
    width: 80px;
    text-align: center;
  }
  & tr {
    padding: 0 16px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
      padding: 0 20px;
    }
    & td:first-child {
      text-align: left;
    }
    & td:last-child {
      text-align: right;
    }
  }
  & .thead {
    background: #1fd1bc;
  }
  & table {
    width: 100%;
  }
  & tbody {
    & tr {
      height: 48px;
      margin: 8px 0 0;
      @media (max-width: 767px) {
        margin: 1px 0 0;
        height: 36px;
        &:first-child {
          margin: 8px 0 0;
        }
      }
      &:nth-child(even) {
        background: hsla(0, 0%, 98%, 0.6);
      }
      & td {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #060531;
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
      & td:first-child {
        font-weight: 700;
      }
      & td:last-child {
        font-weight: 600;
        color: hsla(173, 74%, 47%, 0.855);
      }
    }
  }
  & thead {
    background: #1fd1bc;
    margin: 0 0 8px;
    & tr {
      height: 56px;
      @media (max-width: 767px) {
        height: 36px;
      }
    }
    & th {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}


