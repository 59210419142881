.root {
    position: relative;
    max-height: 99px;
    margin: 24px 0 0;
  }
  
  .filterLabel,
  .filterLabelSelected {
  
    /* Baseline adjustment for label text */
margin: 0;
  }
  
  .filterLabel {
    color: var(--matterColorDark);
   @media (min-width: 767px) {
    margin-top: 6px;
   }
  }
  
  .filterLabelSelected {
    color: var(--marketplaceColor);
  }
  
  .labelButton {
    /* Override button styles */
    outline: none;
    text-align: left;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .clearButton {
    composes: h5 from global;
    font-weight: var(--fontWeightMedium);
    color: var(--matterColorAnti);
  
    /* Layout */
    display: inline;
    float: right;
    margin-top: 6px;
    padding: 0;
  
    /* Override button styles */
    outline: none;
    text-align: left;
    border: none;
  
    &:focus,
    &:hover {
      color: var(--matterColor);
    }
  }
  