@import '../../styles/customMediaQueries.css';

.root {
  margin-bottom: 24px;
}

.filterLabel,
.filterLabelSelected {
  composes: h3 from global;

  /* Baseline adjustment for label text */
  margin-top: 0;
margin: 0;
  padding: 0;
}
.optionText{
  margin-left: -30px;
  & button{
    margin: 0;
  }
}
.instantBookingContainer{
  display: flex;
  align-items: center;
  gap: 8px;
}
.filterLabel {
  color: var(--matterColorDark);
}

.filterLabelSelected {
  color: var(--marketplaceColor);
}

.labelButton {
  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
  margin-bottom: 4px !important;
  @media (max-width: 767px) {
    margin-bottom: 2px !important;
  }
}
.selectButton{

}
.optionsContainerOpen {
  height: auto;
  margin-left: 28px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.optionsContainerClosed {
  height: 0;
  overflow: hidden;
}

.hasBullets {
  padding-left: 26px;
}

.twoColumns {
  @media (--viewportMedium) {
    column-count: 2;
  }
}

.optionBorder,
.optionBorderSelected {
  position: absolute;
  height: calc(100% - 12px);
  top: 10px;
  left: -28px;
  transition: width var(--transitionStyleButton);
}

/* left animated "border" like hover element */
.optionBorder {
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.578125' width='19' height='19' rx='2.5' stroke='%23D8DAD9'/%3E%3C/svg%3E%0A");
@media (max-width: 767px) {
  width: 16px;
  height: 16px;
  background-size: 16px;
}
}

/* left static border for selected element */


.optionBullet,
.optionBulletSelected {
  position: absolute;
  left: -5px;
  top: 13px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: var(--marketplaceColor);
  transition: opacity var(--transitionStyleButton);
}

/* left animated "border" like hover element */
.optionBullet {
  opacity: 0;
}

/* left static border for selected element */
.optionBulletSelected {
  opacity: 1;
}

.option {
  composes: h4 from global;
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  color: var(--matterColor);

  /* Layout */
  display: block;
  position: relative;
  margin: 0 !important;
  padding: 4px 0 8px 20px;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColorDark);
  }

  &:hover .menuItemBorder {
    width: 6px;
  }
  & .optionBorderSelected {
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='20' height='20' rx='6' fill='%231FD1BC'/%3E%3Cpath d='M7.84753 12.6457L14.1973 6.29596C14.3946 6.09865 14.6457 6 14.9507 6C15.2556 6 15.5067 6.09865 15.704 6.29596C15.9013 6.49327 16 6.74439 16 7.04933C16 7.35426 15.9013 7.60538 15.704 7.80269L8.6009 14.9058C8.38565 15.1211 8.13453 15.2287 7.84753 15.2287C7.56054 15.2287 7.30942 15.1211 7.09417 14.9058L4.29596 12.1076C4.09865 11.9103 4 11.6592 4 11.3543C4 11.0493 4.09865 10.7982 4.29596 10.6009C4.49327 10.4036 4.74439 10.3049 5.04933 10.3049C5.35426 10.3049 5.60538 10.4036 5.80269 10.6009L7.84753 12.6457Z' fill='white'/%3E%3C/svg%3E%0A");
 @media (max-width: 767px) {
  width: 16px;
  height: 16px;
  background-size: 16px;
 }
  }
}
.browseByText{
  margin-left: -28px;
  font-weight: 600 !important;
  opacity: 1 !important;
}
.optionSelected {
  composes: option;
  color: var(--matterColorDark);
}

.clearButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  display: inline;
  float: right;
  margin-top: 6px;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}
