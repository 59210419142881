.suggestedWord {
  cursor: pointer;
  text-align: left;
  /* border-bottom: 1px solid #e6e6e6; */
  /* padding-bottom: 10px; */
  margin: 0;
  /* padding-top: 10px; */
}

/* .suggestedWord:hover{
      background-color: rgb(202, 198, 198);
  } */

.autoSuggestion {
  max-height: 238px;
  width: 379px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  position: absolute;
  left: -16px;
  top: 155%;
  right: 0;
  padding: 0px;
  border-radius: 5px;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 2px 4px rgba(7, 4, 146, 0.1), 0px 24px 60px rgba(6, 47, 125, 0.05),
    0px 12px 24px rgba(27, 59, 119, 0.2);
  z-index: 10;

  @media (max-width: 767px) {
    padding: 0px 0px;
    width: 100%;
    left: 0;
    right: 0;
    border: 0;
    /* top: 125%; */
    top: 36px;
    visibility: visible;
    /* max-height: calc(var(--app-height) - 126px); */
  }

  & .suggestedItems {
    max-height: 379px;
    padding: 4px;

    @media (max-width: 767px) {
      padding: 0px 0px;
      max-height: calc(var(--app-height) - 126px);
    }

    & .items {
      margin: 0;
      line-height: 1;
      /* border-bottom: 1px solid #d1d1d1; */
      padding: 10px;
      display: flex;
      align-items: center;

      &>span {
        display: inline-block;
        font-size: 14px;

        & svg {
          margin-right: 12px;
        }
      }

      & .title {
        color: #292929;
        letter-spacing: 0.5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        margin: 0px 0px;
        font-family: var(--fontFamily1);
      }

      & .propertyName {
        color: #7b7b7b;
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        margin: 0px 0px;
      }
    }

    &>div {
      margin-bottom: 0px;
      padding: 0px 24px;

      @media (max-width: 767px) {
        padding: 0px 0px;
      }

      &:hover {
        background: #eef7ff;

        & .items {
          /* border-bottom: 1px solid #fff; */
        }
      }

      &:last-child {
        & .items {
          border: 0;
        }
      }
    }
  }

  &>h3 {
    flex-basis: 100%;
    text-align: left;
    text-transform: uppercase;
    color: #000;
    margin: 0;
    margin-left: 0px;
    margin-bottom: 20px;
  }
}

.noSuggestedItems {
  padding: 14px 20px !important;

  & h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: #292929;
    margin: 0;
  }

  & p {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: #292929;
    margin: 0;
    line-height: 23px;

    & span {
      line-height: 23px;
      display: inline-block;
    }
  }

  @media (max-width: 767px) {
    padding: 10px 0px !important;
  }
}

.location {
  cursor: pointer;

  @media (max-width: 767px) {
    border-bottom: 1px solid #d1d1d1;
  }
}

.locationBorder {
  cursor: pointer;
  background: #eef7ff;

  @media (max-width: 767px) {
    background: #eef7ff;
  }
}

.property {
  cursor: pointer;

  @media (max-width: 767px) {
    border-bottom: 1px solid #d1d1d1;
  }
}

.noProperty {
  & h5 {
    text-align: left;
    margin: 20px 0;
    letter-spacing: 0.5px;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;

    @media (max-width: 767px) {
      text-align: center;
    }

    & span {
      line-height: 26px;
      display: inline-block;
    }
  }

  &:hover {
    background: transparent !important;
  }
}

.location[disabled] {
  & span {
    & svg {
      & path {
        fill: #D1D1D1;
      }

      & g {
        & path {
          fill: #fff !important;
        }
      }
    }
  }

  & .suggestedWord {
    & .title {
      color: #B0ADAD;
    }

    & .propertyName {
      color: #D1D1D1;
    }
  }
}