.root {
  position: relative;
  & svg{
    position: absolute;
    top: 24px;
    right: 16px;
    @media (max-width: 767px) {
      width: 10.5px;
      height: 6px;
    }
  }
  & label {
    position: absolute;
    top: -10px;
    left: 20px;
    padding: 0 5px 0;
    background: #fbfbfb;
    z-index: 1;
  }
}

.select {
  border-radius: 6px;
  height: 56px;
  padding: 0 16px;
  border: 1px solid #d8dad9;
  background: #fff;
  color: var(--matterColorAnti);
  border-bottom-color: var(--attentionColor);
  padding-right: 20px;
 @media (max-width: 767px) {
  height: 52px;
 }
  & > option {
    color: var(--matterColor);
  }
  & > option:disabled {
    color: var(--matterColorAnti);
  }

  &:disabled {
    border-bottom-color: var(--matterColorNegative);
    color: black;
    cursor: default;
  }
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}
