@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  border: 1px solid #e5efff;
  border-radius: 6px;
  width: 100%;
  min-height: 154px;
  overflow: hidden;
  @media (max-width: 767px) {
    display: flex;
  }
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  height: 224px;
  position: relative;
  transition: var(--transitionStyleButton);

  @media (max-width: 1250px) {
    height: 100%;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative);

  @media (min-width: 16001px) {
    padding-bottom: unset;
  }
  @media (max-width: 768px) {
    border-radius: 6px;
  }
  @media (max-width: 390px) {
    padding-bottom: 60%;
  }

  /* Loading BG color */
  & img {
    border-radius: 0;

    @media (max-width: 768px) {
      border-radius: 6px 6px 0 0;
    }
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
  height: 100%;
}

.info {
  /* Layout */
  padding: 11px 16px 24px;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0px 8px 12px;
  }
 & .infoContent{
  @media (max-width: 767px) {
    display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 130px;
  }
  @media (max-width: 480px) {
  height: 110px;
  }
  @media (max-width: 450px) {
  height: 100px;
  }
  @media (max-width: 425px) {
  height: 95px;
  }
  @media (max-width: 385px) {
  height: 84px;
  }
 }
  & h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #1fd1bc;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1f403b;
white-space: pre;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }
  }

  & .locationContent {
    & p {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1f403b;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: left;
      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.03em;
        color: #000000;
      }
    }
  }

  & h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }
}

.ratingStar {
  display: flex;
  align-items: center;
  gap: 4px;
  @media (max-width: 767px) {
    gap: 2px;
  }
  & svg {
    @media (max-width: 767px) {
      height: 10px;
      width: 10px;
    }
  }
}
.locationContent {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 6px;
  margin: 8px 0 24px;
  @media (max-width: 767px) {
    gap: 3px;
    margin: 0px 0 12px;
    height: 14px;
  }
  & svg {
    fill: transparent;
    min-width: 14px;

    @media (max-width: 767px) {
      height: 14px;
      width: 10.18px;
    }
  }
}

.price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    height: 24px;
  }

  & svg {
    fill: #fff;

    @media (max-width: 767px) {
      width: 18.6px;
      height: 19px;
    }
  }
}
.priceContainer{
  display: flex;
  gap: 4px;
  align-items: center;
}
.textContainer{
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  align-items: baseline;
  @media (min-width: 768px) {
    align-items: flex-end;
  }
}

.priceHyphen{
  font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #2D2D2D;
@media (max-width: 767px) {
  margin: 0 2px 0 -3px;
}
}
.priceContent {
  display: flex;
  align-items: center;
  gap: 6px;
  @media (max-width: 767px) {
    gap: 3px;
   
  }

  & img {
    @media (max-width: 767px) {
      width: 11px;
      height: 12px;
    }
  }

  & p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #1f403b;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 11px;
      line-height: 11px;
      letter-spacing: 0.02em;
      color: #000000;
    }
  }
}

.priceValue {
  /* Font */
  composes: h2 from global;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;

  @media (max-width: 767px) {
    height: 32px;
    margin: 12px 0 6px;
  }
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.certificateInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}


@keyframes react-loading-skeleton {
  100% {
    transform: translateX(100%);
  }
}

.react-loading-skeleton {
  --base-color: #ebebeb;
  --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block; /* Enable animation */

  background-color: var(--base-color);

  width: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;

  position: relative;
  user-select: none;
  overflow: hidden;
  z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.react-loading-skeleton::after {
  content: ' ';
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  );
  transform: translateX(-100%);

  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion) {
  .react-loading-skeleton {
    --pseudo-element-display: none; /* Disable animation */
  }
}