@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  @media (max-width: 1023px) {
    /* margin-left: 20px; */
    margin-right: 0;
    grid-column-gap: 11px;
    grid-row-gap: 16px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 767px) {
    margin: 0px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 1280px) {
    /* margin-left: 40px; */
    margin-right: 0;
  }
}

.listingCollapse {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  @media (max-width: 767px) {
    grid-column-gap: 11px;
    grid-row-gap: 16px;
  }
}

.listingCollapse:not(.landinPageListing) {
  @media (min-width: 768px) {
    /* grid-template-columns: repeat(3, 1fr); */
    width: 47%;
  }
  @container (min-width: 768px) {
    width: 100% !important;
  }
}

.listingCard {
  flex-basis: 100%;

  @media (--viewportLarge) {
    /**
      * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1024px = 640px
      panelPaddings: - 2*36px = 72px

      columnCount: 2
      guttersBetweenColumns: 24px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 272px

    * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
    */
    flex-basis: calc(50% - 12px);
  }

  @media (--viewportXLarge) {
    /**
     * resultPanelWidthRatio = 0.625 aka 62.5%

      resultPanel: 62.5vw from 1920px = 1200px
      panelPaddings: - 2*36px = 72px

      columnCount: 3
      guttersBetweenColumns: 2*24px = 48px

      (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth
      ergo => listingCardWidth: 360px

     * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))
     */

    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

.listingCard:nth-of-type(2n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(3n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  padding: 24px 24px;
 @media (max-width: 767px) {
  margin-bottom: 48px;
  padding: 12px;
 }
}


.banner {
  display: flex;
  border: 1.5px solid rgb(129, 205, 188);
  border-radius: 10px;
  max-width: 500px;
  margin: 10px auto;
  width: 100%;
  background-color: rgba(129, 205, 188, 0.3);
  cursor: pointer;
  padding: 10px 20px;
}

.bannerImage {
  padding-right: 20px
}

.bannerImage svg {
  fill: none;
}

.bannerContent {
  flex: 5;
  align-self: center;
}

.bannerTitle {
  color: #060531;
  font-weight: normal;
  font-size: 14px;
  /* padding-right: 10px; */
}

.bannerDesc {
  color: #62677F;
}

.bannerWrapper {
  /* background-color: #f5f5f5; */
  width: 100%;
  /* padding: 0 10px; */
}

.underlineText {
  text-decoration: underline;
  text-decoration-style: dashed;
}

.modalContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.modal {
  background-color: white;
  border-radius: 20px;
  padding: 24px;
  width: 100%;
  max-width: 500px !important;
  /* margin: 0 16px; */
}

.modalAnimationIn {
  animation: modalAnimationIn 0.3s ease-in-out;
}

@keyframes modalAnimationIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.modalAnimationOut {
  animation: modalAnimationOut 0.3s ease-in-out;
}

@keyframes modalAnimationOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}