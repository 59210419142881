@import "../../styles/customMediaQueries.css";

/* Content is visible as modal layer */
.isOpen {
  width: 100%;
  z-index: 100;

  /* scrollLayer is the .root for the Modal */
  & .scrollLayer {
    flex-grow: 1;
    display: flex;

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--matterColorLight);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (--viewportMedium) {
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none;
    }
  }

  & .container {
    flex-grow: 1;

    /* Create context so the close button can be positioned within it */
    position: relative;

    /* Display and dimensions */
    display: flex;
    flex-direction: column;
    padding: var(--modalPadding);

    background-color: var(--matterColorLight);
    border-radius: var(--borderRadius);
    border-bottom: none;

    /* min-height: 100vh;
    height: 100%; */
    min-height: 50dvh;

    @media (--viewportMedium) {
      flex-basis: 576px;
      flex-grow: 0;
      min-height: auto;
      height: auto;

      padding: var(--modalPaddingMedium);
      margin-top: 12.5vh;
      margin-bottom: 12.5vh;
      border-bottom: 8px solid var(--marketplaceColor);
    }
  }
}

.isOpenInPlace {
  composes: isOpen;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.isOpenInPortal {
  composes: isOpen;
  display: block;
  height: calc(var(--vh, 1vh) * 100);
  position: absolute;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.content {
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */
/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */
/* where desktop layout should not get any styling from Modal component.              */
.isClosed {
  display: none;
}

.close {
  composes: marketplaceModalCloseStyles from global;
}

.closeText {
  composes: marketplaceModalCloseText from global;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;
  width: 14px;
  height: 14px;
}

.closeLight {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  &:enabled:hover,
  &:enabled:active {
    color: var(--matterColorLight);
  }
}
.addPhoto {
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 19px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 512px;
    padding: 51px 48px 48px;
    border: none;
    margin-top: 16.5vh;
    @media (max-width: 767px) {
      padding: 47px 24px 24px;
      min-height: 215px;
      height: 215px;
      max-width: 90%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
    width: 16px;
    height: 15.36px;
    margin: 0;
    @media (max-width: 767px) {
      width: 14px;
      height: 14px;
    }
  }
}
.listingEditAvailability {
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      display: none;
      top: 19vh;
      right: 25px;
    }
  }
  & .focusedDiv {
    padding: 14px 48px 48px;
    border: none;
    margin-top: 15.7vh;
    @media (max-width: 767px) {
      padding: 23px 24px 32px 24px;
      min-height: 427px;
      height: 427px;
      max-width: 90%;
      margin: 20vh auto 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
    width: 16px;
    height: 16px;
    margin: 0;
  }
}
.listingStep {
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 512px;
    padding: 51px 48px 48px;
    border: none;
    margin-top: 16.5vh;
    @media (max-width: 767px) {
      padding: 42px 24px 24px;
      min-height: 274px;
      height: 274px;
      margin-top: 0;
      max-width: 90%;
    }
  }
  & .closeIcon {
    color: #fd4e50;
    width: 16px;
    height: 18.52px;
    margin: 0;
    @media (max-width: 767px) {
      width: 14px;
      height: 14px;
    }
  }
}
.listingStep2 {
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 512px;
    padding: 51px 48px 48px;
    border: none;
    margin-top: 16.5vh;
    @media (max-width: 767px) {
      padding: 42px 24px 24px;
      min-height: auto;
      height: auto;
      max-width: 90%;
      margin-top: 0;
      align-items: start;
      justify-content: center;
    }
  }
  & .closeIcon {
    color: #fd4e50;
    width: 16px;
    height: 15.36px;
    margin: 0;
    @media (max-width: 767px) {
      width: 14px;
      height: 14px;
    }
  }
}
.focusedDiv {
  &:focus {
    outline: none;
  }
}
.mobileClose {
  top: 4px;
  left: 250px;
}
.mobileModal {
  height: auto;
  background: rgb(0 0 0 / 60%);
  z-index: 1;
  & .scrollLayer {
    max-width: 320px;
  }
  & .container {
    padding-top: 16px;
  }
}
.removeCard {
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 408px;
    padding: 24px 48px 48px 48px;
    border: none;
    margin-top: 16.5vh;
    @media (max-width: 767px) {
      padding: 43px 22px 32px 26px;
      min-height: 230px;
      height: 230px;
      max-width: 89%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px;
    & svg {
      height: 16px;
      width: 16px;
    }
  }
}
.paymentMethods {
  & .container {
    padding: 24px 48px 48px 48px;
    border-bottom: 0;
    flex-basis: 512px;
    @media (max-width: 767px) {
      padding: 19px 20px 32px;
    }
  }
  & .close {
    color: #fd4e50;
    padding: 31px 52px 0 0;
    & svg {
      height: 16px;
      width: 16px;
    }
  }
}

.paymentBox {
  flex-grow: 1;
  position: absolute !important;
  display: flex;
  flex-direction: column;
  padding: var(--modalPadding);
  background-color: var(--matterColorLight);
  border-radius: 20px 20px 0 0 !important;
  border-bottom: none;
  min-height: 80vh !important;
  height: 81% !important;
  margin-top: 30%;
  bottom: 0;
  overflow-y: auto;

  & > button {
    position: absolute;
    padding: 20px !important;
  }
}
.locationFilters {
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .focusedDiv {
    flex-basis: 408px;
    padding: 24px 48px 48px 48px;
    border: none;
    margin-top: 16.5vh;
    @media (max-width: 767px) {
      padding: 38px 21px 32px 19px;
      min-height: auto;
      height: 469px;
      max-width: 100%;
      margin-top: auto;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px 21px;
    background: transparent;
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
      fill: #fd4e50;
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
}
.searchFilters {
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .focusedDiv {
    flex-basis: 408px;
    padding: 24px 48px 48px 48px;
    border: none;
    margin-top: 16.5vh;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 32px 24px 32px;
      min-height: auto;
      height: auto;
      max-width: 89%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px;
    background: transparent;
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
      fill: #fd4e50;
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
}
.signUpBirth {
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 408px;
    padding: 24px 48px 48px 48px;
    border: none;
    margin-top: 16.5vh;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 32px 32px 32px 24px;
      min-height: auto;
      height: auto;
      max-width: 89%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px;
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
}
.checkoutPayment{
  & .paymentBox{
    min-height: unset !important;
    height: unset !important;
  }
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    & form{
      overflow-y: auto;
      max-height: calc(var(--vh) * 100 - 50px);
    }
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 512px;
    padding: 24px 48px 48px;
    border: none;
    margin-top: 16.5vh;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 20px 20px 0;
      min-height: auto;
      height: auto;
      max-width: 100%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px;
    top: 16px;
    right: 36px;
    @media (max-width: 767px) {
      top: 4px;
      right: 2px;
    }
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
}
.orderSuccess {
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 512px;
    padding: 48px;
    border: none;
    margin-top: 16.5vh;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 45px 24px 32px 24px;
      min-height: auto;
      height: auto;
      max-width: 89%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px;
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
}
.rentalAddress {
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 512px;
    padding: 48px;
    border: none;
    margin-top: 16.5vh;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 45px 39px 32px 24px;
      min-height: auto;
      height: auto;
      max-width: 89%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px;
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
}
.editListingPricingForm {
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .focusedDiv {
    flex-basis: 456px;
    padding: 48px;
    border: none;
    margin-top: 16.5vh;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 32px;
      min-height: auto;
      height: auto;
      max-width: 89%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px;
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
}
.mapMobile {
  /* margin-top: 68px; */
  & .close {
    display: none;
  }
}
:global(.mapboxgl-ctrl-top-left) {
  top: 80px !important;
}
.enquiryForm {
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 520px;
    padding: 48px;
    border: none;
    margin-top: 16.5vh;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 32px 24px 32px;
      min-height: auto;
      height: auto;
      max-width: 89%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px;
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
}
.transactionPanel {
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 408px;
    padding: 24px 48px 48px;
    border: none;
    margin-top: 16.5vh;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 42px 24px 34px;
      min-height: auto;
      height: auto;
      max-width: 89%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px;
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
}
.bookingRequest {
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 408px;
    padding: 24px 48px 48px;
    border: none;
    margin-top: 16.5vh;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 42px 24px 34px;
      min-height: auto;
      height: auto;
      max-width: 89%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px;
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
}
.reviewFeedback {
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 512px;
    padding: 63px 105px 48px;
    border: none;
    margin-top: 16.5vh;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 42px 50px 32px;
      min-height: auto;
      height: auto;
      max-width: 89%;
      margin-top: 0;
      border-radius: 20px;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px;
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
}
.reviewModal {
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 408px;
    padding: 24px 48px 48px;
    border: none;
    margin-top: 16.5vh;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 42px 24px 34px;
      min-height: auto;
      height: auto;
      max-width: 89%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
   display: none;
  }
}
.settingVacation {
  & .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  & .scrollLayer {
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 767px) {
      align-items: center;
      justify-content: center;
    }
  }
  & .close {
    padding: 23px 20px;
    @media (max-width: 767px) {
      padding: 16px;
      right: 0px;
      position: absolute;
      top: 0px;
    }
  }
  & .container {
    flex-basis: 408px;
    padding: 48px;
    border: none;
    margin-top: 16.5vh;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 42px 24px 34px;
      min-height: auto;
      height: auto;
      max-width: 89%;
      margin-top: 0;
    }
  }
  & .closeIcon {
    color: #fd4e50;
  }
  & .close {
    color: #fd4e50;
    padding: 16px;
    & svg {
      height: 16px;
      width: 16px;
      margin: 0;
      @media (max-width: 767px) {
        height: 14px;
        width: 14px;
      }
    }
  }
}
.bookingPanel {
  @media (max-width: 767px) {
    /* height: 100vh; */
    overflow: scroll;
    position: absolute;
    top: 74px;
    padding-bottom: 80px;
  }
  & .scrollLayer {
    @media (max-width: 767px) {
      padding: 0px 16px 0;
      min-height: auto;
      border-radius: 6px;
      overflow-x: hidden;
      flex-grow: unset;
      height: 100%;
      & > div {
        position: relative;
        width: 100%;
        height: auto;
      }
    }
  }
  & .close {
    display: none;
    position: absolute;
    top: -49px;
    right: -26px;
    color: #fd4e50;
    z-index: 100;
  }
}
.selectDays {
  z-index: 0;
  @media (max-width: 767px) {
    padding: 0px 0 120px;
    margin-bottom: 48px;
    overflow: scroll;
    position: absolute;
    top: 74px;
  }
  & .scrollLayer {
    & .container{
      height: 100%;
      border-bottom: none;
      @media (min-width: 768px) {
        padding: 24px;
      }
    }
    @media (max-width: 767px) {
      padding: 0px 16px 0;
      min-height: auto;
      border-radius: 6px;
      overflow-x: hidden;
      flex-grow: unset;
      min-height: 100dvh;
      & > div {
        position: relative;
        width: 100%;
        height: auto;
        padding: 0;
      }
    }
  }
  & .close {
    display: none;
    position: absolute;
    top: -49px;
    right: -26px;
    color: #fd4e50;
    z-index: 100;
  }
}
.themeModal {
  z-index: 999;
  &.listingStep2 {
    & .scrollLayer {
      background-color: rgba(0, 0, 0, 0.76);
    }
    & .close {
      padding: 20px 23px;
    }
    & .closeIcon {
      width: 19px;
      height: 19px;
    }
    & .container {
      color: #060531;
      border-radius: 8px;
      padding: 31px 37px 20px 13px;
      max-width: calc(100% - 44px);
    }
    & h2 {
      font-size: 18px;
      color: #060531;
      font-weight: 700;
      line-height: 22px;
      margin-bottom: 6px;
      font-family: var(--axiformasemibold);
    }
    & p {
      opacity: 60%;
      font-size: 14px;
      color: #060531;
      font-weight: 400px;
      font-family: var(--axiformaregular);
    }
  }
}