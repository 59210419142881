.root {
    position: relative;
    display: inline-block;
  }
  
  .label {
    composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
    letter-spacing: 0.2px;
  
    padding: var(--marketplaceButtonSmallDesktopPadding);
    width: auto;
    height: auto;
    min-height: 0;
    border-radius: 4px;
  
    &:focus {
      outline: none;
      background-color: var(--matterColorLight);
      border-color: transparent;
      text-decoration: none;
      box-shadow: var(--boxShadowFilterButton);
    }
  }
  
  .labelSelected {
    composes: button marketplaceSearchFilterLabelFontStyles buttonText buttonBorders buttonColors from global;
    letter-spacing: 0.2px;
    font-weight: var(--fontWeightSemiBold);
  
    padding: var(--marketplaceButtonSmallDesktopPadding);
    width: auto;
    height: auto;
    min-height: 0;
    border-radius: 4px;
    border: 1px solid var(--marketplaceColor);
  
    &:hover,
    &:focus {
      border: 1px solid var(--marketplaceColorDark);
    }
  }
  