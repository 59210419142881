@import '../../styles/customMediaQueries.css';

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.sectionContainer {
  background: #1fd1bc;
  padding: 96px 32px 183px 32px;
  margin-top: 120px;
  position: relative;

  @media (max-width: 1100px) {
    padding: 80px 32px 183px 32px;
  }
  @media (max-width: 767px) {
    padding: 32px 20px 210px 20px;
    margin: 70px -22px;
  }

  & .title {
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: #ffff;
    margin: 0 0 32px 0;

    @media (max-width: 1100px) {
      font-size: 34px;
      line-height: 46px;
    }

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 30px;
      margin: 0 0 10px 0;
    }
  }

  & .steps {
    display: flex;
    gap: 40px;
    max-width: 203px;
    margin: 0 auto 36px;
    justify-content: space-between;

    @media (max-width: 767px) {
      max-width: 339px;
      gap: 0px;
    }
    & .active {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
      @media (max-width: 767px) {
        border-bottom: 2px solid #fff;
      }
      & span {
        padding-bottom: 8px;
        border-bottom: 2px solid #fff;
        font-weight: 700;
        @media (max-width: 767px) {
          border-bottom: none;
        }
      }
    }
    & button {
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      height: 30px;
      margin: 0;
    text-transform: capitalize;
   width: 48%;
      &:hover {
        background: none;
        box-shadow: none;
      }
      @media (max-width: 767px) {
        height: 40px;
        font-size: 18px;
        line-height: 18px;
      }

      @media (max-width: 767px) {
        font-size: 18px;
        line-height: 18px;
      }
    }
  }

  & .cardContent {
    display: flex;
    align-items: center;
    background: #fff;
    text-align: center;
    gap: 32px;
    padding: 40px 32px 40px 32px;
    position: absolute;
    top: 250px;
    left: 32px;
    right: 32px;

    @media (max-width: 1023px) {
      left: 20px;
      right: 20px;
      gap: 28px;
      padding: 32px 20px 32px 20px;
    }

    @media (max-width: 767px) {
      gap: 26px;
      flex-direction: column;
      top: 135px;
    }

    & > div {
      width: 24%;

      @media (max-width: 767px) {
        display: flex;
        align-items: flex-start;
        text-align: left;
        gap: 16px;
        margin: 0 auto 0 0;
        width: 100%;
      }

      & svg {
        max-width: 64px;
        max-height: 64px;
        @media (max-width: 1023px) {
          height: 45px;
          width: 40px;
        }
      }

      & h2 {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        margin: 26px 0 8px;

        @media (max-width: 1023px) {
          font-size: 18px;
          line-height: 18px;
        }
        @media (max-width: 767px) {
          margin: 0px 0 8px;
        }
        @media (max-width: 365px) {
          font-size: 17px;
          line-height: 16px;
        }
      }

      & p {
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #1f403b;
        opacity: 0.8;
        margin: 0;
        height: 104px;

        @media (max-width: 1023px) {
          font-size: 14px;
          line-height: 20px;
          height: auto;
        }

        @media (max-width: 365px) {
          font-size: 13px;
          line-height: 18px;
        }
      }
    }
  }
}
