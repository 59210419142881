.root {
  & p {
    color: red;
    margin-top: 5px;
    margin-bottom: 35px;
    @media (max-width: 767px) {
      margin-bottom: 26px;
    }
  }
  & label {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
  }
}

.password {
  margin-bottom: 35px;
  @media (max-width: 767px) {
    margin-bottom: 26px;
  }
}
.passwordReset {
  & button{
    width: 152px;
  }
}
.passwordContainer {
  position: relative;
}
.passwordContainer svg {
  position: absolute;
  top: 17px;
  right: 18px;
  @media (max-width: 767px) {
    top: 13px;
  }
}
