@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  border: 1px solid #e5efff;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;
  @media (max-width: 767px) {
    flex-direction: row;
    border-radius: 10px;
    height: 126px;
  }
  @media (max-width: 450px) {
    height: 106px;
  }
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  height: 224px;
  position: relative;
  transition: var(--transitionStyleButton);

  @media (max-width: 1250px) {
    height: 100%;
  }
  @media (max-width: 767px) {
    width: 35.2%;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative);

  @media (max-width: 768px) {
    border-radius: 6px;
  }
  @media (max-width: 390px) {
    padding-bottom: 60%;
  }

  /* Loading BG color */
  & img {
    border-radius: 0;

    @media (max-width: 768px) {
      border-radius: 10px;
    }
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
  height: 100%;
  object-fit: cover;
}
.infoDesktop{
  @media (max-width: 767px) {
    display: none !important;
  }
}
.infoMobile{
  @media (min-width: 768px) {
    display: none !important;
  }
}
.titleContainer {
  display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.info {
  /* Layout */
  padding: 16px 16px 24px;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: row;
    padding: 13px 12px 14px 8px;
    height: 124px;
    width: 64.8%;
  }
  @media (max-width: 450px) {
    height: 104px;
  }

  & h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #1fd1bc;

    @media (max-width: 1200px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1f403b;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }
  }

  & .locationContent {
    & p {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1f403b;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.03em;
        color: #000000;
      }
    }
  }

  & h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 16px;
      color: #000000;
    }
  }
}

.ratingStar {
  display: flex;
  align-items: center;
  gap: 4px;

  & svg {
    @media (max-width: 767px) {
      height: 10px;
      width: 10px;
    }
  }
}

.locationContent {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 6px;
  margin: 8px 0 24px;
  @media (max-width: 767px) {
    gap: 3px;
  }
  & svg {
    fill: transparent;
    min-width: 14px;

    @media (max-width: 767px) {
      width: 10px;
      height: 14px;
    }
  }

  @media (max-width: 767px) {
    margin: 0px 0 12px;
  }
}

.price {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    fill: #fff;

    @media (max-width: 767px) {
      width: 16px;
      height: 16px;
    }
  }
}

.priceContent {
  display: flex;
  align-items: baseline;
  gap: 6px;

  @media (max-width: 767px) {
    gap: 3px;
  }

  & img {
    @media (max-width: 767px) {
      width: 11px;
      height: 12px;
    }
  }

  & p {
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    color: #1f403b;
    margin: 0;
    white-space: pre;

    @media (max-width: 1200px) {
      font-size: 11px;
      line-height: 11px;
      letter-spacing: 0.02em;
      color: #000000;
    }
  }
}

.priceValue {
  /* Font */
  composes: h2 from global;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.certificateInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.status {
  border-radius: 20px;
  width: 90px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
font-size: 13px;
line-height: 16px;
text-align: center;
text-transform: capitalize;
@media (max-width: 1200px) {
  width: 75px;
  height: 22px;
  font-size: 12px;
line-height: 15px;
}
}
.statusPending {
border: 1px solid rgb(44, 56, 116);
color: rgb(44, 56, 116);
}
.statusUpcoming {
  border: 1px solid #1FD1BC;
  color: #1FD1BC;
}
.statusInProgress {
  border: 1px solid skyblue;
color: skyblue;
}
.statusCompeleted{
  color: #259F46;
  border: 1px solid #259F46;
}
.statusReview {
  border: 1px solid blue;
  color: blue;
}
.statusDeclined {
  border: 1px solid #FD4E50;
  color: #FD4E50;
}
