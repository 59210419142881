@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;
  flex: 0;
  & input {
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.email {
  margin-top: 32px;

  @media (--viewportMedium) {
    margin-top: 35px;
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
  $ button{
    @media (max-width: 767px) {
      width: 152px;
    }
  }
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  margin-bottom: 32px;
  composes: marketplaceModalBottomWrapperText from global;
}

/* Helper links */
.modalHelperLink {
  composes: marketplaceModalHelperLink from global;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  composes: marketplaceModalHelperText from global;
}
