@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  composes: marketplaceSectionWrapper from global;
  background: #ffffff;
  padding: 0 24px 48px;
  margin-top: 48px;
  margin-bottom: 96px;
  @media (max-width: 767px) {
    margin-bottom: 0px;
    margin-top: 0px;
    padding: 16px 20px 0;
  }
}

.hidden {
  display: none;
}
.progress {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: 
    radial-gradient(closest-side, white 79%, transparent 80% 100%),
    conic-gradient(hotpink 75%, pink 0);    
}
.container {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-top: 16px;
  @media (max-width: 1023px) {
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 185px;
    gap: 0;
  }
}

.txInfo {
  @media (--viewportLarge) {
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    margin-bottom: 0;
  }
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}
.feedbackModal{
  text-align: center;
  & svg{
    margin: 0 auto;
    width: 262px;
    height: 200px;
    @media (max-width: 767px) {
      width: 158px;
      height: 120px;
    }
  }
  & h2{
    font-weight: 700;
font-size: 24px;
line-height: 32px;
text-align: center;
color: #060531;
margin: 48px 0;
 @media (max-width: 767px) {
  font-size: 22px;
  margin: 24px 0 32px;
 }
  }
  & .continueLink{
    background: #1FD1BC;
border-radius: 6px;
font-weight: 700;
font-size: 16px;
line-height: 20px;
text-align: center;
text-transform: uppercase;
color: #FFFFFF;
width: 186px;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
margin: 0 auto;
@media (max-width: 767px) {
  font-size: 14px;
line-height: 18px;
}
  }
}
/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  width: 100%;
  position: relative;
  @media (min-width: 768px) {
    display: block;
    overflow-y: hidden;
  }
}
.avatarContainer {
  width: 100%;
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 767px) {
    width: 35%;
    height: 136px;
  }
  @media (max-width: 450px) {
    height: 106px;
  }
}
.avatarContainerDesktop {
  width: 100%;
  @media (min-width: 1024px) {
    max-height: 278px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}
/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  padding: 8px;
  background: #fff;
  border-radius: 14px;
  @media (min-width: 1024px) {
    max-height: 278px;
  }
  @media (max-width: 767px) {
    padding: 0px;
    box-shadow: 0px 0px 6px 4px rgba(31, 209, 188, 0.04);
    border-radius: 9px;
    height: 136px;
    object-fit: cover;
  }
  @media (max-width: 450px) {
    height: 106px;
  }
}
.mobileDescriptionTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #060531;
  margin: 0;
  @media (min-width: 768px) {
    display: none;
  }
}
.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }
  @media (--viewportLarge) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;
  display: none;
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  display: none;
}
.avatarWrapperProviderDesktop {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  padding: 24px 0 0;
  @media (max-width: 767px) {
    display: none;
  }
}
.confirmationPending {
  grid-template-columns: repeat(1, 1fr);
}
.avatarWrapperProviderMobile {
  @media (min-width: 768px) {
    display: none;
  }
  & .providerDetails {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.listingRating {
  display: flex;
  align-items: center;
  gap: 4px;
  & h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #1f403b;
    @media (max-width: 767px) {
      line-height: 18px;
      color: #000000;
    }
  }
  & svg {
    @media (max-width: 767px) {
      height: 10px;
      width: 10px;
    }
  }
}
.addressContainer {
  display: flex;
  gap: 16px;
  @media (max-width: 767px) {
    gap: 8px;
  }
  & svg {
    @media (max-width: 767px) {
      width: 24px;
      height: 24px;
    }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: 0.02em;
    color: rgba(31, 64, 59, 0.8);
    @media (max-width: 767px) {
      letter-spacing: 0;
    }
  }
  & .extraText {
    font-weight: 700;
    margin: 24px 0 0;
    @media (max-width: 767px) {
      font-weight: 600;
      margin: 16px 0 0;
    }
  }
}
.avatarWrapperProvider {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  width: 100%;
  padding: 16px 19px 16px 16px;
  @media (max-width: 767px) {
    max-width: 100%;
    padding: 0;
    box-shadow: none;
  }
  & .providerSectionSecond {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      border-bottom: 1px solid #ececec;
      padding: 0 0 16px;
      margin: 0 0 16px;
    }
    & svg {
      width: 38px;
      height: 38px;
    }
    & p {
      font-weight: 500;
      font-size: 16px;
      line-height: 10px;
      color: #1f403b;
      @media (max-width: 1200px) {
        font-size: 14px;
        line-height: 18px;
      }
      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #1fd1bc;
      }
    }
    & h6 {
      margin: 0 0 6px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #1f403b;
      opacity: 0.6;
      @media (max-width: 767px) {
        gap: 4px;
        opacity: 0.8;
      }
    }
    & .phoneNumber {
      display: flex;
      align-items: center;
      gap: 7px;
      max-height: 24px;
      & svg {
        height: 18px;
        width: 18px;
      }
    }
  }
  & .providerSectionFirst {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0 0 60px;
    @media (max-width: 767px) {
      gap: 8px;
      justify-content: space-between;
      border-bottom: 1px solid #ececec;
      padding: 0 0 16px;
      margin: 0 0 16px;
    }
    & .avatarImage {
      & a {
        width: 80px;
        height: 80px;
      }
    }
    & .transactionMessage {
      & svg {
        width: 32px;
        height: 32px;
        margin: 24px 0 0;
      }
    }
    & .idVerified {
      display: flex;
      align-items: center;
      gap: 10px;
      @media (max-width: 767px) {
        gap: 4px;
      }
      & svg {
        width: 15px;
        height: 18px;
      }
    }
    & h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #259f46;
      @media (max-width: 1200px) {
        font-size: 14px;
        line-height: 18px;
      }
      @media (max-width: 767px) {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
      }
    }
    & h6 {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #060531;
      margin: 6px 0;
      @media (max-width: 767px) {
        margin: 0 0 4px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
        letter-spacing: -0.02em;
      }
    }
    & p {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1f403b;

      opacity: 0.6;
      @media (max-width: 767px) {
        letter-spacing: -0.02em;
        opacity: 0.8;
        margin: 0 0 6px;
      }
    }
  }
}
.anotherProvider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .providerSectionFirst {
    margin: 0;
  }
}
.avatarDesktop {
  @media (--viewportLarge) {
    display: flex;
  }
}

/* PanelHeadings subcomponent */
.headingOrder {
  margin: 0;
  @media (max-width: 767px) {
    margin: 16px 0 0;
  }
  @media (--viewportMedium) {
    max-width: 80%;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    padding: 0;
  }
}
.extraPadding {
  @media (min-width: 1023px) {
    padding: 66px 0 0;
  }
  @media (min-width: 1200px) {
    padding: 96px 0 0;
  }
}
.headingSale {
  margin: 18px 24px 0 24px;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 42px 0 0 0;
    padding: 0;
  }
}

.mainTitle {
  display: block;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #060531;
  @media (max-width: 767px) {
    margin: 16px 0 0;
    font-size: 18px;
    line-height: 22px;
  }
}

.transactionInfoMessage {
  margin: 18px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
}

/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  @media (--viewportMedium) {
    padding: 4px 0 0px 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  @media (max-width: 1023px) and (min-width: 767px) {
    margin-top: 32px;
  }
}

.detailCard {
  height: 100%;
  @media (--viewportLarge) {
    position: sticky;
    top: -200px; /* This is a hack to showcase how the component would look when the image isn't sticky */
    width: 480px;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 6px;
    z-index: 1;
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 48px 33px 48px;
  }
}

.detailCardTitle {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    margin-top: 14px;
    margin-bottom: 0;
  }
}

.detailCardSubtitle {
  composes: h5 from global;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

/* AddressLinkMaybe subcomponent */
.address {
  composes: h5 from global;
  color: var(--matterColorAnti);
  margin: 0;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  composes: h5 from global;
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
  /* default "root" class for breakdown container */
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
    margin: 32px 48px 24px 48px;
    padding: 4px 0 4px 0;
  }
}

.breakdown {
  padding: 24px 16px 0;
  border-top: 1px solid #d8dad9;
  @media (max-width: 767px) {
    padding: 0;
    border-top: none;
  }
}

.breakdownContainer {
  @media (--viewportLarge) {
    display: block;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 32px 0px;

  @media (--viewportMedium) {
    margin: 40px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 43px 0 0 0;
  }
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 32px 0px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
  & textarea {
    background: #fbfbfb;
    border: 1px solid #d8dad9;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    padding: 16px;
    height: 140px !important;
    box-sizing: border-box;
    @media (max-width: 767px) {
      height: 120px !important;
      font-size: 14px;
      line-height: 18px;
      padding: 12px;
      width: 100%;
    }
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  /* Position action button row above the footer */
  z-index: 9;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 24px 20px 32px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);
  background-color: white;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 0 16px;
    padding: 0;
  }
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 48px;
  @media (max-width: 767px) {
    margin-bottom: 0px;
  }
  & button {
    width: 100%;
    text-transform: capitalize;
    &:nth-child(2) {
      background: #fd4e50;
      border-radius: 6px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      @media (max-width: 767px) {
        background: #ff8585;
      }
    }
  }
}
.reviewButton {
  margin-bottom: 24px;
  margin-top: 24px;
  @media (max-width: 767px) {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
.actionError {
  composes: h5 from global;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;

  @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionButtons {
  /* margin-bottom: 48px; */
  @media (max-width: 767px) {
    margin: 0;
  }
}

/* BookingPanel subcompnent */
.bookingPanel {
  margin: 16px;
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}
.titleContents {
}
.dividerLine {
  height: 1px;
  width: 100%;
  border: none;
  background: #d8dad9;
  margin: 16px 0;
  @media (max-width: 767px) {
    display: none;
  }
}
.menubar {
  @media (max-width: 767px) {
    position: absolute;
    right: 18px;
    top: 11px;
  }
  & svg {
    @media (max-width: 767px) {
      height: 14.5px;
      width: 4.5px;
    }
    & path {
      fill: #060531;
    }
  }
}
.titleText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 0;
  @media (max-width: 767px) {
    padding: 0px 0 16px;
    justify-content: center;
  }
  & h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #060531;
    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 28px;
    }
  }
}
.productTitleDesktop {
  @media (max-width: 767px) {
    display: none !important;
  }
}
.productTitleMobile {
  @media (min-width: 768px) {
    display: none !important;
  }
}
.productTitleMain {
  display: flex;
  align-items: center;
  background: #1fd1bc;
  border-radius: 6px;
  padding: 16px 24px;
  justify-content: space-between;
  @media (max-width: 767px) {
    justify-content: unset;
    gap: 8px;
    background: #ffffff;
    padding: 0 0 16px;
    margin: 0 0 16px;
    border-bottom: 1px solid #ececec;
  }
  & p {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-transform: capitalize;
    @media (max-width: 767px) {
      margin: 4px 0 25px;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #1f403b;
    }
  }
  & h4 {
    font-weight: 700;
    font-size: 30px;
    line-height: 30px;
    color: #ffffff;
    display: inline-block;
    margin-right: 5px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      color: #1fd1bc;
    }
  }
  & .dayText {
    color: rgb(31 64 59 / 80%);
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
  }
  & h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #ffffff;
    margin: 0 0 8px;
    text-transform: capitalize;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
      color: #060531;
      margin: 0;
    }
  }
}
.descriptionText {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: #1f403b;
  margin: 24px 0 0;
  @media (max-width: 767px) {
    margin: 6px 0 16px;
    padding: 0 0 16px;
    font-size: 14px;
    line-height: 20px;
    border-bottom: 1px solid #ececec;
  }
}
.dateSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin: 24px 0 24px;
  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 0;
    margin: 0;
  }
  & .startTime {
    @media (max-width: 767px) {
      border-bottom: 1px solid #ececec;
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  }
  & .endDate {
    @media (max-width: 767px) {
      border-bottom: 1px solid #ececec;
      padding-bottom: 16px;
      margin-bottom: 16px;
    }
  }
  & p {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  & h6 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #1f403b;
    margin: 0 0 8px;
    @media (max-width: 767px) {
      margin: 0 0 6px;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
.providerName {
  margin: 22px 0 0px -22px;
  @media (max-width: 767px) {
    margin: 22px 0 0px -24px;
  }
}
.mainTitle {
  display: flex;
  justify-content: space-between;
  background: #1fd1bc;
  border-radius: 6px;
  width: 664px;
  height: 90px;
}
.productDetails h2 {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */
  color: #ffffff;
  padding: 13px 0px 3px 15px;
}
.productDetails p {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  padding-left: 15px;

  color: #ffffff;
}
.productPrice p {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  /* or 100% */
  display: flex;
  align-items: center;
  text-align: right;
  padding: 20px;
  color: #ffffff;
}

.DurationStart {
  display: flex;
  justify-content: space-between;
  padding-top: 26px;
  margin-bottom: 33px;
}

.bookingConfrim {
  display: flex;
  justify-content: space-between;
  width: 376px;
  height: 64px;
  left: 280px;
  background: rgba(254, 165, 21, 0.12);
  box-shadow: -6px 0px 0px #fea515;
  border-radius: 6px;
  padding: 12px;
  margin: 0px auto;
}

.bookingConfrim h3 {
  margin: 0px;
  padding: 0px;
}

.orderSuccess {
  background: unset;
  height: 78vh;
  margin-top: 96px;
}
.modalContainer {
  & .modalButtons {
    display: flex;
    align-items: center;
    gap: 24px;
    @media (max-width: 767px) {
      gap: 11px;
    }
    & button {
      width: 100%;
      height: 40px;
      border-radius: 6px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    & .desktopDecline {
      @media (max-width: 767px) {
        display: none;
      }
    }
    & .mobileDecline {
      @media (min-width: 768px) {
        display: none;
      }
    }
    & .noButton {
      background: #ffffff;
      color: #060531;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #1f403b;
    margin: 24px 0 48px;
    @media (max-width: 767px) {
      margin: 16px 0 32px;
      font-size: 16px;
      line-height: 22px;
      color: #060531;
    }
  }
  & h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #ff4242;
    text-align: center;
    margin: 0;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
.decline {
  margin: 0;
  width: 144px;
  height: 40px;
  background: #ff4242;
  border-radius: 6px;
  border: none;
  color: white;
}
.AuthorSectionMain {
  width: 1000%;
  display: flex;
}
.AuthorSection {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  margin-top: 43px;
  width: 133px;
}

.rightAddress {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  margin-top: 43px;
}
.menuContent {
  position: absolute;
  right: 0;
  z-index: var(--zIndexPopup);
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 175px !important;
  @media (max-width: 767px) {
    width: 150px !important;
    left: unset !important;
  }
  & ul {
    & li {
      padding: 0;
      line-height: 20px;
      &:first-child {
      }
      &:nth-child(2) {
        padding: 8px 0 0;
        border-top: 1px solid #ebebeb;
        margin: 8px 0 0;
      }
      &:nth-child(3) {
        padding: 8px 0 0;
        border-top: 1px solid #ebebeb;
        margin: 8px 0 0;
      }
      & button {
        display: inline;

        /* Borders */
        border: none;
        /* Text size should be inherited */
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #060531;
        white-space: nowrap;
        text-align: left;
        padding: 0;
        border-radius: 4px;

        /* Remove default margins from font */
        margin-top: 0;
        margin-bottom: 0;
        transition: var(--transitionStyleButton);
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
        }
        &:hover {
          text-decoration: none;
          /* background-color: var(--failColor); */
          cursor: pointer;
        }
      }
    }
  }
}

.deliveryInfoCard {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;

  @media (max-width: 767px) {
    border-bottom: 1px solid #ececec;
    padding: 0 0 16px;
    margin: 0 0 12px;
    max-width: 100%;
  }
}

.addressCardWrapper {
  background: #ffffff;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 10%);
  border-radius: 6px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 767px) {
    padding: 0 0 16px;
    margin: 0 0 12px;
    box-shadow: none;
    border-bottom: 1px solid #ececec;
    flex-direction: row;
    max-width: 100%;
  }
  & .directionContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    & svg {
      @media (max-width: 767px) {
        width: 24px;
        height: 24px;
      }
    }
  }
  & .addressDescription {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #1f403b;
    max-width: 256px;
  }
  & .addressTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    opacity: 0.8;
    margin: 0 0 12px;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
    }
    & svg {
      fill: transparent;
    }
  }
  & h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.02em;
      color: #060531;
    }
  }
}
.statusCardsDesktop {
  @media (max-width: 767px) {
    display: none !important;
  }
}
.statusCardsMobile {
  @media (min-width: 768px) {
    display: none !important;
  }
}
.statusDaysLeft {
  background: rgba(31, 209, 188, 0.12);
  box-shadow: -6px 0px 0px #1fd1bc;
  border-radius: 6px;
  padding: 12px 24px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  max-width: 376px;
  width: 100%;
  @media (max-width: 767px) {
    margin: 0px auto 32px;
  }
  & .daysLogo {
    position: relative;
    background: #ffffff;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    @media (max-width: 767px) {
      height: 32px;
      width: 32px;
    }
    &>div{
      width: 87px !important;
      position: absolute;
      height: 87px !important;
    }
    & span {
      font-weight: 700;
      font-size: 13px;
      line-height: 16px;
      color: #fc3207;
      position: absolute;
      z-index: 10;
      background: #fff;
      border-radius: 100px;
      height: 35px;
      width: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 16px;
      }
    }
    & svg {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  & .statusTitle {
    color: #fc3207;
    font-weight: 600;
  }
  & .statusLogoMobile {
    height: 40px;
    width: 40px;
    background: #fea515;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.statusPending {
  background: rgba(254, 165, 21, 0.12);
  box-shadow: -6px 0px 0px #fea515;
  border-radius: 6px;
  padding: 12px 24px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  max-width: 376px;
  width: 100%;
  @media (max-width: 767px) {
    margin: 0px auto 32px;
  }
  & .statusLogoMobile {
    height: 40px;
    width: 40px;
    background: #fea515;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.statusCancel {
  background: rgba(252, 50, 7, 0.12);
  box-shadow: -6px 0px 0px #fc3207;
  border-radius: 6px;
  padding: 12px 24px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  max-width: 376px;
  width: 100%;
  @media (max-width: 767px) {
    margin: 0px auto 32px;
  }
  & .statusLogoMobile {
    height: 40px;
    width: 40px;
    background: #fea515;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.statusCompleted {
  background: rgba(37, 159, 70, 0.12);
  box-shadow: -6px 0px 0px #259f46;
  border-radius: 6px;
  padding: 12px 24px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  max-width: 376px;
  width: 100%;
  @media (max-width: 767px) {
    margin: 0px auto 32px;
  }
  & .statusLogoMobile {
    height: 40px;
    width: 40px;
    background: #fea515;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.statusDescription {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #060531;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
}
.statusTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: rgb(31 64 59 / 80%);
  margin: 0 0 8px;
  text-transform: capitalize;
  @media (max-width: 767px) {
    margin: 0 0 2px;
    font-size: 14px;
    line-height: 16px;
  }
}
.buttonNo {
  width: 144px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
}

.buttonYesCancel {
  width: 144px;
  height: 40px;
  background: #ff4242;
  border-radius: 6px;
  cursor: pointer;
}
.buttonYes {
  width: 144px;
  height: 40px;
  background: #1fd1bc;
  border-radius: 6px;
  cursor: pointer;
}
.cancelButtons {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  margin: 48px 0 0;
  @media (max-width: 767px) {
    gap: 11px;
    margin: 32px 0 0;
  }
  & button {
    border: none;
  }
}
.cancelModalContainer {
  text-align: center;

  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #1f403b;
    & .learnText {
      color: #1fd1bc;
      font-weight: 700;
    }
  }
  & h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    margin: 24px 0 16px;
    @media (max-width: 767px) {
      margin: 16px 0 16px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  & h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #ff4242;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.contactSupport {
  text-align: center;
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: #1f403b;
  }
  & h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #060531;
    margin: 0 0 24px;
    @media (max-width: 767px) {
      margin: 0 0 16px;
      font-size: 18px;
      line-height: 22px;
    }
  }
}

.acceptBookingModal {
  text-align: center;
  & h6 {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    margin: 24px 0 16px;
    @media (max-width: 767px) {
      margin: 16px 0 16px;
      font-size: 16px;
      line-height: 20px;
    }
  }
  & .accptBooking {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #1fd1bc;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
.ratingText {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #1f403b;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 16px;
    color: #000000;
  }
}
