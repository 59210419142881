@import '../../styles/customMediaQueries.css';

.root {
  margin: 0 0 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 392px;
  overflow-y: auto;
}
.viewAll {
  @media (max-width: 767px) {
    flex-direction: row;
    overflow-x: auto;
    margin: 0;
  }
  & .review {
    width: 100%;
    @media (max-width: 767px) {
      width: 302px !important;
    }
  }
}
/* width */
.root::-webkit-scrollbar {
  width: 5px;
  @media (max-width: 767px) {
    display: none;
  }
}

/* Track */
.root::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
.root::-webkit-scrollbar-thumb {
  background: #d8dad9;
  border-radius: 5px;
}

/* Handle on hover */
.root::-webkit-scrollbar-thumb:hover {
  background: #d8dad9;
}

.reviewItem {
  width: 100%;
}
.reviewContainer {
  width: 100%;
}
.nameContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.review {
  display: flex;
  flex-direction: row;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
  background: #f5f5f5;
  border-radius: 6px;
  padding: 16px;
  @media (max-width: 767px) {
    padding: 12px 12px 24px;
    width: 100%;
  }
  & > div:first-child {
    height: 36px;
    width: 36px;
  }
}
.reviewTag {
  background-color: grey;
  color: #f5f5f5;
  border-radius: 5px;
  padding: 0px 6px 2px;
  margin-left: 12px;
  font-size: 13px;
}
.avatar {
  flex-shrink: 0;
  margin-right: 16px;
  @media (max-width: 767px) {
    margin-right: 8px;
  }
}

.reviewContent {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1f403b;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
  }
}

.reviewInfo {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgb(31 64 59 / 80%);
  margin: 4px 0 9px;
  @media (max-width: 767px) {
    margin: 2px 0 12px;
    font-size: 12px;
    line-height: 15px;
  }
}

.reviewInfoWithoutContent {
  margin-bottom: 0;
}

.separator {
  margin: 0 7px;
}

.desktopSeparator {
  margin: 0 7px;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}
.userName {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #060531;
  text-transform: capitalize;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
}
.mobileReviewRating {
  display: inherit;
}

.desktopReviewRatingWrapper {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}
.starReview {
  display: flex;
  gap: 4px;
  & svg {
    width: 16px;
    height: 16px;
    @media (max-width: 767px) {
      width: 12px;
      height: 12px;
    }
  }
  & h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 14px;
    }
  }
}

.desktopReviewRating {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  position: absolute;
  top: 4px;
  white-space: nowrap;
}

.reviewRatingStar {
  height: 12px;
  width: 12px;
  margin-right: 2px;
  fill: #ffce31;
  &:last-of-type {
    margin-right: 0;
  }
}
