@import '../../styles/customMediaQueries.css';

.coverEverything {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #ECECEC;
  border-radius: 6px;
  /* overflow: hidden; */
  @media (max-width: 767px) {
    flex-direction: row;
    cursor: pointer;
  }
  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}
.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 8px 16px 24px;
  height: 138px;
  @media (max-width: 767px) {
    padding: 13px 16px 12px 8px;
    width: 100%;
    height: 104px;
  }
}
.priceHyphen{
  font-weight: 700;
font-size: 24px;
line-height: 30px;
color: #1F403B;
margin: 0 2px 0 8px;
border-radius: 2px;
@media (max-width: 1360px) {
  margin: 0 2px 0 4px;
}
@media (max-width: 767px) {
  font-weight: 600;
font-size: 16px;
line-height: 20px;
color: #2D2D2D;
}
}
.mainInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  margin: 0 0 44px;
  @media (max-width: 767px) {
    margin: 0 0 33px;
  }
  & button{
    padding: 0;
    height: 20px;
  }
  & .iconWrapper {
    color: var(--matterColorLight);
    border-radius: 4px;
    transition: var(--transitionStyleButton);
  
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
    & svg{
      color: #060531;
    }
  }
}

.menuOverlayWrapper {
  /* Positioning */
  composes: coverEverything;
}

.menuOverlay {
  /* Positioning */
  composes: coverEverything;

  /* Overlay */
  background-color: var(--matterColorAnti);
  mix-blend-mode: multiply;
  opacity: 0;
  transition: var(--transitionStyleButton);
}

.menuOverlayContent {
  /* Positioning */
  composes: coverEverything;
  /* Fonts */
  composes: h4 from global;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorLight);

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);
  cursor: pointer;
  @media (max-width: 767px) {
    height: 104px;
    max-width: 117px;
    max-height: 104px;
    border-radius: 6px;
    overflow: hidden;
  }
  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 79%; /* 3:2 Aspect Ratio */
  background: #F5F5F5; /* Loading BG color */
  margin-bottom: 1px; /* Fix 1px bug */
  @media (max-width: 767px) {
    padding-bottom: 0;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  composes: coverEverything;
  width: 100%;
  border-radius: 6px 6px 0 0;
  height: 100%;
  object-fit: cover;
}

.menubarWrapper {
  /* Position */
  position: absolute;
  bottom: 110px;
  left: -7px;
  width: 100%;
  height: 22px;
  /* @media (max-width: 1100px) {
    bottom: 133px;
  }
  @media (max-width: 1023px) {
    bottom: 141px;
  } */
  @media (max-width: 767px) {
    top: 0;
    left: 0;
  }
}


.menubar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
@media (max-width: 767px) {
  position: absolute;
  top: 13px;
  right: 7px;
}
}
/* .listingMenuIsOpen {
  & .iconWrapper {
    background-color: rgba(0, 0, 0, 0.7);
    color: var(--matterColorLight);
  }
} */

.menuContent {
  position: absolute;
  right: 0;
  left: unset !important;
  z-index: var(--zIndexPopup);
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
  border-radius: 6px;
  max-width: 106px;
  @media (max-width: 767px) {
    padding: 12px;
  }
  & ul{
    & li{
      padding: 0;
      &:first-child{
        & button{
          padding: 0 0 8px;
          border-bottom: 1px solid #EBEBEB;
          margin: 0 0 8px;
          height: 30px;
        }
       
        /* & button{
          color: #FD4E50;
        } */
      }
      &:nth-child(3){
        padding: 8px 0 0;
        border-top: 1px solid #EBEBEB;
        margin: 8px 0 0;
      }
    }
  }
}

.cardRoot{
  position: relative;
}
.menuItem {
  display: inline;

  /* Borders */
  border: none;
 width: 74px;
  /* Text size should be inherited */
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #060531;
  white-space: nowrap;
  text-align: left;
  padding: 0;
  border-radius: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  transition: var(--transitionStyleButton);
@media (max-width: 767px) {
  font-size: 14px;
line-height: 18px;
}
  &:hover {
    text-decoration: none;
    /* background-color: var(--failColor); */
    cursor: pointer;
  }

}

.menuItemDisabled {
  color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorNegative);
  }
}



.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-shrink: 0;
  @media (max-width: 767px) {
    white-space: pre;
  }
}

.priceValue {
  color: var(--marketplaceColor);
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin: 0 2px 0 0;
  @media (max-width: 1350px) {
    font-size: 22px;
line-height: 26px;
  }
  @media (max-width: 1200px) {
    font-size: 20px;
line-height: 24px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
line-height: 20px;
  }
}

.perUnit {
  /* Font */
  font-weight: 600;
  font-size: 10px;
  line-height: 24px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 1150px) {
    font-size: 10px;
    line-height: 24px;
  }
}

@media (--viewportMedium) {
  .perUnit {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.noPrice {
  composes: perUnit;
  padding: 5px 0 3px 0;
}


.titleWrapper {
}

.title {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;
  /* Text size should be inherited */
  text-decoration: none;

  font-weight: 700;
font-size: 16px;
line-height: 20px;
color: #060531;
text-align: left;
padding-right: 24px !important;
word-break: break-word;
hyphens: auto;
/* overflow: hidden; */
text-overflow: ellipsis;
/* display: -webkit-box; */
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
@media (max-width: 767px) {
font-size: 16px;
line-height: 22px;
}
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.titlePending {
  color: var(--attentionColor);
}

.titleDraft {
  margin-right: 8px;
}

/* Solid gray background for draft listings without image */
.draftNoImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.edit {
  composes: button buttonFont buttonText buttonBordersSecondary buttonColorsSecondary from global;

  /* TODO: Odd font-size */
  font-size: 14px;

  /* Reset min-height from button styles */
  min-height: 0;

  /* Reserve space for button */
  flex-shrink: 0;
  width: auto;
  height: 41px;
  padding: 7px 14px 7px 35px;

  /* Add edit icon as a background image */
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px center;
}

.openListingButton,
.finishListingDraftLink {
  composes: button buttonText buttonBorders buttonColorsPrimary from global;

  font-family: 'poppins', Helvetica, Arial, sans-serif;
  font-weight: var(--fontWeightMedium);
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
  /* Reset min-height from button styles */
  width: 145px;
  padding: 4px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    margin: 5px 0 0;
    width: 92px;
    height: 38px;
  }
}

.menu {
  /* display: none; */
}

.cardIsOpen {
  display: block;
}

.manageLinks {
  composes: h5 from global;
  line-height: 18px;
  padding-top: 2px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    line-height: 24px;
    padding-top: 0;
    padding-bottom: 0;

    margin-top: 0;
    margin-bottom: 0;
  }
}

.manageLink {
  text-decoration: none;
  color: var(--matterColor);
  white-space: pre;
width: 100%;
  &:hover {
    text-decoration: underline;
    color: var(--matterColorDark);
  }
}

.manageLinksSeparator {
  margin: 0 3px 0 3px;
}
