@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  border: 1px solid #e5efff;
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.02);
  border-radius: 14px;
  width: 100%;
  min-height: 126px;
  max-height: 176px;
  overflow: hidden;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  max-width: 256px;
  height: 178px;
  position: relative;
  transition: var(--transitionStyleButton);

  @media (max-width: 1250px) {
    height: 165px;
  }
  @media (max-width: 767px) {
    width: 35.2%;
    max-height: 160px;
  }
  @media (max-width: 450px) {
    max-height: 126px;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%;
  /* 3:2 Aspect Ratio */

  @media (max-width: 767px) {
    border-radius: 6px;
    padding-bottom: 109.6667%;
  }

  /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 8px;
  border-radius: 14px;
  overflow: hidden;
  background: #fff;
  @media (max-width: 767px) {
    padding: 0;
    object-fit: cover;
  }
}

.info {
  /* Layout */
  padding: 0;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: flex-start;
  @media (max-width: 767px) {
    flex-direction: column;
  }

  & h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #1fd1bc;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1f403b;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }
  }

  & .locationContent {
    & p {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1f403b;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;

      @media (max-width: 767px) {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.03em;
        color: #000000;
      }
    }
  }

  & h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #060531;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.ratingStar {
  display: flex;
  align-items: center;
  gap: 4px;

  & svg {
    @media (max-width: 767px) {
      height: 10px;
      width: 10px;
    }
  }
}

.locationContent {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 6px;
  margin: 8px 0 24px;
  @media (max-width: 767px) {
    gap: 3px;
  }
  & svg {
    fill: transparent;
    min-width: 14px;

    @media (max-width: 767px) {
      width: 10px;
      height: 14px;
    }
  }

  @media (max-width: 767px) {
    margin: 0px 0 12px;
  }
}

.price {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & svg {
    fill: #fff;

    @media (max-width: 767px) {
      width: 16px;
      height: 16px;
    }
  }
}

.priceContent {
  display: flex;
  align-items: center;
  gap: 6px;

  @media (max-width: 767px) {
    gap: 3px;
  }
  & .totalEarning {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #060531;
    @media (min-width: 768px) {
      display: none;
    }
  }
  & img {
    @media (max-width: 767px) {
      width: 11px;
      height: 12px;
    }
  }

  & p {
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    color: #1f403b;
    margin: 0;

    @media (max-width: 767px) {
      font-size: 11px;
      line-height: 11px;
      letter-spacing: 0.02em;
      color: #000000;
    }
  }
}

.priceValue {
  /* Font */
  composes: h2 from global;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: left;

  @media (max-width: 767px) {
    height: 32px;
    gap: 2px;
  }
  & .dateText {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
  & p {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: rgb(31 64 59 / 60%);
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}

.title {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.certificateInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.status {
  border: 1px solid black;
  padding: -2px;
  border-radius: 38px;
}

.accept {
  width: 144px;
  height: 40px;
  background: #1fd1bc;
  border-radius: 6px;
  border: none;
  color: white;
  margin: 0;
}
.decline {
  margin: 0;
  width: 144px;
  height: 40px;
  background: #ff4242;
  border-radius: 6px;
  border: none;
  color: white;
}
.productDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 24px 24px;
  @media (max-width: 767px) {
    width: 64.9%;
    padding: 10px 12px 12px 8px;
  }
  & .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    @media (max-width: 767px) {
      display: none;
      width: 64.9%;
      padding: 10px 12px 12px 8px;
    }
  }
}
.modalContainer {
  & .modalButtons {
    display: flex;
    align-items: center;
    gap: 24px;
    @media (max-width: 767px) {
      gap: 11px;
    }
    & button {
      width: 100%;
      height: 40px;
      border-radius: 6px;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
    & .desktopDecline{
      @media (max-width: 767px) {
        display: none;
      }
    }
    & .mobileDecline{
      @media (min-width: 768px) {
        display: none;
      }
    }
    & .noButton {
      background: #ffffff;
      color: #060531;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    color: #1f403b;
    margin: 24px 0 48px;
    @media (max-width: 767px) {
      margin: 16px 0 32px;
      font-size: 16px;
      line-height: 22px;
      color: #060531;
    }
  }
  & h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: #ff4242;
    text-align: center;
    margin: 0;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
