@import "../../styles/customMediaQueries.css";
.root {
  composes: marketplaceModalFormRootStyles from global;
  justify-content: flex-start;
  @media (--viewportMedium) {
    justify-content: space-between;
  }
}
.name {
  display: flex;
  justify-content: space-between;
  margin: 0px 0 26px 0;
  @media (--viewportMedium) {
    margin: 0px 0 35px 0;
  }
}

.switchBusinessButtons{
  background-color: #EAEAEA;
  height: 50px;
  margin-bottom: 50px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
}
.switchButton{
  width: 100%;
  border-radius: 15px;
  border: none;
  outline: none;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #060531;
}
.swithButtonClicked{
  background-color: #1FD1BC;
  color: white;
}

.errorText {
  color: red;
}

.firstNameRoot {
  width: 100%;
  margin-top: 35px;

  @media (max-width: 767px) {
    margin-top: 26px;
  }
}
.phoneNumber{
  margin-top: 38px;
}
.NameRoot {
  width: 100%;
}

@@ -49,6 +52,7 @@

.lastNameRoot {
  width: calc(66% - 9px);
}
.password {
  composes: marketplaceModalPasswordMargins from global;
}
.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  margin: 18px 0 50px 0;

  @media (max-width: 767px) {
    margin: 16px 0 32px 0;
  }

  @@ -67,6 +71,7 @@;
}
.termsText {
  composes: marketplaceModalHelperText from global;
  margin-left: 8px;
}
.termsLink {
  composes: marketplaceModalHelperLink from global;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.bottomWrapper h6 {
  font-size: 18px;
  line-height: 22px;

  @@ -78,14 +83,17 @@

  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 24px 0;
  text-transform: none;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.inputError input {
  border-bottom-color: var(--failColor) !important;
}

.googleLogIn {
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);

  @@ -101,62 +109,87 @@

  border-radius: 6px;
  border: none;
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 20px;
  color: #060531;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.googleLogIn svg {
  margin-right: 8px;

  @media (max-width: 767px) {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
}

.signUpContent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}

.signUpContent button {
  border: none;
  color: var(--marketplaceColor);
}

.signUpContent p {
  margin: 0;
}

.passwordContainer {
  position: relative;
  & input {
    padding-right: 16px;
   
  
    @media (max-width: 767px) {
      padding-right: 12px;
    }
  
  }
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  & input::-webkit-date-and-time-value{
    text-align: left !important;
  }
  & .dateInput{
    @media (max-width:767px) {
      border: 1px solid var(--attentionColor); 
      border-radius: 5px;
    }

  }
  /* Firefox */
  & input[type="date"] {
  @media (max-width:767px) {
    min-width: 90%;
    max-width: calc(100vw - 220px) ;
    padding: 0px !important;
    -moz-appearance: textfield;
    display: flex !important;
    display: -webkit-flex;
    flex: 1 0 0;
    min-height: 44px;
    justify-content: flex-start;
    position: relative;
    left: 12px;
    border: 0px solid var(--attentionColor);
  }
  @media (max-width: 480px) {
    max-width: calc(100vw - 120px) ;
  }
  @media (max-width: 420px) {
    max-width: calc(100vw - 65px) ;
  }
    /* align-items: flex-start; */
  }
  & input[type="number"] {
    -moz-appearance: textfield;
  }
}

.locationAddress{
  flex-wrap: wrap;
  &>div{
    border-bottom: 0;
    & svg{
      display: none;
    }
  }
  & input{
    border: 1px solid var(--attentionColor);
    border-radius: 6px;
    background: #fbfbfb;
    height: 56px;
    padding: 0 0 0 16px;
  }
}
.addressBox{
  margin-top: 35px;
  position: relative;
  @media (max-width: 767px) {
    margin-top: 26px;
  }
  & label{
    position: absolute;
    top: -10px;
    left: 20px;
    padding: 0 5px 0;
    background: #fbfbfb;
    z-index: 1;
  }
}

.passwordContainer {
  & .passwordShowHide{
    position: absolute;
    top: 45px;
    right: 18px;
    cursor: pointer;
  
    @media (max-width: 767px) {
      top: 38px;
    }
  }
}


.field {
  position: relative;
  margin-top: 35px;
  @media (max-width: 767px) {
    margin-top: 26px;
  }
  & svg {
    @media (max-width: 767px) {
      top: 22px;
    }
  }
}

.field select {
  background: #fbfbfb;
  border: 1px solid #d8dad9;
  border-radius: 6px;
  height: 56px;
  padding: 0px 16px;

  @media (max-width: 767px) {
    height: 48px;
  }
}

.field label {
  position: absolute;
  top: -10px;

  left: 20px;
  padding: 0 5px 0;
  background: #fbfbfb;
  z-index: 1;
  @media (max-width: 767px) {
    top: -7px;
  }
}

.brandStoreIcon {
  position: absolute;
  top: 16px;
  left: 207px;
  z-index: 1;
  background: #fff;
  width: 23px;

  @media (max-width: 767px) {
    top: 12px;
    left: 186px;
    width: 17px;
  }

  & svg {
    fill: #ffffff00;
    background: #fff;
    width: 18px;
    height: 18px;
    @media (max-width: 767px) {
      width: 14px;
      height: 14px;
    }
  }
}
