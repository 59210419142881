@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.error {
  color: red;
  position: fixed;
  bottom: 71px;
  z-index: 999;
  text-align: center;
  margin-left: 6px;
  @media (max-width: 370px) {
    font-size: 13px;
  }
}

/* .dateInputTitle {
  @media (max-width: 767px) {
    display: none;
  }
} */

:global(.CalendarMonthGrid_month__hidden) {
  /* visibility: unset;
  height: 45vh; */
  &>div>div{
    padding-bottom: 0 !important;
  }
}
:global(.CalendarMonthGrid_month__hidden_2){
  visibility: inherit !important;
  height: 45vh;
}
.yes {
  border-width: 5px;
  border-color: black;
  background: #ffffff;
  border: 1px solid #d8dad9;
  border-radius: 6px;
  padding: 0 16px;
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    position: absolute;
    bottom: 12px;
    width: 24px;
    height: 24px;
    right: 16px;
    content: '';
    background-position: center;
    background-size: cover;
    z-index: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.3 1.8H13.5V0.9C13.5 0.661305 13.4052 0.432387 13.2364 0.263604C13.0676 0.0948211 12.8387 0 12.6 0C12.3613 0 12.1324 0.0948211 11.9636 0.263604C11.7948 0.432387 11.7 0.661305 11.7 0.9V1.8H6.3V0.9C6.3 0.661305 6.20518 0.432387 6.0364 0.263604C5.86761 0.0948211 5.63869 0 5.4 0C5.1613 0 4.93239 0.0948211 4.7636 0.263604C4.59482 0.432387 4.5 0.661305 4.5 0.9V1.8H2.7C1.98392 1.8 1.29716 2.08446 0.790812 2.59081C0.284464 3.09716 0 3.78392 0 4.5V15.3C0 16.0161 0.284464 16.7028 0.790812 17.2092C1.29716 17.7155 1.98392 18 2.7 18H15.3C16.0161 18 16.7028 17.7155 17.2092 17.2092C17.7155 16.7028 18 16.0161 18 15.3V4.5C18 3.78392 17.7155 3.09716 17.2092 2.59081C16.7028 2.08446 16.0161 1.8 15.3 1.8ZM16.2 15.3C16.2 15.5387 16.1052 15.7676 15.9364 15.9364C15.7676 16.1052 15.5387 16.2 15.3 16.2H2.7C2.46131 16.2 2.23239 16.1052 2.0636 15.9364C1.89482 15.7676 1.8 15.5387 1.8 15.3V9H16.2V15.3ZM16.2 7.2H1.8V4.5C1.8 4.2613 1.89482 4.03239 2.0636 3.8636C2.23239 3.69482 2.46131 3.6 2.7 3.6H4.5V4.5C4.5 4.73869 4.59482 4.96761 4.7636 5.1364C4.93239 5.30518 5.1613 5.4 5.4 5.4C5.63869 5.4 5.86761 5.30518 6.0364 5.1364C6.20518 4.96761 6.3 4.73869 6.3 4.5V3.6H11.7V4.5C11.7 4.73869 11.7948 4.96761 11.9636 5.1364C12.1324 5.30518 12.3613 5.4 12.6 5.4C12.8387 5.4 13.0676 5.30518 13.2364 5.1364C13.4052 4.96761 13.5 4.73869 13.5 4.5V3.6H15.3C15.5387 3.6 15.7676 3.69482 15.9364 3.8636C16.1052 4.03239 16.2 4.2613 16.2 4.5V7.2Z' fill='%231FD1BC'/%3E%3C/svg%3E%0A");

    @media (max-width: 767px) {
      width: 20px;
      height: 20px;
      bottom: 13px;
    }
  }

}

.selectDays {
  background: #eaeaea;
  border-radius: 10px;
  height: 40px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #060531;
  margin: 0 0 12px;

  @media (max-width: 767px) {
    /* width: calc(100vw - 36px); */
  }
  & span {

    &:hover,
    &:active {}
  }

  & .activeDate {
    background: #1fd1bc;
    border-radius: 10px;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #fff;
  }
}
.calendarWrapper{
@media (max-width: 767px) {
  width: calc(100vw - 36px);
}
}
.buttonApply {
  position: relative;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 30px 20px 16px;
  z-index: 1100;
  background: #fff;

  @media (max-width: 767px) {
    position: fixed;
  }

  & button {
    max-width: 144px;
    width: 100%;

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }
}

.selectDateTitle {
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #060531;
  margin: 12px 0 32px;
}

.formRow {
  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    padding: 0;
  }
}

.ownListing {
  border: none;
  padding: 0;
}

.startDateInput {
  margin: 0 0 16px;
}

.timeDuration {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin: 16px 0 0;

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.OwnTimeDuration {
  grid-template-columns: repeat(1, 1fr);
}
.ownListingInput{
  @media (max-width: 767px) {
    background: #ECECEC !important;
  }
}
.listingPageForm {
  & .OwnDateInputTitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    margin: 0 0 16px;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 8px;
    }
  }

  & .dateInputTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    margin: 0 0 8px;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.02em;
      margin: 0 0 8px;
    }
  }

  & .formRow {
    background: #fbfbfb;
    border: 1px solid #d8dad9;
    border-radius: 6px;
    height: 48px;
    align-items: center;
    justify-content: flex-start;
    padding: 0 48px;
    position: relative;
    text-transform: capitalize;
@media (max-width: 767px) {
  background: #ffffff;
}
    &:after {
      position: absolute;
      bottom: 12px;
      width: 24px;
      height: 24px;
      left: 16px;
      content: '';
      background-position: center;
      background-size: cover;
      z-index: 1;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.4 2.4H18V1.2C18 0.88174 17.8736 0.576515 17.6485 0.351472C17.4235 0.126428 17.1183 0 16.8 0C16.4817 0 16.1765 0.126428 15.9515 0.351472C15.7264 0.576515 15.6 0.88174 15.6 1.2V2.4H8.4V1.2C8.4 0.88174 8.27357 0.576515 8.04853 0.351472C7.82348 0.126428 7.51826 0 7.2 0C6.88174 0 6.57652 0.126428 6.35147 0.351472C6.12643 0.576515 6 0.88174 6 1.2V2.4H3.6C2.64522 2.4 1.72955 2.77928 1.05442 3.45442C0.379285 4.12955 0 5.04522 0 6V20.4C0 21.3548 0.379285 22.2705 1.05442 22.9456C1.72955 23.6207 2.64522 24 3.6 24H20.4C21.3548 24 22.2705 23.6207 22.9456 22.9456C23.6207 22.2705 24 21.3548 24 20.4V6C24 5.04522 23.6207 4.12955 22.9456 3.45442C22.2705 2.77928 21.3548 2.4 20.4 2.4ZM21.6 20.4C21.6 20.7183 21.4736 21.0235 21.2485 21.2485C21.0235 21.4736 20.7183 21.6 20.4 21.6H3.6C3.28174 21.6 2.97652 21.4736 2.75147 21.2485C2.52643 21.0235 2.4 20.7183 2.4 20.4V12H21.6V20.4ZM21.6 9.6H2.4V6C2.4 5.68174 2.52643 5.37652 2.75147 5.15147C2.97652 4.92643 3.28174 4.8 3.6 4.8H6V6C6 6.31826 6.12643 6.62348 6.35147 6.84853C6.57652 7.07357 6.88174 7.2 7.2 7.2C7.51826 7.2 7.82348 7.07357 8.04853 6.84853C8.27357 6.62348 8.4 6.31826 8.4 6V4.8H15.6V6C15.6 6.31826 15.7264 6.62348 15.9515 6.84853C16.1765 7.07357 16.4817 7.2 16.8 7.2C17.1183 7.2 17.4235 7.07357 17.6485 6.84853C17.8736 6.62348 18 6.31826 18 6V4.8H20.4C20.7183 4.8 21.0235 4.92643 21.2485 5.15147C21.4736 5.37652 21.6 5.68174 21.6 6V9.6Z' fill='%231FD1BC'/%3E%3C/svg%3E%0A");
    }

    &:first-child {
      @media (max-width: 767px) {
        padding: 0 48px;
      }

      &:after {
        position: absolute;
        bottom: 12px;
        width: 24px;
        height: 24px;
        left: 16px;
        content: '';
        background-position: center;
        background-size: cover;
        z-index: 1;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.4 2.4H18V1.2C18 0.88174 17.8736 0.576515 17.6485 0.351472C17.4235 0.126428 17.1183 0 16.8 0C16.4817 0 16.1765 0.126428 15.9515 0.351472C15.7264 0.576515 15.6 0.88174 15.6 1.2V2.4H8.4V1.2C8.4 0.88174 8.27357 0.576515 8.04853 0.351472C7.82348 0.126428 7.51826 0 7.2 0C6.88174 0 6.57652 0.126428 6.35147 0.351472C6.12643 0.576515 6 0.88174 6 1.2V2.4H3.6C2.64522 2.4 1.72955 2.77928 1.05442 3.45442C0.379285 4.12955 0 5.04522 0 6V20.4C0 21.3548 0.379285 22.2705 1.05442 22.9456C1.72955 23.6207 2.64522 24 3.6 24H20.4C21.3548 24 22.2705 23.6207 22.9456 22.9456C23.6207 22.2705 24 21.3548 24 20.4V6C24 5.04522 23.6207 4.12955 22.9456 3.45442C22.2705 2.77928 21.3548 2.4 20.4 2.4ZM21.6 20.4C21.6 20.7183 21.4736 21.0235 21.2485 21.2485C21.0235 21.4736 20.7183 21.6 20.4 21.6H3.6C3.28174 21.6 2.97652 21.4736 2.75147 21.2485C2.52643 21.0235 2.4 20.7183 2.4 20.4V12H21.6V20.4ZM21.6 9.6H2.4V6C2.4 5.68174 2.52643 5.37652 2.75147 5.15147C2.97652 4.92643 3.28174 4.8 3.6 4.8H6V6C6 6.31826 6.12643 6.62348 6.35147 6.84853C6.57652 7.07357 6.88174 7.2 7.2 7.2C7.51826 7.2 7.82348 7.07357 8.04853 6.84853C8.27357 6.62348 8.4 6.31826 8.4 6V4.8H15.6V6C15.6 6.31826 15.7264 6.62348 15.9515 6.84853C16.1765 7.07357 16.4817 7.2 16.8 7.2C17.1183 7.2 17.4235 7.07357 17.6485 6.84853C17.8736 6.62348 18 6.31826 18 6V4.8H20.4C20.7183 4.8 21.0235 4.92643 21.2485 5.15147C21.4736 5.37652 21.6 5.68174 21.6 6V9.6Z' fill='%231FD1BC'/%3E%3C/svg%3E%0A");

        @media (max-width: 767px) {
          width: 20px;
          height: 20px;
          bottom: 13px;
        }
      }
    }

    &:last-child {
      &:after {
        position: absolute;
        bottom: 11px;
        width: 24px;
        height: 24px;
        left: 16px;
        content: '';
        background-position: center;
        background-size: cover;
        z-index: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 0C5.3832 0 0 5.3832 0 12C0 18.6168 5.3832 24 12 24C18.6168 24 24 18.6168 24 12C24 5.3832 18.6168 0 12 0ZM12 21.6C6.7068 21.6 2.4 17.2932 2.4 12C2.4 6.7068 6.7068 2.4 12 2.4C17.2932 2.4 21.6 6.7068 21.6 12C21.6 17.2932 17.2932 21.6 12 21.6Z' fill='%231FD1BC'/%3E%3Cpath d='M13.1998 6H10.7998V13.2H17.9998V10.8H13.1998V6Z' fill='%231FD1BC'/%3E%3C/svg%3E%0A");

        @media (max-width: 767px) {
          width: 20px;
          height: 20px;
          bottom: 13px;
        }
      }

      /* &:before{
      position: absolute;
      bottom: 16px;
      width: 24px;
      height: 24px;
      color: #1F403B;
      opacity: .6;
      left: 124px;
      content: "-";
      z-index: 1;
    } */
    }

    & input {
      padding: 7px 0px;
      border: none;
      background: none;
      position: relative;
      z-index: 10;

      @media (max-width: 767px) {
        padding: 11px 0px;
        width: 75%;
      }
    }

    & .select {
      background: none;
      height: 48px;
      border: none;
      padding: 0;
      z-index: 10;
    }

    & .field {
      width: 69px;
      text-transform: capitalize;

      @media (max-width: 767px) {
        width: 61px;
      }

      &>div:after {
        display: none;
      }

      & svg {
        display: none;
      }

      & label {
        display: none;
      }
    }
  }

  & .otherFormRow {
    padding: 0 16px;

    &:after {
      left: unset !important;
      right: 16px;
    }

    &:first-child {
      @media (max-width: 767px) {
        padding: 0 24px;
      }

      &:after {
        left: unset;
        right: 16px;
      }
    }

    & .field {
      width: 100%;

      @media (max-width: 767px) {
        width: 100%;
      }
    }
  }
}

.generalDate {
  width: 100% !important;
}

.otherListing {
  & .field {
    width: 100%;

    @media (max-width: 767px) {
      width: 100%;
    }
  }
}

.field {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;

  @media (--viewportMedium) {
    width: calc(50% - 12px);

    &:nth-child(2n) {
      margin-top: unset;
    }
  }
}

.fieldDisabled {
  background: none;
  color: --matterColorNegative;
}

.fieldDateInput {
  flex-grow: 1;
}

.fieldSelect {
  flex-grow: 1;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 24px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%234A4A4A" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%234A4A4A"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }

  @media (--viewportMedium) {
    margin-left: 0;
    margin-right: 0;

    &::after {
      left: 0;
      bottom: 12px;
    }
  }
}

.fieldSelectDisabled {
  composes: fieldSelect;

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 13px;
    left: 24px;
    background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" xmlns="http://www.w3.org/2000/svg"><g transform="translate(1 -3)" fill="none" fill-rule="evenodd"><circle stroke="%23E6E6E6" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" cx="6" cy="10" r="6"/><g transform="translate(2 6)" fill="%23E6E6E6"><rect x="3.5" width="1" height="5" rx=".5"/><rect transform="rotate(90 2.5 4.5)" x="2" y="2.5" width="1" height="4" rx=".5"/></g></g></svg>');
    background-size: 12px 12px;
    width: 12px;
    height: 12px;
  }

  @media (--viewportMedium) {
    &::after {
      left: 0;
      bottom: 12px;
    }
  }
}

.select {
  position: relative;
  padding-left: 24px;
  padding-right: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 10px 6px;
  border-bottom-width: 2px;

  @media (--viewportMedium) {
    border-bottom-width: 3px;
  }
}

.selectDisabled {
  composes: select;
  background-image: url('data:image/svg+xml;utf8,<svg width="10" height="6" xmlns="http://www.w3.org/2000/svg"><path d="M4.686 5.315a.445.445 0 00.628 0L8.87 1.759a.444.444 0 10-.629-.628L5 4.37 1.759 1.13a.444.444 0 10-.628.628l3.555 3.556z" fill="%23E6E6E6" stroke="%23E6E6E6" fill-rule="evenodd"/></svg>');
}

/**
CSS for hiding the end date

If you want to show the field for the end date e.g. for enabling longer bookings,
you can remove the following code.
Remember to do required code changes also in FieldDateAndTimeInput.js!
**/

.field {
  width: calc(50% - 5px);

  @media (--viewportMedium) {
    width: calc(50% - 12px);
  }
}

.startDate {
  width: 100%;
}

.endDateHidden {
  display: none;
}

.lineBetween,
.lineBetweenDisabled {
  margin: 0 4px;
}

.lineBetweenDisabled {
  color: var(--matterColorNegative);
}

.generalInput{
  @media (max-width: 767px) {
    background: #ECECEC !important;
border: 1px solid #D8DAD9 !important;
border-radius: 6px !important;
  }
}
.timeDurationDay{
  display: flex;
  align-items: center;
  & p{
    white-space: pre;
  }
  & span{
    margin: 0 4px;
  }
}

.clearInput {
  color: #060531;
  position: fixed;
  bottom: 89px;
  z-index: 999;
  text-align: left;
  margin-left: 6px;
  text-decoration: underline;
  width: 100%;
  background: white;
  @media (max-width: 370px) {
    font-size: 13px;
  }
}
/**
CSS for hiding the end date above
**/