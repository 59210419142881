@import "../../styles/customMediaQueries.css";

.locationOption {
    margin-top: 24px;
}

.locationAddress {
    flex-wrap: wrap;

    &>div {
        border-bottom: 0;

        & svg {
            display: none;
        }
    }

    & input {
        border: 1px solid var(--attentionColor);
        border-radius: 6px;
        background: #fbfbfb;
        height: 56px;
        padding: 0 0 0 16px;
    }
}

.addressBox {
    margin-top: 35px;
    position: relative;

    @media (max-width: 767px) {
        margin-top: 26px;
    }

    & label {
        position: absolute;
        top: -10px;
        left: 20px;
        padding: 0 5px 0;
        background: #fbfbfb;
        z-index: 1;
    }
}

.firstNameRoot {
    width: 100%;
    margin-top: 35px;

    @media (max-width: 767px) {
        margin-top: 26px;
    }
}