@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.password {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;
}

/* Adjust the bottomWrapper's margin to match modals without the helper text */
.bottomWrapperText {
  composes: marketplaceModalBottomWrapperText from global;
  margin: 16px 0 48px 0;
  justify-content: flex-end;
  @media (max-width: 767px) {
    margin: 8px 0 32px 0;
  }
}

.recoveryLink {
  composes: marketplaceModalHelperLink from global;
}

.recoveryLinkInfo {
  composes: marketplaceModalHelperText from global;
}
.bottomWrapper h6 {
  font-size: 18px;
  line-height: 22px;
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 24px 0;
  text-transform: none;
  @media (max-width: 767px) {
    font-size: 14px;
line-height: 18px;
  }
}
.googleLogIn {
  background: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  border: none;
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 20px;
  color: #060531;
  width: 100%;
  height: 56px;
}
.googleLogIn svg {
  margin-right: 8px;
}
.signUpContent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
}
.signUpContent button {
  border: none;
  color: var(--marketplaceColor);
}
.signUpContent p {
  margin: 0;
}
.passwordContainer {
  position: relative;
}
.passwordContainer svg {
  position: absolute;
  top: 17px;
  right: 18px;
  @media (max-width: 767px) {
    top: 13px;
  }
}
