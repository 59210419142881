@import '../../styles/customMediaQueries.css';

.root {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }
}

.sectionContainer {
  padding: 0;
  text-align: center;

  @media (--viewportMedium) {
    padding: 0;
  }

  & textarea {
    background: #fbfbfb;
    border: 1px solid #d8dad9;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    height: 136px !important;
    box-sizing: border-box;
    padding: 19px 16px;
    &::placeholder {
      color: #1f403b;
      opacity: 0.6;
    }
    @media (max-width: 767px) {
      height: 160px !important;
    }
  }

  & h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #060531;

    @media (max-width: 767px) {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.03em;
    }
  }

  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    margin: 8px 0 0;

    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.02em;
      margin: 16px 0 0;
    }
  }
}

.avatarPlaceholderIcon svg {
  width: 144px;
  height: 144px;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 120px;
    height: 120px;
  }
}

/* .buttonContent {
  width: 100%;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    background: #fff;
  }
} */
.buttonContent button {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin: 0px auto 24px;

  /* width: 186px; */
  @media (max-width: 767px) {
    width: 152px;
  }
}

.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.lastSection {
  margin-bottom: 55px;

  @media (--viewportMedium) {
    margin-bottom: 76px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 32px;
  margin-bottom: 42px;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 35px;
  }
}

.label {
  width: 120px;
  margin: 0 auto;

  @media (--viewportMedium) {
    width: 144px;
  }
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: 120px;
  height: 120px;

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  cursor: pointer;

  @media (--viewportMedium) {
    width: 144px;
    height: 144px;
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  /* border-style: dashed; */
  border-color: var(--matterColorNegative);
  border-width: 2px;
  background: rgba(31, 64, 59, 0.05);
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}

.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  composes: h4 from global;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: -12px;
  right: 18px;
  width: 89px;
  height: 32px;
  padding: 5px 10px 7px 32px;

  /* Look and feel (buttonish) */
  background-color: var(--matterColorLight);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 11px 9px;
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Position: under */
    width: 103px;
    height: 37px;
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 5px 10px 7px 32px;
  }

  &:hover {
    border: solid 1px var(--matterColorAnti);
  }
}

.uploadingImage {
  /* Dimensions */
  height: 120px;
  width: 120px;

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative);
  /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    height: 144px;
    width: 144px;
    border-radius: 100px;
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  composes: marketplaceDefaultFontStyles from global;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.fileInfo {
  composes: h5 from global;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.firstName {
  width: calc(34% - 9px);
}

.lastName {
  width: calc(66% - 9px);
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  margin-top: 24px;
}