@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 0;
}

.sectionContainer {
  composes: marketplaceSectionWrapper from global;
  display: grid;
  align-items: center;
  gap: 80px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 1280px) {
    gap: 70px;
  }

  @media (max-width: 1100px) {
    gap: 50px;
  }

  @media (max-width: 1023px) {
    gap: 40px;
  }

  @media (max-width: 767px) {
    gap: 24px;
    grid-template-columns: repeat(1, 1fr);
  }

  & img {
    max-height: 454px;
    object-fit: cover;
    border-radius: 8px;
  }

  & h1 {
    font-size: 36px;
    line-height: 48px;
    color: #060531;
    margin: 0;
    @media (max-width: 1100px) {
      font-size: 34px;
      line-height: 46px;
    }

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  & .textColor {
    color: var(--marketplaceColor);
    margin-bottom: 24px;

    @media (max-width: 1023px) {
      margin-bottom: 16px;
    }
  }

  & .listText>div {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 19px;

    @media (max-width: 1100px) {
      margin-bottom: 12px;
    }

    @media (max-width: 767px) {
      align-items: flex-start;
    }

    & h5 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--matterColorAnti);
      opacity: 0.8;

      @media (max-width: 1023px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    & svg {
      min-width: 28px;

      @media (max-width: 1023px) {
        min-width: 20px;
        width: 20px;
        height: 22px;
      }
    }
  }

  & .listText>div:last-child {
    margin-bottom: 0;
  }
}