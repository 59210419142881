@import '../../styles/customMediaQueries.css';

.unsupportedCountryError {
  color: var(--failColor);
}

.root {
  /* Parent component should not shrink this field */
  flex-shrink: 0;

  /* This component uses flexbox layout too */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.input {
  position: relative;
  & input {
    background: #fbfbfb;
    border: 1px solid #d8dad9;
    border-radius: 6px;
    height: 56px;
    padding: 16px;
    margin: 20px 0 0;
  }
  & label {
    position: absolute;
    top: 10px;
    left: 20px;
    padding: 0 5px 0;
    background: #fbfbfb;
    z-index: 1;
  }
  /* Parent component should not shrink this field */
  flex-shrink: 0;
  border-bottom-color: var(--attentionColor);
  margin-bottom: 24px;
  width: 100%;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.longForm {
  width: 100%;
  margin-bottom: 24px;
}

@media (--viewportSmall) {
  .longForm {
    width: calc(50% - 9px);
  }
}

.inputSuccess {
  border-bottom-color: var(--successColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}
