@import '../../styles/customMediaQueries.css';
.sectionContainer {
  margin: 0 0px 96px;

  @media (max-width: 767px) {
    margin-left: -5.5px;
    margin-bottom: 32px;
  }

  & h1 {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #060531;
    margin-bottom: 24px;
    margin-left: 5px;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  & .imageContent {
    & .productContent {
      width: 100%;
      min-height: 154px;
      object-fit: cover;
    }

    margin: 0 -10px;
    & :global(.slick-track) {
      display: flex;
    }
    @media (max-width: 767px) {
      margin: 0;
    }

    & :global(.slick-prev:before) {
      content: unset;
    }

    & :global(.slick-next):before,
    & :global(.slick-prev):before {
      content: unset;
    }

    & :global(.slick-slide)>div>div {
      padding: 0 12px;

      @media (max-width: 767px) {
        padding: 0 5.5px;
      }
    }

    & :global(.slick-disabled) svg {
      border: 1px solid black;
      border-radius: 100px;
    }

    & :global(.slick-disabled) path {
      stroke: #000000;
    }

    & :global(.slick-disabled) rect {
      fill: #ffffff;
      stroke: #ffffff;
    }

    & :global(.slick-arrow) {
      right: 0 !important;
      top: unset;
      top: -41px;
    }

    & :global(.slick-next) {
      right: 2% !important;

      @media(max-width: 1320px) {
        right: 3% !important;
      }

      @media(max-width: 768px) {
        display: none !important;
      }

      & svg {
        width: 36px;
        height: 36px;

        &:hover {
          & path {
            stroke: #fff;
          }

          & rect {
            stroke: #1FD1BC;
            fill: #1FD1BC;
          }
        }
      }
    }

    & :global(.slick-prev) {
      left: 92%;
      /* bottom: -64px; */
      z-index: 32;

      @media(max-width: 1320px) {
        left: 88%;
      }

      @media(max-width: 768px) {
        display: none !important;
      }

      & svg {
        width: 36px;
        height: 36px;
        transform: rotate(180deg);

        &:hover {
          & path {
            stroke: #fff;
          }

          & rect {
            stroke: #1FD1BC;
            fill: #1FD1BC;
          }
        }
      }
    }
    text-align: center;

    & a:hover {
      text-decoration: none;
    }

    & .productContent {
      border: 1px solid #e5efff;
      border-radius: 6px;
      width: 100%;
      min-height: 154px;
      object-fit: cover;
      overflow: hidden;

      &:hover h3 {
        font-weight: 700;
        color: #1FD1BC;

        @media(max-width: 768px) {
          color: #060531;
        }
      }

      & img {
        width: 100%;
        min-height: 144px;
        object-fit: cover;
        @media (max-width: 400px) {
          max-height: 144px;
        }
      }

      & h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #060531;

        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
        }
      }

      & img {
        width: 100%;
        min-height: 144px;
        object-fit: cover;
        /* height: 286px;

        @media (max-width: 1300px) {
          height: 270px;
        }

        @media (max-width: 1200px) {
          height: 250px;
        }

        @media (max-width: 1100px) {
          height: 230px;
        }

        @media (max-width: 1023px) {
          height: 210px;
        }

        @media (max-width: 767px) {
          height: 144px;
        }
        @media (max-width: 460px) {
          height: 144px;
        } */
      }

      /* &:hover {
        & p {
          color: var(--marketPlaceColor);
          text-decoration: none;
        }
      } */
      & p {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: var(--matterColor);

        @media (max-width: 767px) {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
}

/* :global(.slick-track) div:last-child>div>div{
 padding-right: 0;
}
:global(.slick-track) div:first-child>div>div{
 padding-left: 0;
} */