@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;
}
.popupWrapper {
  display: flex;
}
.timeContainer {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.518);
  z-index: 110;
  @media (max-width: 767px) {
    align-items: flex-end;
  }
}
.timeContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-direction: column;
  gap: 16px;
  grid-column-gap: 24px;
  width: 416px;
  height: 528px;
  background: #ffffff;
  border-radius: 6px;
  padding: 48px;
  overflow-y: scroll;
  margin-top: 21vh;
  @media (max-width: 767px) {
    margin-top: 0;
    border-radius: 20px 20px 0px 0px;
    padding: 39px 32px 32px;
    width: 100%;
    height: 496px;
  }
  & > div {
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.allDaysContent {
  display: flex;
  flex-direction: column;
  display: grid;
  width: 416px;
  height: 518px;
  background: #ffffff;
  border-radius: 6px;
  padding: 48px;
  margin-top: 1vh;
  @media (max-width: 767px) {
    padding: 32px;
    gap: 12.5px;
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0px 0px;
  }
  & input[type='checkbox']:checked + label:before {
    content: url("data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.65684 0.115832C7.80686 1.13191 6.2553 2.71756 4.94243 4.38017C4.49483 4.94974 3.94286 5.59629 3.48039 6.28914C2.94331 5.47322 2.28684 4.7343 1.52603 4.10309C1.15305 3.79522 0.630832 3.73359 0.257857 4.10309C-0.0554959 4.42643 -0.115119 5.10369 0.257857 5.41169C1.22754 6.21224 2.00338 7.18203 2.57033 8.32129C2.58521 8.33665 2.58521 8.35211 2.6002 8.36747C2.6897 9.26036 4.0921 9.53744 4.36059 8.506C4.36059 8.47518 4.37547 8.45982 4.37547 8.44446V8.42911C4.42022 8.35211 4.45009 8.25976 4.49484 8.18277C4.62909 7.92107 4.77833 7.67474 4.94243 7.4284C5.36017 6.76648 5.82263 6.1198 6.31499 5.51943C7.50853 4.02614 8.88106 2.60983 10.5371 1.70154C11.5814 1.14722 10.6713 -0.438432 9.65687 0.115777L9.65684 0.115832Z' fill='%231FD1BC'/%3E%3C/svg%3E%0A");
    background: #ffffff !important;
    border-color: #1FD1BC !important;
    color: white;
    border: 1.33px solid #1FD1BC !important;
  }
  & label {
    display: inline-flex !important;
    align-items: center;
    height: 20px;
    margin: 0;
    &:before {
      width: 20px !important;
      height: 20px !important;
      padding: 0px 1px 0px 3px !important;
      border: 1.33px solid rgba(31, 64, 59, 0.6) !important;
      border-radius: 3px !important;
      @media (max-width: 767px) {
        padding: 3px 0px 0px 3px !important;
      }
    }
    & span {
      margin-left: 12px;
    }
  }
  & .allDaysButtons {
    display: flex;
    gap: 24px;
    margin: 48px 0 0;
    @media (max-width: 767px) {
      margin: 16px 0 0;
    }
    & button {
      width: 144px;
      height: 40px;
      font-size: 16px;
      line-height: 20px;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
         &:first-child{
      background: #FFFFFF;
box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
border-radius: 6px;
color: #060531;
    }
    }
  }
  & h6 {
    margin: 8px 0;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: rgba(31, 64, 59, 0.6);
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
.dateAndTimeContent {
  & p {
    margin: 0 0 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(31, 64, 59, 0.6);
  }
  & h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #060531;
    text-align: center;
    margin: 24px 0 48px;
    @media (max-width: 767px) {
      margin: 0px 0 16px;
      font-size: 18px;
      line-height: 24px;
    }
  }
  & .timeButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fbfbfb;
    border: 1px solid #d8dad9;
    border-radius: 6px;
    padding: 12px 16px;
    cursor: pointer;
    height: 48px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1f403b;
    margin: 0 0 16px;
  }
}
.title,
.heading,
.subheading,
.timezonePicker,
.weekDay,
.hasEntries {
  padding-left: 30px;
  padding-right: 30px;

  @media (--viewportSmall) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.subheading {
  margin: 0;
  padding-top: 19px;
  padding-bottom: 5px;
  color: var(--matterColorAnti);
}

.error {
  color: var(--failColor);
}

.timezonePicker {
  margin-bottom: 24px;
}

.week {
  display: flex;
  flex-direction: column;

  padding-top: 16px;
  margin-bottom: 23px;

  @media (--viewportLarge) {
    margin-bottom: 95px;
  }
}

.weekDay {
  composes: marketplaceTabNavHorizontalFontStyles from global;

  /* Layout */
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  padding-bottom: 19px;
  border-top: solid 1px var(--matterColorNegative);

  &:last-child {
    border-bottom: solid 1px var(--matterColorNegative);
  }
}

.hasEntries {
  background-color: var(--matterColorLight);
}

.dayOfWeek {
  width: 180px;
  padding-top: 8px;
  @media (--viewportSmall) {
    width: 200px;
  }
}

.timePicker {
  width: 100%;
}

.fieldWrapper {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  padding-bottom: 6px;

  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 41px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid var(--matterColorNegative);
    z-index: 0;
  }
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  /* parent aka fieldWrapper is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;

  z-index: 1;
}

.closeIcon {
  composes: marketplaceModalCloseIcon from global;

  stroke: none;
  fill: var(--matterColorAnti);

  &:hover {
    fill: var(--matterColor);
  }

  /* align icon with the select fields */
  padding: 2px 0 11px;
}

.dashBetweenTimes {
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 8px;
}

.buttonSetHours {
  padding-top: 8px;
}

.buttonAddNew {
  composes: h5 from global;
  color: var(--matterColor);
}
.submitButton {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  gap: 24px;
  @media (max-width: 767px) {
    margin-top: 16px;
    gap: 11px;
  }
  & button{
    height: 40px;
    max-width: 144px;
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
    &:first-child{
      font-weight: 700;
color: #060531;
      background: #FFFFFF;
box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
border-radius: 6px;
    }
  }
}

@media (--viewportMedium) {
  .root {
    padding-top: 2px;
    margin-top: -16px;
  }
  .title {
    margin-bottom: 32px;
  }

  .buttonAddNew {
    margin-bottom: 6px;
  }
}

@media (--viewportLarge) {
  .calendarWrapper {
    flex-grow: 0;
    margin-bottom: 24px;
  }
}

.fieldSelect {
  composes: marketplaceTabNavHorizontalFontStyles from global;

  background: none;
  padding-right: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 42px;

  &:select {
    margin-right: 0;
  }
}
