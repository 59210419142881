@import '../../styles/customMediaQueries.css';

.root {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  /* position: relative; */
}
.fixed {
  position: fixed;
  top: 0;
  z-index: 110;
  @media (min-width: 768px) {
    position: relative;
  }
}
.mapTopbar{
 /* @media (max-width: 767px) {
  display: none;
 } */
}
.MobileAccountModal {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  padding: 0 20px;
  overflow: scroll;
  @media (min-width: 768px) {
    display: none;
  }
  & h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #060531;
    margin: 16px 0 32px;
  }
  & nav {
    display: flex;
  }
}
.MobileDashboardModal {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  padding: 0 36px 0 34px;
  overflow: scroll;
  @media (min-width: 768px) {
    display: none;
  }
  & .navbarDashboard{
  margin-top: 44px;
  }
  & h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #060531;
    margin: 16px 0 32px;
  }
  & nav {
    display: flex;
  }
}

.container {
  /* Size */
  width: 100%;
  height: 68px;
  padding: 16px 20px;

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.06);
  & a svg {
    height: 36px;
    width: 148px;
  }
  @media (--viewportMedium) {
    display: none;
  }
}
.avatar {
  width: 36px;
  height: 36px;
}
.profileMenu {
  display: flex;
  align-items: center;
}
.logoutButton {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */
.searchMenu {
  /* Font */
  composes: h5 from global;
  color: var(--matterColor);

  /* Layout */
  display: flex;
  width: 66px;
  margin: 0;
  border-width: 0px;
  background-color: transparent;

  /* Hovers */
  &:enabled {
    cursor: pointer;
  }
  &:enabled:hover,
  &:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }
  &:disabled {
    background-color: transparent;
    cursor: auto;
  }
}

.menu {
  position: relative;
  border: none;
  padding: 0 10px;
}
.searchMenu {
  padding: 12px 24px;
}

.menuIcon,
.searchMenuIcon {
  margin: 9px 0;
}

.notificationDot {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  background-color: var(--failColor);
  border: 2px solid var(--matterColorLight);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.desktop {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* Icon component styles */
.rootMenuIcon {
  fill: var(--matterColor);
}
.rootSearchIcon {
  stroke: var(--matterColor);
}

.searchContainer {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.mobileHelp {
  color: var(--matterColorAnti);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;
  padding: 0;

  @media (--viewportMedium) {
    flex-basis: 576px;
  }
}

.missingInformationModal {
  composes: marketplaceModalBaseStyles from global;
  padding-top: 70px;
}

/* Title of the modal */
.modalTitle {
  composes: marketplaceModalTitleStyles from global;
}

/* Paragraph for the Modal */
.modalMessage {
  composes: marketplaceModalParagraphStyles from global;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  composes: marketplaceModalHelperLink from global;
}

.helperText {
  composes: marketplaceModalHelperText from global;
}

.error {
  composes: marketplaceModalPasswordMargins from global;
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

.reminderModalLinkButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;
}

.genericError {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;

  /* Place on top of everything */
  z-index: var(--zIndexGenericError);

  @media (--viewportMedium) {
    top: calc(var(--topbarHeightDesktop) + 15px);
  }
}

.genericErrorVisible {
  right: 0;
  opacity: 1;
}

.genericErrorContent {
  padding: 16px 22px;
  border-radius: 4px;
  background-color: var(--marketplaceColorLight);
  border-radius: 4px;
}

.genericErrorText {
  composes: h5 from global;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
  }
}
