@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
 align-items: center;
  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;
  padding: 0 12px;
  & textarea{
    padding: 12px;
    box-sizing: border-box;
    height: 80px !important;
    @media (max-width: 767px) {
      height: 72px !important;
     }
  }
  &>svg{
 @media (max-width: 767px) {
  width: 112px;
  height: 120px;
 }
  }
}

.reviewHere{
  font-weight: 600;
font-size: 16px;
line-height: 150%;
text-align: center;
color: #060531;
}
.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.reviewRating {
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.reviewContent {
  flex-shrink: 0;
  margin-top: 16px;
  margin-bottom: 16px;
width: 100%;
  @media (--viewportMedium) {
    margin-top: 16px;
  }
  @media (max-width: 767px) {
    margin-bottom: 32px;
  }
}

.submitButton {
  margin-top: auto;
  flex-shrink: 0;
 max-width: 224px;
 height: 40px;
 @media (max-width: 767px) {
  max-width: 138px;
 }
  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
  }
}
