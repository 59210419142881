@import '../../styles/customMediaQueries.css';

.root {
  composes: p from global;
  margin-top: 6px;
  margin-bottom: 12px;
  color: var(--failColor);
  overflow: hidden;
  text-align: left;

  @media (--viewportMedium) {
    margin-top: 6px;
    margin-bottom: 10px;
    text-align: left;
  }
}
