.root {
  composes: marketplaceModalFormRootStyles from global;
  justify-content: flex-start;
  padding: 20px;
  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.profileHeader {
  display: flex;
  gap: 20px;
}

.profilePic {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}

.profileTitle {
  padding: 0;
  margin: 0;
}

.ratingBox {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;

  & span {
    font-weight: bold;
  }
}

.verifiedBox {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.verifiedIcon {
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #cff3ef;
  padding: 3px 7px;
  font-size: 12px;
  border-radius: 3px;
}

.verifiedIcon > span {
  color: #1fd1bc;
  font-weight: 500;
}

.social_icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.filter_box {
  color: #1fd1bc;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  background-color: #eaeaea;
  max-width: fit-content;
  border-radius: 5px;
  font-weight: bold;
  border: none;
  outline: none;
  margin-top: 15px;
}

.products_box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px 0px;
}

.product {
  display: flex;
  box-shadow: 2px 2px 7px rgb(216, 216, 216);
  border-radius: 10px;
  overflow: hidden;
}

.product_img {
  min-width: 100px;
  min-height: 110%;
  display: flex;
}

.product_img > img {
  object-fit: cover;
  min-height: 100%;
  flex-grow: 1;
  border-radius: 10px;
}

.product_detail {
  padding: 10px 15px;
  width: 100%;
}

.product_title_box {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.product_title {
  font-size: 18px;
  font-weight: bold;
  color: #060531;
}

.product_menu_icon > span {
  background-color: #060531;
  min-width: 3px;
  min-height: 3px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.product_category_box > div {
  background-color: #1fd1bc;
  color: white;
  padding: 3px 10px;
  border-radius: 50px;
  max-width: fit-content;
}

.product_price_box {
  display: flex;
  align-items: center;
  gap: 5px;
}

.product_price {
  color: #1fd1bc;
  font-weight: bold;
  font-size: 20px;
}

.product_price_detail {
  color: #060531;
}

.plus{
  background-color: #1fd1bc;
  width: 50px;
  height: 50px;
  position: fixed;
  right: 15px;
  bottom: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

@media screen and (max-width: 768px) {
  .profilePic {
    width: 60px;
    height: 60px;
  }
}

.filter_dropdown {
  background: transparent !important;
  color: #1fd1bc !important;
  border: none !important;
}

.Dropdown_control {
  background: transparent !important;
  color: #1fd1bc !important;
  border: none !important;
  display: flex;
  padding: 8px 10px !important;
  gap: 10px;
}

.Dropdown_menu {
  background: #eaeaea !important;
  color: #1fd1bc !important;
  border: none !important;
}
