.root {
  height: 100%;
  width: 100%;
  margin: 0;
  & button,
  & a, & a>div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  & svg{
    fill: transparent;
    margin: 0 !important;
    width: 20px;
  }
}
