@import '../../styles/customMediaQueries.css';

:global(.image-gallery-content),
:global(.image-gallery-slide-wrapper),
:global(.image-gallery-swipe),
:global(.image-gallery-slides),
:global(.image-gallery-slide) {
  width: 100%;
  height: 100%;
}

.root {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: #fcfcfc;
}

:global(.image-gallery-fullscreen-button) {
  display: none;
}
:global(.image-gallery-play-button) {
  display: none;
}
:global(.image-gallery-left-nav) {
  left: -24px;
  top: 0;
  padding: 0;
  height: 20px;
  width: 20px;
  @media (max-width: 767px) {
    top: 20%;
  }
  @media (max-width: 650px) {
    top: 16%;
  }
  @media (max-width: 600px) {
    top: 12%;
  }
  @media (max-width: 550px) {
    top: 10%;
  }
  @media (max-width: 500px) {
    top: 6%;
  }
  @media (max-width: 450px) {
    top: 0;
  }
  &:after{
    position: absolute;
    top: 8px;
    width: 128px;
    height: 24px;
    right: 12px;
    /* background: black; */
    content: "";
    background-position: center;
    background-size: cover;
    z-index: 1;
    background-image: url("data:image/svg+xml,%3Csvg width='128' height='24' viewBox='0 0 128 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.05' d='M0 6C0 2.68629 2.68629 0 6 0H122C125.314 0 128 2.68629 128 6V24H0V6Z' fill='%231FD1BC'/%3E%3Cpath opacity='0.75' d='M64.7001 10.4039L70.2272 15.6806C70.3275 15.789 70.4525 15.8739 70.5926 15.9289C70.7326 15.9839 70.884 16.0075 71.0351 15.9979C71.1862 15.9883 71.3329 15.9457 71.4641 15.8735C71.5953 15.8012 71.7074 15.7012 71.7918 15.5811C71.9434 15.3812 72.0161 15.1364 71.997 14.8902C71.9779 14.644 71.8683 14.4122 71.6876 14.2357L65.4302 8.30679C65.3374 8.2101 65.2246 8.1329 65.0989 8.08009C64.9732 8.02727 64.8374 8 64.7001 8C64.5627 8 64.4269 8.02727 64.3012 8.08009C64.1755 8.1329 64.0627 8.2101 63.9699 8.30679L57.7126 14.2357C57.5318 14.4122 57.4222 14.644 57.4031 14.8902C57.384 15.1364 57.4567 15.3812 57.6084 15.5811C57.6927 15.7012 57.8048 15.8012 57.936 15.8735C58.0672 15.9457 58.2139 15.9883 58.365 15.9979C58.5161 16.0075 58.6675 15.9839 58.8075 15.9289C58.9476 15.8739 59.0726 15.789 59.1729 15.6806L64.7001 10.4039Z' fill='%23060531'/%3E%3C/svg%3E%0A");
    @media (max-width: 767px) {
      width: 26px;
      height: 26px;
      right: -40px;
      top: 83px;
      background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.4' filter='url(%23filter0_b_3276_10883)'%3E%3Ccircle cx='13' cy='13' r='13' fill='white'/%3E%3C/g%3E%3Cpath d='M15.1715 17.875C15.0328 17.8753 14.8957 17.8523 14.7703 17.8078C14.6449 17.7632 14.5345 17.6981 14.4471 17.6173L9.96128 13.4391C9.82468 13.3145 9.75 13.1582 9.75 12.9969C9.75 12.8356 9.82468 12.6793 9.96128 12.5547L14.605 8.37656C14.7626 8.23435 14.9891 8.14492 15.2347 8.12795C15.4803 8.11097 15.7248 8.16784 15.9145 8.28604C16.1042 8.40424 16.2234 8.57409 16.2461 8.75822C16.2687 8.94236 16.1929 9.1257 16.0352 9.26791L11.8838 13.0004L15.8959 16.7329C16.0095 16.8351 16.0816 16.9596 16.1038 17.0916C16.126 17.2236 16.0973 17.3576 16.0211 17.4777C15.9449 17.5979 15.8243 17.6991 15.6738 17.7695C15.5232 17.8399 15.3489 17.8765 15.1715 17.875Z' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_b_3276_10883' x='-7' y='-7' width='40' height='40' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeGaussianBlur in='BackgroundImageFix' stdDeviation='3.5'/%3E%3CfeComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_3276_10883'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_3276_10883' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
    }
    @media (max-width: 500px) {
      right: -40px;
      top: 119px;
    }
    @media (max-width: 400px) {
      right: -40px;
      top: 107px;
    }
    
  }
  &:hover{
@media (max-width: 767px) {
  &:after{
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_b_1452_23460)'%3E%3Ccircle cx='13' cy='13' r='13' fill='%231FD1BC'/%3E%3C/g%3E%3Cpath d='M15.1715 17.875C15.0328 17.8753 14.8957 17.8523 14.7703 17.8078C14.6449 17.7632 14.5345 17.6981 14.4471 17.6173L9.96128 13.4391C9.82468 13.3145 9.75 13.1582 9.75 12.9969C9.75 12.8356 9.82468 12.6793 9.96128 12.5547L14.605 8.37656C14.7626 8.23435 14.9891 8.14492 15.2347 8.12795C15.4803 8.11097 15.7248 8.16784 15.9145 8.28604C16.1042 8.40424 16.2234 8.57409 16.2461 8.75822C16.2687 8.94236 16.1929 9.1257 16.0352 9.26791L11.8838 13.0004L15.8959 16.7329C16.0095 16.8351 16.0816 16.9596 16.1038 17.0916C16.126 17.2236 16.0973 17.3576 16.0211 17.4777C15.9449 17.5979 15.8243 17.6991 15.6738 17.7695C15.5232 17.8399 15.3489 17.8765 15.1715 17.875Z' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_b_1452_23460' x='-7' y='-7' width='40' height='40' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeGaussianBlur in='BackgroundImageFix' stdDeviation='3.5'/%3E%3CfeComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1452_23460'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_1452_23460' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
  }
}
  }
  & svg{
    display: none;
  }
}
:global(.image-gallery-right-nav) {
  left: -24px;
  bottom: 0;
  padding: 0;
  height: 20px;
  width: 20px;
  @media (max-width: 767px) {
    left: unset;
    bottom: 0;
   right: 0;
 }
  &:after{
    position: absolute;
    bottom: -183px;
    width: 128px;
    height: 24px;
    right: 12px;
    /* background: black; */
    content: "";
    background-position: center;
    background-size: cover;
    z-index: 1;
    background-image: url("data:image/svg+xml,%3Csvg width='128' height='24' viewBox='0 0 128 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.05' d='M0 0H128V18C128 21.3137 125.314 24 122 24H6C2.68629 24 0 21.3137 0 18V0Z' fill='%231FD1BC'/%3E%3Cpath opacity='0.75' d='M64.2999 13.5961L58.7728 8.31937C58.6725 8.21102 58.5475 8.12608 58.4074 8.07108C58.2674 8.01608 58.116 7.99248 57.9649 8.00209C57.8138 8.01171 57.6671 8.05428 57.5359 8.12653C57.4047 8.19879 57.2926 8.29881 57.2082 8.41891C57.0566 8.61885 56.9839 8.86357 57.003 9.10979C57.0221 9.356 57.1317 9.58784 57.3124 9.76427L63.5698 15.6932C63.6626 15.7899 63.7754 15.8671 63.9011 15.9199C64.0268 15.9727 64.1626 16 64.2999 16C64.4373 16 64.5731 15.9727 64.6988 15.9199C64.8245 15.8671 64.9373 15.7899 65.0301 15.6932L71.2874 9.76427C71.4682 9.58784 71.5778 9.356 71.5969 9.10979C71.616 8.86357 71.5433 8.61885 71.3916 8.41891C71.3073 8.29881 71.1952 8.19879 71.064 8.12653C70.9328 8.05428 70.7861 8.01171 70.635 8.00209C70.4839 7.99248 70.3325 8.01608 70.1925 8.07108C70.0524 8.12608 69.9274 8.21102 69.8271 8.31937L64.2999 13.5961Z' fill='%23060531'/%3E%3C/svg%3E%0A");
    @media (max-width: 767px) {
      width: 26px;
      height: 26px;
      background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.4' filter='url(%23filter0_b_1452_23463)'%3E%3Ccircle cx='13' cy='13' r='13' fill='white'/%3E%3C/g%3E%3Cpath d='M10.8285 8.12505C10.9672 8.12469 11.1043 8.14765 11.2297 8.19224C11.3551 8.23683 11.4655 8.30191 11.5529 8.3827L16.0387 12.5609C16.1753 12.6855 16.25 12.8418 16.25 13.0031C16.25 13.1644 16.1753 13.3207 16.0387 13.4453L11.395 17.6234C11.2374 17.7656 11.0109 17.8551 10.7653 17.8721C10.5197 17.889 10.2752 17.8322 10.0855 17.714C9.89584 17.5958 9.77657 17.4259 9.75393 17.2418C9.73129 17.0576 9.80713 16.8743 9.96477 16.7321L14.1162 12.9996L10.1041 9.26708C9.99051 9.16487 9.91837 9.0404 9.89619 8.9084C9.87402 8.77641 9.90273 8.64241 9.97894 8.52227C10.0551 8.40213 10.1757 8.30087 10.3262 8.23047C10.4768 8.16008 10.6511 8.12349 10.8285 8.12505Z' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_b_1452_23463' x='-7' y='-7' width='40' height='40' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeGaussianBlur in='BackgroundImageFix' stdDeviation='3.5'/%3E%3CfeComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1452_23463'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_1452_23463' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
       bottom: -2px;
      right: 10px;
    }
  }
  &:hover{
 @media (max-width: 767px) {
  &:after{
    background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_b_3276_10877)'%3E%3Ccircle cx='13' cy='13' r='13' fill='%231FD1BC'/%3E%3C/g%3E%3Cpath d='M10.8285 8.12505C10.9672 8.12469 11.1043 8.14765 11.2297 8.19224C11.3551 8.23683 11.4655 8.30191 11.5529 8.3827L16.0387 12.5609C16.1753 12.6855 16.25 12.8418 16.25 13.0031C16.25 13.1644 16.1753 13.3207 16.0387 13.4453L11.395 17.6234C11.2374 17.7656 11.0109 17.8551 10.7653 17.8721C10.5197 17.889 10.2752 17.8322 10.0855 17.714C9.89584 17.5958 9.77657 17.4259 9.75393 17.2418C9.73129 17.0576 9.80713 16.8743 9.96477 16.7321L14.1162 12.9996L10.1041 9.26708C9.99051 9.16487 9.91837 9.0404 9.89619 8.9084C9.87402 8.77641 9.90273 8.64241 9.97894 8.52227C10.0551 8.40213 10.1757 8.30087 10.3262 8.23047C10.4768 8.16008 10.6511 8.12349 10.8285 8.12505Z' fill='white'/%3E%3Cdefs%3E%3Cfilter id='filter0_b_3276_10877' x='-7' y='-7' width='40' height='40' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeGaussianBlur in='BackgroundImageFix' stdDeviation='3.5'/%3E%3CfeComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_3276_10877'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_backgroundBlur_3276_10877' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
  }
 }
  }
  & svg{
    display: none;
  }
}
:global(.image-gallery-content){
  display: flex;
  gap: 12px;
}
:global(.image-gallery-thumbnails-container){
  display: flex;
  flex-direction: column;
  gap: 8px;
}
:global(.image-gallery-content .image-gallery-slide .image-gallery-image) {
  max-height: 392px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 6px;
  @media (max-width: 767px) {
    max-height: 250px;
    border-radius: 6px;
  }
  @media (max-width: 450px) {
    max-height: 240px !important;
    border-radius: 6px;
  }
  @media (max-width: 400px) {
    max-height: 220px !important;
  }
}
:global(.image-gallery-thumbnail) {
  width: 120px;
  height: 103px;
  border: none;
  cursor: pointer;
}
:global(.image-gallery-thumbnail .image-gallery-thumbnail-image) {
  padding: 4px;
  border: 1px solid #d8dad98c;
  object-fit: contain;
  height: 96px;
  opacity: 0.6;
}
:global(.image-gallery-thumbnail:focus) {
  border: none !important;
  & :global(.image-gallery-thumbnail-image){
border: 1px solid transparent;
  }
}
:global(.image-gallery-thumbnail:hover) {
  border: none !important;
  & :global(.image-gallery-thumbnail-image){
border: 1px solid #1FD1BC;
  }
}
:global(.active) {
  border: none !important;
  & :global(.image-gallery-thumbnail-image){
border: 1px solid #1FD1BC;
opacity: 10;
  }
}

:global(.image-gallery-thumbnails-wrapper) {
  display: flex;
  align-items: center;
  height: 392px;
  width: 128px !important;
  min-width: 128px;
  margin: 0 !important;
  @media (max-width: 767px) {
    /* display: none !important; */
  }
}
:global(.image-gallery-thumbnails) {
  top: 30px !important;
  height: 328px !important;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
  &::-webkit-scrollbar {
    display: none;
  }
}
:global(.image-gallery-slide-wrapper) {
  width: 100% !important;
}
:global(.image-gallery-bullets) {
  bottom: -20px;
  @media (min-width: 768px) {
    display: none;
  }
  & button{
    background-color: rgba(31, 209, 188, 0.2) !important;
border-radius: 20px !important;
    border: none !important;
    width: 10px;
    height: 7px;
    box-shadow: none !important;
  }
  & :global(.active){
    background-color: #1FD1BC !important;
   }
}
:global(.image-gallery-slide-wrapper) {
  /* max-height: 392px;*/
  @media (max-width: 767px) {
   height: 250px;
  }
  @media (max-width: 450px) {
   height: 240px !important;
  }
  @media (max-width: 400px) {
   height: 220px !important;
  }
}


.noImage {
  border: 1px solid var(--matterColorNegative);
  border-radius: 4px;
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.item {
  max-width: 100%;
  max-height: 100%;
}

.arrowHead {
  fill: var(--matterColorLight);
  stroke: var(--matterColorLight);
}

.navLeft,
.navRight {
  position: absolute;
  width: 10vw;
  min-width: 52px;
  height: 100%;
  border: 0;
  padding: 0;
  z-index: 1;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
    & .arrowHead {
      fill: var(--matterColorAnti);
      stroke: var(--matterColorAnti);
    }
  }
}

.navLeft {
  left: 0;
  justify-content: flex-start;
  padding-left: 12px;

  @media (--viewportMedium) {
    padding-left: 3vw;
  }

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 0;
  justify-content: flex-end;
  padding-right: 12px;

  @media (--viewportMedium) {
    padding-right: 3vw;
  }

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: none;

  width: 40px;
  height: 40px;
  border-radius: 50%;

  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (--viewportMedium) {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.imageWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.imageIndex {
  /* Fonts */
  composes: h4 from global;
  font-weight: var(--fontWeightSemiBold);

  /* Position and dimensions */
  position: absolute;
  top: 21px;
  left: 20px;
  margin: 0;

  /* Colors */
  color: var(--matterColorLight);

  @media (--viewportMedium) {
    margin: 0;
    font-weight: var(--fontWeightSemiBold);
  }
}
