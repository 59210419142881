@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.card {
  background: #fbfbfb;
  /* border: 1px solid #060531; */
  border-radius: 6px;
  padding: 16px;
  height: 56px;
  border: 1px solid #d8dad9;
  @media (max-width: 767px) {
    padding: 10px;
  }
  & input {
    color: red;
  }
  &:hover {
    border: 1px solid #060531;
  }
  @media (max-width: 767px) {
    height: 48px;
  }
}
.modalTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #060531;
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 24px;
  margin: 0 0 24px;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 12px;
    margin: 0 0 12px;
  }
}
.modalSubTitle {
  font-weight: 700;
  color: #060531;
  margin: 0 0 35px;
  font-size: 18px;
  line-height: 22px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 25px;
  }
}
.cardNumberInput {
  position: relative;
  & label {
    position: absolute;
    top: -8px;
    left: 20px;
    padding: 0 5px 0;
    background: #fbfbfb;
    z-index: 1;
  }
}
.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  margin: 0 0 14px 0;
  color: var(--matterColorAnti);

  padding-top: 8px;
  padding-bottom: 0px;

  @media (--viewportMedium) {
    margin: 0 0 26px 0;
  }
}

.billingHeading {
  margin: 0 0 14px 0;
  color: var(--matterColorAnti);

  padding-top: 3px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 26px 0;
  }
}

.paymentLabel {
  margin: 0;

  @media (--viewportMedium) {
    margin-bottom: 2px;
  }
  @media (--viewportLarge) {
    margin-bottom: 4px;
  }
}

.messageHeading {
  color: var(--matterColorAnti);
  margin: 40px 0 14px 0;

  padding-top: 4px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    margin: 41px 0 26px 0;
  }
  @media (--viewportLarge) {
    margin: 40px 0 26px 0;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
}

.submitContainer {
  margin-top: auto;
  margin-top: 48px;
  @media (max-width: 767px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  & button {
    width: 100%;
  }
}

.infoText {
  composes: h5 from global;

  color: var(--matterColorAnti);
  padding: 0;
  margin: 14px 0 0 0;
}

.submitButton {
}

.missingStripeKey {
  color: var(--failColor);
}

.paymentAddressField {
  padding-top: 11px;
  @media (max-width: 767px) {
    padding-top: 0px;
  }
}

.formRow {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 24px;
}

.postalCode {
  margin-top: 24px;
  width: calc(40% - 9px);
}

.city {
  margin-top: 24px;
  width: calc(60% - 9px);
}

.field {
  margin-top: 24px;
}
