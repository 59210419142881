@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 0;
}

.textColor {
  color: #1fd1bc;
}
.sectionMain{
  composes: marketplaceSectionWrapper from global;
}
.sectionContainer {
  margin: 0 auto 0;
  padding: 0 0 32px;
  @media (max-width: 1280px) {
    max-width: 100%;
    margin: 0px 0px 0;
  }

  @media (max-width: 1023px) {
    padding: 0px 0px 32px;
  }

  & h1 {
    text-align: center;

    @media (max-width: 767px) {
      line-height: 38px;
      margin-bottom: 16px;
    }

    & .textColor {
      color: #1fd1bc;
    }
  }

  & .faqContent {
    display: flex;
    gap: 32px;

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 0px;
    }

    & .faqText {
      position: relative;

      & svg {
        position: absolute;
        top: 23px;
        right: 21px;

        @media (max-width: 767px) {
          top: 28px;
          right: 15px;
        }
      }
    }
  }
}

:global(.Collapsible__trigger) {
  /* composes: h1 from global; */
  display: block;
  background: #ffffff;
  border: 1px solid #e5efff;
  border-radius: 6px;
  margin-bottom: 16px;
  padding: 15px 64px 15px 18px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  font-weight: 600;

  @media (max-width: 1300px) {
    padding: 15px 48px 15px 18px;
  }

  @media (max-width: 767px) {
    margin-bottom: 12px;
    padding: 0px 44px 0px 12px;
    height: 64px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
}

:global(.Collapsible__contentInner) {
  padding: 0 15px;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
}