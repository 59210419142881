@import '../../styles/customMediaQueries.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;
  position: relative;
  z-index: 9;
  @media (--viewportMedium) {
    padding-top: 2px;
  }
}
.instantBookInput {
  margin-bottom: 35px;
  /* display: grid; */
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  align-items: end;
  position: relative;
  @media (max-width: 767px) {
    gap: 16px;
    margin-bottom: 27px;
    grid-template-columns: repeat(1, 1fr);
  }
  & svg {
    top: 50px;

    @media (max-width: 767px) {
      top: 45px;
    }
  }
  & label {
    position: unset;
    margin: 0 0 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    background: #ffffff;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
  & .brandStoreIcon {
    position: absolute;
    top: -8px;
    left: 289px;
    z-index: 1;
    background: #fff;
    width: 23px;

    @media (max-width: 767px) {
      top: -5px;
      left: 257px;
      width: 17px;
    }

    & svg {
      fill: transparent;

      @media (max-width: 767px) {
        width: 14px;
        height: 14px;
      }
    }
  }

  & .descriptionIcon {
    left: 104px;

    @media (max-width: 767px) {
      left: 95px;
    }
  }

  & select {
    background: #fbfbfb;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #1f403b;
    @media (max-width: 767px) {
      background: #F5F5F5;
    }
    &:hover {
      border: 1px solid var(--matterColor);
    }
  }
  & .instantBookText{
    display: flex;
    gap: 8px;
    margin-bottom: 2px;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 158%;
    letter-spacing: -0.02em;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  /* top: 0; */
  z-index: 10;
  padding: 24px 0;
  background: #ffffff;
  box-shadow: 0px 1px 24px rgb(31 209 188 / 10%);

  @media (max-width: 767px) {
    padding: 0;
    gap: 0;
  }

  & button:first-child {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    color: #1f403b;
    opacity: 0.6;
  }

  & button {
    margin: 0;

    @media (max-width: 767px) {
      height: 56px;
      width: 50%;
      border-radius: 0;
    }
  }
}

.fieldSelectCategory {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  /* margin: 24px 0 35px; */

  @media (max-width: 767px) {
    /* margin: 16px 0 27px; */
    gap: 16px;
    grid-template-columns: repeat(1, 1fr);
  }
  & label {
    position: unset;
    margin: 0 0 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #060531;
    background: #ffffff;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  & select {
    background: #fbfbfb;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    @media (max-width: 767px) {
      background: #F5F5F5;
    }
    &:hover {
      border: 1px solid var(--matterColor);
    }
  }
  & svg {
    top: 50px;

    @media (max-width: 767px) {
      top: 45px;
    }
  }
}

.brandStoreInput {
  position: relative;
  margin: 0 0 24px;

  & textarea {
    height: 160px;
    padding: 19px 16px;
    box-sizing: border-box;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #1f403bab;
      opacity: 1;
      /* Firefox */
    }

    @media (max-width: 767px) {
      height: 128px;
      padding: 15px 16px;
    }

    &:hover {
      border: 1px solid #060531 !important;
    }
  }

  & input {
    border-bottom-color: #d8dad9 !important;

    &:hover {
      border: 1px solid #060531 !important;
    }
  }

  & .brandStoreIcon {
    position: absolute;
    top: -17px;
    left: 289px;
    z-index: 1;
    background: #fff;
    width: 23px;

    @media (max-width: 767px) {
      top: -12px;
      left: 305px;
      width: 17px;
    }

    & svg {
      fill: transparent;

      @media (max-width: 767px) {
        width: 14px;
        height: 14px;
      }
    }
  }

  & .descriptionIcon {
    left: 115px;

    @media (max-width: 767px) {
      left: 104px;
    }
  }

  & label {
    left: 0;
  };
}

.error {
  color: var(--failColor);
  margin: 0 0 24px;
}

.formMargins {
  margin-bottom: 35px;

  @media (max-width: 767px) {
    margin-bottom: 27px;
  }
}

.title {
  composes: formMargins;
  margin: 24px 0;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.certificate {
  composes: formMargins;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (min-width: 768px) {
    display: inline-block;
    width: 186px;
    margin-top: 12px;
  }
}

.descriptionLocation {
  margin-bottom: 35px;
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
  & > div {
    position: relative;

    & label {
      position: absolute;
      top: -10px;
      left: 20px;
      padding: 0 5px 0;
      background: #fbfbfb;
      z-index: 1;
      @media (max-width: 767px) {
        top: -8px;
      }
    }
  }

  & .brandStoreIcon {
    position: absolute;
    top: -17px;
    left: 289px;
    z-index: 1;
    background: #fff;
    width: 23px;

    @media (max-width: 767px) {
      top: -13px;
      left: 257px;
      width: 17px;
    }

    & svg {
      fill: transparent;

      @media (max-width: 767px) {
        width: 14px;
        height: 14px;
      }
    }
  }

  & .descriptionIcon {
    left: 98px;

    @media (max-width: 767px) {
      left: 87px;
    }
  }
}

.locationAddress {
  border: 1px solid var(--attentionColor);
  border-radius: 6px;
  background: #fbfbfb;
  height: 56px;
  padding: 0;
  color: #1f403b;
  /* margin-bottom: 35px; */
@media (max-width: 767px) {
  height: 50px;
}
  &:hover {
    border: 1px solid #060531;
  }

  & > div {
    border: none;
    border-radius: 6px;

    & svg {
      display: none;
    }
  }

  & input {
    border-radius: 6px;
    border: #d8dad9 !important;
    padding: 0 16px;
    height: 56px;
    margin-left: -22px;
    @media (max-width: 767px) {
      height: 48px;
    }
    &:hover {
      border: none;
      padding: 0 16px;
      /* height: 56px; */
    }
  }
}
