@import '../../styles/customMediaQueries.css';

.sectionContainer {
  margin: 0 -6px;

  @media (max-width: 767px) {
    margin-left: -5.5px;
  }

  & h1 {
    text-align: center;

    @media (max-width: 767px) {
      margin-bottom: 24px;
    }

    & .colorText {
      color: #1fd1bc;
    }
  }

  & .imageContent {
    @media (max-width: 767px) {
      /* display: flex; */
      /* gap: 10px; */
      /* overflow-x: auto; */

      /* &::-webkit-scrollbar {
        display: none;
      } */

      /* & .brandCard {
        min-width: 45%;
      } */
    }



    & :global(.slick-prev:before) {
      content: unset;
    }

    & :global(.slick-next):before,
    & :global(.slick-prev):before {
      content: unset;
    }

    & :global(.slick-slide)>div>div {
      padding: 0 12px;

      @media (max-width: 767px) {
        padding: 0 5.5px;
        /* margin-left: -110px !important; */
      }

      /* @media (max-width: 767px) {
        margin-left: -90px !important;
      } */
    }

    & :global(.slick-disabled) svg {
      border: 1px solid black;
      border-radius: 100px;
    }

    & :global(.slick-disabled) path {
      stroke: #000000;
    }

    & :global(.slick-disabled) rect {
      fill: #ffffff;
      stroke: #ffffff;
    }

    & :global(.slick-arrow) {
      right: 40%;
      top: unset;
      bottom: -39px;

      & svg {
        height: 32px;
        width: 32px;
        @media (max-width: 767px) {
          height: 26px;
          width: 26px;
        }
      }
    }
    & :global(.slick-track) {
      display: flex;
    }
    & :global(.slick-next) {
      right: 46.1%;
      @media (max-width: 767px) {
        right: 41.8%;
      }
    }

    & :global(.slick-prev) {
      left: 45.2%;
      z-index: 32;
      @media (max-width: 767px) {
        left: 41.8%;
      }
    }

    text-align: center;

    & a:hover {
      text-decoration: none;
    }

    & .productContent {
      border: 1px solid #e5efff;
      border-radius: 6px;
      width: 100%;
      min-height: 154px;
      object-fit: cover;
      overflow: hidden;

      & img {
        width: 100%;
        min-height: 144px;
        object-fit: cover;
        @media (max-width: 400px) {
          max-height: 144px;
        }
      }

      &:hover {
        & p {
          color: var(--marketPlaceColor);
          text-decoration: none;
        }
      }

      & p {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: var(--matterColor);
        padding: 16px 0 16px;
        @media (max-width: 767px) {
          font-size: 16px;
          line-height: 16px;
          padding: 15px 0 16px;

          @media(max-width: 460px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.arrowButtons {
  display: none;

  @media(max-width: 767px) {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    & svg {
      margin: 0 10px;
    }
  }
}

/* :global(.slick-track) div:last-child>div>div{
 padding-right: 0;
}
:global(.slick-track) div:first-child>div>div{
 padding-left: 0;
} */