@import '../../styles/customMediaQueries.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;
  animation-delay: 3s;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  font-size: 56px;
  line-height: 76px;
  color: var(--matterColor);
  font-weight: var(--fontWeightBold);
  margin: 0;
  composes: animation;

  @media (max-width: 1300px) {
    font-size: 50px;
    line-height: 70px;
  }

  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 40px;
  }
}

.heroMainTitleFEDelay {
  animation-delay: 0s;
}

.heroSubTitleFEDelay {
  animation-delay: 0.15s;
}

.heroButtonFEDelay {
  animation-delay: 0.3s;
}

.textColor {
  color: #1fd1bc;
}

.sectionContainer {
  composes: marketplaceSectionWrapper from global;
  display: flex;
  align-items: center;
  gap: 90px;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  & .heroContentLeft {
    width: 53.5%;

    @media (max-width: 1023px) {
      width: 100%;
      text-align: center;
      max-width: 580px;
    }

    & .heroSubTitle {
      font-size: 18px;
      line-height: 30px;
      color: #1f403b;
      font-weight: 500;
      opacity: 0.8;
      margin: 24px 0 48px;

      @media (max-width: 767px) {
        margin: 16px 0 24px;
        font-size: 16px;
        line-height: 24px;
      }
    }

    & .searchInput {
      position: relative;
      max-width: 600px;

      @media (max-width: 1023px) {
        margin: 0 auto;
      }

      & .searchIcon {
        position: absolute;
        top: 16px;
        left: 28px;
        z-index: 1;

        @media (max-width: 1023px) {
          top: 15px;
          left: 16px;
        }

        & svg {
          @media (max-width: 1023px) {
            width: 18px;
            height: 18px;
          }
        }
      }

      & input {
        background: #ffffff;
        border: 1px solid #e5efff;
        box-shadow: 0px 36px 60px rgba(193, 193, 193, 0.12);
        border-radius: 100px;
        padding: 27px 0 27px 66px;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #1f403ba8;
        height: 64px;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #1f403b;
          opacity: 0.4;
        }

        @media (max-width: 1023px) {
          height: 56px;
          padding: 23px 0 23px 40px;
          font-size: 14px;
          line-height: 14px;
        }
      }

      & button {
        position: absolute;
        top: 8px;
        right: 8px;
        background: #fd4e50;
        border-radius: 100px;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        width: 148px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 18px;

        @media (max-width: 1023px) {
          width: 102px;
          height: 44px;
          font-size: 14px;
          line-height: 16px;
          top: 6px;
          right: 6px;
          gap: 6px;
        }

        & svg {
          width: 18px;
          height: 12px;
        }
      }
    }
  }

  & .heroContentRight {
    width: 46%;
    display: flex;
    justify-content: center;
    gap: 21px;
    position: relative;
    padding-left: 35px;

    @media (max-width: 1023px) {
      gap: 15px;
      width: 100%;
      justify-content: center;
      padding-left: 22px;
    }

    @media (max-width: 767px) {
      width: 100%;
      padding-left: 0px;
      padding-right: 15px;
    }

    & .heroBackground {
      position: absolute;
      top: 39%;
      left: 53%;
      transform: translate(-50%, -50%);
      z-index: -10;

      & svg {
        height: 672px;
      }

      @media (max-width: 1023px) {
        display: none;
      }
    }

    & .twoCard {
      display: flex;
      flex-direction: column;
      gap: 63px;

      @media (max-width: 450px) {
        gap: 60px;
      }
    }
  }
}

.firstCard {
  position: relative;
  max-width: 175px;
  & img {
    position: absolute;
    top: -18px;
    right: 0px;
    @media (max-width: 450px) {
      top: -30px;
      right: -4px;
      width: 162px;
      height: 128px;
    }
  }
  & .starReview {
    position: absolute;
    top: 29%;
    right: -87px;
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 7px;
    width: 100%;
    max-width: 123px;
    max-height: 52px;
    box-shadow: 1px 52px 112px -10px rgba(93, 126, 176, 1);
    -webkit-box-shadow: 1px 52px 112px -10px rgba(93, 126, 176, 1);
    -moz-box-shadow: 1px 52px 112px -10px rgba(93, 126, 176, 1);
    /* backdrop-filter: blur(7.5px); */
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.48) 0%,
      rgba(255, 255, 255, 0.72) 100%
    );
    border-radius: 12px;

    @media (max-width: 500px) {
      max-width: 108px;
      top: 28%;
      right: -112px;
    }

    & svg {
      background: #ff9f00;
      height: 35px;
      min-width: 35px;
      border-radius: 100px;
      padding: 9px;

      @media (max-width: 500px) {
        height: 25px;
        min-width: 25px;
        padding: 5px;
      }
    }

    & p {
      font-weight: 700;
      font-size: 12px;
      line-height: 12px;
      color: #000;

      @media (max-width: 500px) {
        font-size: 12px;
        line-height: 12px;
      }
    }
  }
}

.secondCard {
  max-width: 175px;
  & img {
    position: absolute;

    top: -56px;
    right: 22px;
    @media (max-width: 450px) {
      top: -43px;
      right: 18px;
      height: 150px;
      width: 116px;
    }
  }
}
.thirdCard {
  position: relative;
  margin-top: 153px;
  max-width: 175px;
  @media (max-width: 450px) {
    margin-top: 109px;
  }

  & img {
    position: absolute;
    top: -8px;
    right: -16px;

    @media (max-width: 450px) {
      top: -7px;
      right: -14px;
      height: 124px;
      width: 132px;
    }
  }

  & .identityVerified {
    position: absolute;
    bottom: -42%;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 8px 18px 8px 9px;
    gap: 6px;
    height: 100%;
    width: 100%;
    max-width: 118px;
    max-height: 60px;
    box-shadow: 1px 26px 112px 0px rgb(93 126 176 / 46%);
    -webkit-box-shadow: 1px 26px 112px 0px rgb(93 126 176 / 46%);
    -moz-box-shadow: 1px 26px 112px 0px rgb(93 126 176 / 46%);
    /* backdrop-filter: blur(7.5px); */
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 12px;

    @media (max-width: 500px) {
      max-height: 47px;
      max-width: 110px;
      bottom: -42%;
      right: 22px;
    }
    & div {
      background: #1fd1bc;
      height: 40px;
      min-width: 40px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 500px) {
        padding: 6px;
        height: 30px;
        min-width: 30px;
      }
    }
    & svg {
    }

    & p {
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      color: #000;

      @media (max-width: 500px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
