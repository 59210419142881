@import '../../styles/customMediaQueries.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    opacity: 0.8;
    margin: 8px 0 0px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
      margin: 24px 0 24px;
    }
  }
}
.informationContents{
  composes: marketplaceRightPadding from global;
  max-width: 808px;
  @media (min-width: 1500px) {
    max-width: 100%;
  }
}
.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  /* top: 0; */
  z-index: 10;
  padding: 24px 0;
  background: #ffffff;
  box-shadow: 0px 1px 24px rgb(31 209 188 / 10%);
  @media (max-width: 767px) {
    padding: 0;
    gap: 0;
  }
  & button:first-child {
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    color: #1f403b;
    opacity: 0.6;
  }
  & button {
    max-width: 186px;
    margin: 0;
    @media (max-width: 767px) {
      height: 56px;
      width: 50%;
      border-radius: 0;
      max-width: 100%;
    }
  }
}
.dividerLine {
  width: 104%;
  height: 1px;
  margin: 16px 0px 16px -16px;
  background: #d8dad9;
  @media (max-width: 767px) {
    display: none;
  }
}
.sectionHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 16px;
}

.title {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #060531;
  margin: 0;
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
  }
}

.sectionTitle {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  color: #1F403B;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
}
.editPlanButton {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #1fd1bc;
  margin: 0;
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 20px;
  }
  & svg {
    fill: transparent;
    margin-left: 12px;
    @media (max-width: 767px) {
      margin-left: 6px;
      width: 18px;
      height: 18px;
    }
  }
}

.editPlanIcon {
  margin: 0px 3px 4px 0;
}

.week {
  display: flex;
  flex-direction: column;
  border-top: solid 1px var(--matterColorNegative);
  border-right: solid 1px var(--matterColorNegative);
  border-left: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  margin-bottom: 23px;

  & > .weekDay {
    border-bottom: solid 1px var(--matterColorNegative);
    @media (max-width: 767px) {
      border: none;
      justify-content: space-between;
    }
  }
  @media (max-width: 767px) {
    border: none;
  }
}
.weekDay {
  display: flex;
  flex-direction: row;
  padding: 18px 16px;
  cursor: pointer;
  font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #060531;
height: 56px;
  &:hover {
    color: var(--matterColorDark);
  }
  @media (max-width: 767px) {
    padding: 15px 0px;
  }
  & .entries{
    color: #1F403B;
  }
}

.dayOfWeek {
  width: 100px;
}

.blockedWeekDay {
  & > .dayOfWeek {
    color: var(--matterColorAnti);
  }

  &:hover > .dayOfWeek {
    color: var(--matterColor);
  }
}

.entries {
}

.entry {
  display: block;
}

.exceptionsLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noExceptions {
  composes: h4 from global;
  display: flex;
  align-items: center;
  margin: 0 0 24px 0;
}

.exceptions {
  display: flex;
  flex-direction: column;
  border-top: solid 1px var(--matterColorNegative);
  border-right: solid 1px var(--matterColorNegative);
  border-left: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  margin-top: 0;
  margin-bottom: 11px;

  & > .exception {
    border-bottom: solid 1px var(--matterColorNegative);
  }
}

.exceptionHeader {
  display: flex;
  flex-direction: row;
}

.exceptionAvailability {
  padding-right: 12px;
  margin: 12px auto 0px 24px;
  display: flex;
  flex-direction: row;
  margin-right: auto;
}

.exceptionAvailabilityDot {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-top: 8px;
  margin-right: 12px;
  background-color: var(--failColor);
}

.isAvailable {
  background-color: var(--successColor);
}

.exceptionAvailabilityStatus {
  composes: h4 from global;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  padding: 0;
}

.timeRange {
  composes: h5 from global;
  margin: -2px 12px 13px 32px;
  color: var(--matterColorAnti);
}

.removeIcon {
  width: 10px;
  height: 10px;
  stroke: var(--matterColorAnti);
  fill: var(--matterColorAnti);
}

.removeExceptionButton {
  padding: 0 24px 0 24px;
  border: 0;
  cursor: pointer;

  &:hover .removeIcon {
    stroke: var(--matterColorDark);
    fill: var(--matterColorDark);
  }
}

.addExceptionButton {
  composes: h4 from global;
  padding: 1px 0 5px 0;
  margin: 0 0 24px 0;
}

.goToNextTabButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
}

.modalContainer {
  composes: marketplaceModalBaseStyles from global;
  min-height: 100vh;
  height: 100%;

  padding: 22px 48px 48px 48px;
  background-color: var(--matterColorBright);
  flex-basis: 512px;
  min-height: auto;
  height: auto;
  @media (max-width: 767px) {
    padding: 23px 24px 32px;
  }
  &>button{
    padding: 18px 20px;
    & svg{
      color: #FD4E50;
    }
  }
}

.error {
  color: var(--failColor);
}

@media (--viewportMedium) {
  .dayOfWeek {
    width: 200px;
  }

  .exceptions {
    margin-bottom: 8px;
  }

  .exceptionAvailability {
    margin: 8px auto 0px 24px;
  }

  .exceptionAvailabilityDot {
    margin-top: 12px;
  }

  .exceptionAvailabilityStatus {
    margin: 0px;
    padding: 5px 0 3px 0;
  }

  .timeRange {
    padding: 6px 0 2px 0;
    margin: -8px 44px 15px 44px;
  }

  .removeIcon {
    width: 12px;
    height: 12px;
  }

  .removeExceptionButton {
    padding: 6px 24px 0 24px;
  }

  .addExceptionButton {
    padding: 3px 0 5px 0;
    margin: 0 0 72px 0;
  }

}

@media (--viewportLarge) {
  .goToNextTabButton {
    display: inline-block;
    width: 241px;
    margin-top: 62px;
  }
}
