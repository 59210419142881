@import '../../styles/customMediaQueries.css';

.sectionContainer {
  margin: 0;
  & h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #1F403B;
    margin-bottom: 0px;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
      color: #060531;
    }
  }
  & .imageContent {
    /* & .productContent{
      width: 100%;
      min-height: 154px;
      object-fit: cover;
    } */
    margin: 0 -10px;
    @media (max-width: 767px) {
      margin: 0;
    }
    & :global(.slick-track){
      display: flex;
    }
    & > div {
      margin: 5px 0 0;
    }
    & :global(.slick-prev:before) {
      content: unset;
    }
    & :global(.slick-next):before,
    & :global(.slick-prev):before {
      content: unset;
    }
    & :global(.slick-slide) {
      @media (max-width: 767px) {
        /* box-shadow: 0px 0px 6px 3px rgba(215, 215, 215, 0.25); */
      }
    }
    & :global(.slick-slide) > div > div {
      padding: 0 12px;
      @media (max-width: 767px) {
        padding: 0 5.5px;
      }
    }
    & :global(.slick-disabled) svg {
      border-radius: 100px;
    }
    & :global(.slick-disabled) path {
      stroke: #000000;
    }
    & :global(.slick-disabled) rect {
      fill: #ffffff;
      stroke: #ffffff;
    }
    & :global(.slick-arrow) {
      right: unset;
      top: unset;
      bottom: 131px;
      @media (max-width: 767px) {
        bottom: 78px;
      }
     & svg{
      @media (max-width: 767px) {
        height: 26px;
        width: 26px;
      }
      &:hover{
        & circle{
          fill: #1FD1BC;
        }
        & path{
          fill: #fff;
        }
      }
     }
    }
    & :global(.slick-next) {
      right: 1% !important;
      width: 48px;
      height: 48px;
      @media (max-width: 767px) {
        right: 13px !important;
        width: 26px;
        height: 26px;
      }
      & svg {

        &:hover {
          & path {
            stroke: #fff;
          }

          & rect {
            stroke: #1FD1BC;
            fill: #1FD1BC;
          }
        }
      }
    }

    & :global(.slick-prev) {
      left: 1%;
      /* bottom: -64px; */
      z-index: 32;
      width: 48px;
      height: 48px;
      @media (max-width: 767px) {
        left: 14px;
        width: 26px;
        height: 26px;
      }
      & svg {
        transform: rotate(180deg);
        &:hover {
          & path {
            stroke: #fff;
          }

          & rect {
            stroke: #1FD1BC;
            fill: #1FD1BC;
          }
        }
      }
    }
    /* display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: space-between;
    gap: 24px; */
    text-align: center;
    @media (max-width: 1100px) {
      gap: 18px;
    }
    @media (max-width: 1023px) {
      gap: 14px;
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 767px) {
      gap: 11px;
      grid-template-columns: repeat(2, 1fr);
    }
    & a:hover {
      text-decoration: none;
    }
    /* & .productContent {
      border: 1px solid #e5efff;
      border-radius: 6px;
      width: 100%;
      min-height: 154px;
      object-fit: cover;
      overflow: hidden;
      & h3{
        font-weight: 600;
font-size: 24px;
line-height: 24px;
color: #060531;

      }
      & img {
        width: 100%;
        min-height: 154px;
        object-fit: cover;
      }
      &:hover {
        & p {
          color: var(--marketPlaceColor);
          text-decoration: none;
        }
      }
      & p {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: var(--matterColor);
        @media (max-width: 767px) {
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
        }
      }
    } */
  }
}
.titleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  margin: 16px 0 0;
  border-top: 1px solid #D8DAD9;
  @media (max-width: 767px) {
    margin: 18px 0 0;
    padding: 16px 0 12px;
  }
}
.titleWrapper a {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #1fd1bc;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
}
.imageContent {
  /* display: grid;
grid-template-columns: repeat(4, 1fr);
align-items: center;
justify-content: space-between;
gap: 24px;
text-align: center;
@media (max-width: 1100px) {
gap: 18px
}
@media (max-width: 1023px) {
gap: 14px;
grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 767px) {
gap: 11px;
grid-template-columns: repeat(2, 1fr);
} */

  /* & .productContent{
   width: 100%;
   min-height: 154px;
   object-fit: cover;
 } */
}
