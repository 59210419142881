@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}
.leftContainer {
  display: flex;
  flex-direction: column;
  width: 60%;
  @media (max-width: 1023px) {
    width: 100%;
  }
}
.locationText {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1f403b;
  margin: 8px 0 0;
  @media (max-width: 767px) {
    margin: 12px 0 0;
  }
}
.locationWrapper {
  display: flex;
  gap: 7.25px;
  align-items: center;
  @media (max-width: 767px) {
    gap: 3px;
  }
  & svg {
    fill: transparent;
    @media (max-width: 767px) {
      width: 11px;
      height: 14px;
    }
  }
  & .locationText {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #1f403b;
    margin: 0;
    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: -0.03em;
    }
  }
}
.listingRatingContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 0 8px;
  @media (max-width: 767px) {
    gap: 2px;
  }
  & svg {
    fill: transparent;
    @media (max-width: 767px) {
      min-width: 10px;
      height: 14px !important;
      width: 14px !important;
    }
  }
  & span {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #1f403b;
    @media (max-width: 1100px) {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
    }
  }
}
.listingRating {
  display: flex;
  align-items: center;
  width: 73px;
  & h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #1f403b;
    margin-left: 4px;
    @media (max-width: 767px) {
      line-height: 18px;
      color: #000000;
    }
  }
  & svg {
    @media (max-width: 767px) {
      height: 10px;
      width: 10px;
    }
  }
}
.routeLink {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    /* background: #f9f9f9; */
    margin-bottom: 9px;
    gap: 8px;
    height: 24px;
  }

  & svg {
    @media (max-width: 767px) {
      width: 11px;
      height: 11px;
    }
  }

  & a {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #060531;
    text-decoration: none;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 12px;
    }
  }

  & .accountSetting {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1fd1bc;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 12px;
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
}
.reviewContainer {
  & .reviewTitle {
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
    @media (max-width: 767px) {
      margin: 16px 0 12px;
    }
    & a {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #1fd1bc;
      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    & h4 {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      color: #060531;
      @media (max-width: 767px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  & .commentCards {
    display: flex;
    flex-direction: column;
    height: 392px;
    overflow-y: scroll;
    @media (max-width: 767px) {
      overflow-x: scroll;
      gap: 11px;
      flex-direction: row;
      height: auto;
    }
  }
  & .commentCard {
    background: #f5f5f5;
    border-radius: 6px;
    padding: 16px;
    margin: 0 0 16px;
    @media (max-width: 767px) {
      min-width: 302px;
      margin: 0 0 12px;
      padding: 12px 10px 12px 12px;
    }
    & .commentText {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #1f403b;
      padding-left: 44px;
      @media (max-width: 767px) {
        padding-left: 0px;
        font-size: 14px;
        line-height: 18px;
      }
    }
    & .commentDate {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.02em;
      color: #1f403b;
      opacity: 0.8;
      margin: 4px 0 0;
      @media (max-width: 767px) {
        margin: 2px 0 0;
        font-size: 12px;
        line-height: 15px;
      }
    }
    & .commentCardTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      & .imageContent {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      & .starReview {
        display: flex;
        gap: 4px;
        & svg {
          width: 16px;
          height: 16px;
          @media (max-width: 767px) {
            width: 12px;
            height: 12px;
          }
        }
      }
      & h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        @media (max-width: 767px) {
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
        }
      }
      & h6 {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #1f403b;
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
  }
}
.listingPageContainer {
  display: flex;
  flex-direction: column;
  max-width: 1306px;
  margin: 0 auto;
  width: 100%;
  padding: 48px 0 96px;
  min-height: 92vh;
  @media (max-width: 1500px) {
    max-width: 1216px;
  }
  @media (max-width: 1336px) {
    max-width: 100%;
    padding: 48px 60px 96px;
  }
  @media (max-width: 1250px) {
    padding: 40px 40px 70px;
  }
  @media (max-width: 1023px) {
    /* gap: 48px; */
    flex-direction: column;
  }
  @media (max-width: 900px) {
    padding: 40px 20px 70px;
  }
  @media (max-width: 767px) {
    min-height: 93vh;
    padding: 12px 20px 32px;
    gap: 35px;
  }
}
.listingSliderContainer {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  @media (max-width: 1023px) {
    flex-direction: column;
  }
  @media (max-width: 767px) {
    gap: 0;
  }
}
.sliderContainer {
  width: 100%;
}
.imageContainer {
  width: 60%;
}
.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  display: flex;
  border-radius: 6px;
  position: relative; /* allow positioning own listing action bar */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  padding-bottom: 0; /* No fixed aspect on desktop layouts */
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  composes: h4 from global;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  composes: h4 from global;
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 0;
    padding: 25px 24px 22px 12px;
  }
}

.editIcon {
  margin: -6px 7px 0 0;
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.41 * 100vw);
    max-height: 50vh;
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 41%;
  max-width: 480px;
  background: #ffffff;
  border: 1px solid #d8dad9;
  border-radius: 6px;
  height: fit-content;
  @media (max-width: 1023px) {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 767px) {
    border: none;
  }
}

.mainContent {
  padding: 16px 16px 48px;
  @media (max-width: 767px) {
    padding: 0px 0px 0px;
  }
}
.otherMainContent {
  @media (max-width: 767px) {
    display: none;
  }
}
.mainContentMobile {
  padding: 32px 16px 48px;
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 767px) {
    padding: 0px 0px 0px;
  }
}

.bookingPanel {
  & form > p {
    @media (max-width: 767px) {
      /* display: none; */
    }
  }
  & form > div {
    @media (max-width: 767px) {
      /* display: none; */
    }
    &:first-child {
      @media (max-width: 767px) {
        display: grid;
      }
    }
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -72px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -112px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;
  width: 96px;
  height: 96px;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */
  width: 152px;
  height: 152px;

  @media (--viewportMedium) {
    display: flex;
  }
}

.initialsDesktop {
  font-size: 48px;
  font-weight: var(--fontWeightBold);
  padding-bottom: 8px;
}
.iconWrapper {
  background: #ffffff;
  box-shadow: 0px 36px 60px rgba(31, 209, 188, 0.12);
  border-radius: 100px;
  height: 26px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    background: transparent;
  }
  & path {
    @media (max-width: 767px) {
      fill: #060531;
    }
  }
  &:hover {
    background: #e9e6e6df;
    @media (max-width: 767px) {
      background: transparent;
    }
  }
}
.headingMain {
  background: #1fd1bc;
  border-radius: 6px 6px 0px 0px;
  padding: 16px 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 767px) {
    background: #fcfcfc;
    padding: 35px 0px 16px;
    align-items: flex-start;
  }
  & .headingMainContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 60px;
    align-items: center;
  }
}

.menuContent {
  position: absolute;
  right: 0;
  z-index: var(--zIndexPopup);
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  left: unset !important;
  @media (max-width: 767px) {
    max-width: 88px;
    padding: 12px;
  }
  & ul {
    & li {
      padding: 0;
      line-height: 20px;
      &:first-child {
        padding: 0 0 8px;
        border-bottom: 1px solid #ebebeb;
        margin: 0 0 8px;
      }
      &:nth-child(2) {
      }
      &:nth-child(3) {
        padding: 8px 0 0;
        border-top: 1px solid #ebebeb;
        margin: 8px 0 0;
      }
      & a,
      & button {
        display: inline;

        /* Borders */
        border: none;
        width: 74px;
        /* Text size should be inherited */
        text-decoration: none;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #060531;
        white-space: nowrap;
        text-align: left;
        padding: 0;
        border-radius: 4px;

        /* Remove default margins from font */
        margin-top: 0;
        margin-bottom: 0;
        transition: var(--transitionStyleButton);
        @media (max-width: 767px) {
          font-size: 14px;
          line-height: 18px;
        }
        &:hover {
          text-decoration: none;
          /* background-color: var(--failColor); */
          cursor: pointer;
        }
      }
    }
  }
}
.viewAllText {
  color: #1fd1bc;
  font-family: Quicksand;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
.sectionHeading {
  @media (max-width: 767px) {
    background: #fcfcfc;
    padding: 0px;
  }
  & h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #ffffff;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
      color: #060531;
      margin-bottom: 4px;
    }
  }
}

.heading {
  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}
.subCategoryText {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-transform: capitalize;
  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1fd1bc;
  }
}
.mobileText {
  color: #010807;
  text-transform: capitalize;
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionDescription {
  border-bottom: 1px solid #d8dad9;
  border-top: 1px solid #d8dad9;
  padding: 16px 0;
  & h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #060531;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  & p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #1f403b;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.sectionDescriptionWithoutReviews {
  @media (--viewportMedium) {
    border-bottom: 0;
  }
}

.descriptionTitle {
  margin: 0 0 16px;
  @media (max-width: 767px) {
    margin: 0 0 6px;
  }
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  /* Font */
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.description {
  margin-top: 0;
  margin-bottom: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
  & button {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.sectionMap {
  border-top: 1px solid #d8dad9;
  border-bottom: 1px solid #d8dad9;
  padding: 16px 0;
  margin: 0 0 16px;
  @media (max-width: 767px) {
    border-top: unset;
    margin: 0;
  }
}

.sectionMapWithoutReviews {
  border-bottom: 0;

  @media (--viewportMedium) {
    border-bottom: 1px solid #d8dad9;
  }
}

.locationTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #060531;
  margin: 0 0 8px;
  position: relative;
  @media (max-width: 767px) {
    margin: 0 0 12px;
    font-size: 16px;
    line-height: 24px;
  }
  & .brandStoreIcon {
    position: absolute;
    top: -2px;
    left: 136px;
    z-index: 1;
    background: #fff;
    width: 23px;
    @media (max-width: 767px) {
      top: -1px;
      left: 116px;
      width: 17px;
    }
    & svg {
      fill: #ffffff00;
      background: #fff;
      width: 18px;
      height: 18px;
      @media (max-width: 767px) {
        width: 14px;
        height: 14px;
      }
    }
  }
}
.menuContent {
  position: absolute;
  left: 0 !important;
  right: unset !important;
  z-index: var(--zIndexPopup);
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 25%);
  border-radius: 6px;
  max-width: 250px;
  min-width: 250px !important;
  @media (max-width: 767px) {
    padding: 12px;
    left: 0 !important;
    right: unset !important;
    min-width: 200px !important;
    max-width: 200px;
  }
  & > div {
    display: none;
  }
  & ul {
    & li {
      padding: 0 0 4px;
      border-bottom: 1px solid #ebebeb;
      margin: 0 0 4px;
      &:last-child {
        border-bottom: none;
        padding: 0;
        margin: 0;
      }
    }
  }
}
.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  composes: h3 from global;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  /* margin: 70px 0 100px 0; */

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
  & textarea {
    box-sizing: border-box;
    padding: 14px;
    height: 180px !important;
    @media (max-width: 767px) {
      height: 150px !important;
    }
  }
  & h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #060531;
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 20px;
      color: #060531;
    }
  }
}

.enquirySubmitButtonWrapper {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */

  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 104px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;
  height: 104px;
  border-radius: 6px;
  overflow: hidden;
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
.otherContentContainer {
  padding: 24px 16px 24px;
  @media (max-width: 767px) {
    display: none;
  }
  & .headingMain {
    background: #ffffff;
    align-items: flex-start;
    padding: 0px;
    margin: 0 0 8px;
    display: block;
  }
  & .title {
    color: #060531;
    margin: 0 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @media (max-width: 767px) {
      margin: 0 0 4px;
    }
  }
}
.otherHeadingMain {
  padding: 35px 0 0;
  margin: 0 0 16px;
  @media (min-width: 768px) {
    display: none;
  }
}
.otherListingPageContainer {
  padding-top: 0;
  padding-bottom: 120px;
  @media (max-width: 767px) {
    padding-bottom: 32px;
    gap: 0;
    margin-top: 16px;
  }
}
.bookingHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d8dad9;
  height: 112px;
  padding: 0 16px;
  @media (max-width: 767px) {
    border-top: unset;
    height: 104px;
  }
}
.desktopPriceContainer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.desktopPriceValue {
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #060531;
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 28px;
  }
}
.desktopPerUnit {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #1f403b;
}
.activeLink {
  border: 1px solid #1fd1bc;
  border-radius: 6px;
  width: 150px;
  height: 80px;
  margin: 0 -16px;
  @media (max-width: 767px) {
    width: 104px;
    height: 72px;
  }
  & .desktopPriceValue {
    color: #1fd1bc;
  }
  & .desktopPerUnit {
    color: #1fd1bc;
  }
}
.bookingHeadingMobile {
  @media (min-width: 768px) {
    display: none;
  }
}
.dateSection {
  background: #ffffff;
  border: 1px solid #d8dad9;
  border-radius: 6px;
  padding: 10px 16px;
  height: 200px;
}
.learnMoreSection {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid #d8dad9;
  & a {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #1fd1bc;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  & .freeCancellation {
    display: flex;
    align-items: center;
    gap: 8px;
    @media (max-width: 767px) {
      gap: 9.28px;
    }
    & svg {
      @media (max-width: 767px) {
        width: 17.5px;
        height: 17.5px;
      }
    }
    & h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #060531;
      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
.officialBrandSection {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  & a {
    & svg {
      @media (max-width: 767px) {
        width: 32px;
        height: 32px;
      }
    }
  }
  & .officialBrand {
    display: flex;
    align-items: center;
    gap: 8px;
    @media (max-width: 767px) {
      gap: 9.28px;
    }
    & svg {
      @media (max-width: 767px) {
        width: 24px;
        height: 13px;
      }
    }
    & h6 {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #060531;
      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
.totalPriceSection {
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  & a {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #ffffff;
    background: #1fd1bc;
    border-radius: 6px;
    width: 216px;
    height: 40px;
  }
  & .totalPrice {
    display: flex;
    align-items: center;
    gap: 3px;
    & p {
      font-size: 14px;
      margin-top: 5px;
      @media (max-width: 767px) {
        font-size: 10px;
        line-height: 24px;
      }
    }
    & h6 {
      font-weight: 700;
      font-size: 24px;
      line-height: 40px;
      color: #1fd1bc;
      @media (max-width: 767px) {
        font-size: 22px;
        line-height: 24px;
      }
    }
  }
}
.learnMoreSectionMobile {
  @media (min-width: 768px) {
    display: none;
  }
}
.officialBrandSectionMobile {
  @media (min-width: 768px) {
    display: none;
  }
}
.totalPriceSection {
  @media (max-width: 767px) {
    display: none;
  }
}
