@import '../../styles/customMediaQueries.css';

.root {
}
.uploadText {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin: 8px 0 0 0;
  cursor: pointer;
  @media (max-width: 767px) {
    margin: 16px auto 0 !important;
    max-width: 260px;
    letter-spacing: -0.02em;
  }
}
.sectionContainer {
  padding: 0;
  & .thisInformation {
    max-width: 648px;
    margin: 48px auto 121px;
    padding: 44px 48px 48px;
    text-align: left;
    display: flex;
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1f403b;
    background: rgba(31, 209, 188, 0.06);
    border: 1px solid #ececec;
    border-radius: 6px;
    @media (max-width: 767px) {
      padding: 16px 12px 23px;
      margin: 32px auto 32px;
      border: 1px solid transparent;
    }

    & svg {
      margin-right: 8px;
    }
  }

  & .paragraphContent {
    display: flex;
    gap: 48px;
    text-align: left;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 22px;
    }

    & > div {
      width: 100%;
    }

    & svg {
      min-width: 112px;
      height: 96px;
      @media (max-width: 767px) {
        max-width: 56px;
        min-width: 56px;
        height: 42px;
      }
    }

    & h2 {
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 8px;

      @media (max-width: 767px) {
        margin-bottom: 6px;
        font-size: 16px;
        line-height: 20px;
      }
    }

    & p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #1f403b;

      @media (max-width: 767px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  & .buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 48px 0 178px;
    @media (max-width: 767px) {
      margin: 32px 0 16px;
      flex-direction: column-reverse;
    }

    & button {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: initial;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      border-radius: 6px;
      @media (max-width: 767px) {
        border-radius: 10px;
        font-size: 14px;
        line-height: 18px;
        max-width: 152px;
        width: 50%;
      }
    }

    & button:first-child {
      background: #ffffff;
      text-align: right;
      color: #1fd1bc;

      &:hover {
        background-color: #fff;
        box-shadow: none;
      }
    }
  }
}

.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 111px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin: 0 auto;
  max-width: 248px;

  @media (max-width: 767px) {
    max-width: 100%;
  }
}

.label {
  margin: 0 auto;
  /* width: var(--ProfileSettingsForm_avatarSize);
  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  } */
}
  :global(.loader) {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid var(--marketplaceColor);
    width: 28px;
    height: 28px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: 100%;
  height: 52px;
  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  cursor: pointer;

  @media (--viewportMedium) {
    width: 248px;
    height: 200px;
    border-radius: 6px;
  }
}

.avatarContainer img {
  object-fit: cover;
  border-radius: 6px;

  @media (max-width: 767px) {
    object-fit: contain;
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  background: #f5f5f5;
  opacity: 0.5;
  border: 1px dashed #1f403b;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 767px) {
    border: 0px dashed #1f403b;
    border-radius: 10px;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
  }

  &:hover {
    border-color: var(--matterColorAnti);
  }

  & .uploadIcon {
    @media (max-width: 767px) {
      min-width: 52px;
      background: #1fd1bc;
    }
  }

  & svg {
    color: #f5f5f5;

    @media (max-width: 767px) {
      width: 20px;
    }
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}

.avatarPlaceholderText {
  font-size: 14px;
  line-height: 18px;

  @media (--viewportMedium) {
    max-width: 150px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  composes: h4 from global;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
  background: none;
}

.changeAvatar {
  /* Font */
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: 5px;
  right: 10px;
  /* Dimensions */
  width: 95px;
  height: 35px;
  padding: 5px 10px 7px 33px;

  /* Look and feel (buttonish) */
  background-color: var(--matterColorLight);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Position: under */
    width: 105px;
    height: 41px;
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 5px 10px 7px 35px;
  }

  &:hover {
    border: solid 1px var(--matterColorAnti);
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: 32px;

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative);
  /* Loading BG color */
  border-radius: 6px;
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: 248px;
    height: 200px;
    border-radius: 6px;
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  composes: marketplaceDefaultFontStyles from global;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.fileInfo {
  composes: h5 from global;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.firstName {
  width: calc(34% - 9px);
}

.lastName {
  width: calc(66% - 9px);
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  margin-top: 24px;
}
