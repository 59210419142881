@import '../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalBaseStyles from global;
  padding: 23px 12px 50px;
  flex-basis: 335px;
  @media (max-width: 767px) {
    padding: 32px 16px 32px;
    margin: 0 20px;
  }
  @media (--viewportMedium) {
    flex-basis: 512px;
  }
}

.modalContent {
  flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
  composes: marketplaceModalIconStyles from global;
}

/* Title of the modal */
.modalTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #060531;
  text-align: center;
  @media (max-width: 767px) {
    font-size: 18px;
line-height: 22px;
  }
}

.reviewee {
  white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #060531;
  margin: 8px 0 0;
  text-align: center;
}
.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  padding: 8px;
  background: #fff;
  border-radius: 14px;
  @media (min-width: 768px) {
    max-height: 156px;
    width: 186px;
    height: 156px;
  }
  @media (max-width: 767px) {
    padding: 0px;
    box-shadow: 0px 0px 6px 4px rgba(31, 209, 188, 0.04);
    border-radius: 9px;
    height: 136px;
    object-fit: cover;
  }
  @media (max-width: 450px) {
    height: 106px;
  }
}
.heroReviewWrapper{
  display: flex;
  margin-bottom: 38px;
  border-bottom: 1px dashed hsl(241deg 81% 11% / 80%);
  position: relative;
  padding: 0 12px 32px;
  @media (max-width: 767px) {
    padding: 0 0px 32px;
  }
  &:after{
    position: absolute;
    content: "";
    height: 24px;
    width: 13px;
    background: #d9d9d9;
    bottom: -12px;
    right: -12px;
    border-radius: 100px 0 0 100px;
    @media (max-width: 767px) {
      height: 20px;
      width: 11px;
      bottom: -10px;
      right: -16px;
    }
  }
  &:before{
    position: absolute;
    content: "";
    height: 24px;
    width: 13px;
    background: #d9d9d9;
    bottom: -12px;
    left: -12px;
    border-radius: 0 100px 100px 0px;
    @media (max-width: 767px) {
      height: 20px;
      width: 11px;
      bottom: -10px;
      left: -16px;
    }
  }
 & img{
@media (max-width: 767px) {
  width: 117px;
  height: 106px;
}
 }
  & h6{
    font-weight: 700;
font-size: 20px;
line-height: 25px;
color: #060531;
@media (max-width: 767px) {
  font-size: 18px;
line-height: 24px;
}
  }
  & p{
    font-weight: 500;
    text-transform: capitalize;
font-size: 16px;
line-height: 20px;
color: #1F403B;
margin: 8px 0 0;
@media (max-width: 767px) {
  font-size: 12px;
line-height: 14px;
}
  }
  & .dateText{
    font-weight: 600;
font-size: 18px;
line-height: 22px;
letter-spacing: -0.02em;
color: #060531;
@media (max-width: 767px) {
  font-size: 14px;
line-height: 18px;
letter-spacing: -0.02em;
}
  }
  &>div{
    &:nth-child(1){
    
    }
    &:nth-child(2){
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px;
      @media (max-width: 767px) {
        padding: 6px 8px;
      }
    }
  }
}