@import '../../styles/customMediaQueries.css';

.title {
  composes: h1 from global;

  margin-top: 0;
  margin-bottom: 0;
}

.faqSection {
  background: linear-gradient(270deg, #e5efff 0%, rgba(255, 255, 255, 0) 100%);

  @media (max-width: 767px) {
    background: linear-gradient(91deg, #e5efff 0%, rgba(255, 255, 255, 0) 100%);
  }
}
.sectionMain {
  composes: marketplaceSectionWrapper from global;
}

.sectionContainer {
  margin: 88px auto 0;
  padding: 96px 0 96px;

  @media (max-width: 1280px) {
    max-width: 100%;
    margin: 80px 0px 0;
    padding: 80px 0;
  }
  @media (max-width: 1100px) {
    padding: 70px 0px;
    margin: 70px 0px 0;
  }
  @media (max-width: 1023px) {
    padding: 32px 0px 32px;
    margin: 32px 0px 0;
  }

  & h1 {
    text-align: center;

    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }

    @media (max-width: 450px) {
      width: 300px;
      margin: 0 auto 24px;
    }

    & .textColor {
      color: #1fd1bc;
    }
  }

  & .textColor {
    color: #1fd1bc;
  }

  & .faqContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    /* row-gap: 16px; */
    @media (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 0px;
      row-gap: 0px;
    }

    & .faqText {
      position: relative;
      /* &:last-child{
       & svg{
          top: 34px;
          @media (max-width: 767px) {
            top: 28px;
          }
        }
      } */
      & svg {
        position: absolute;
        top: 23px;
        right: 21px;
        @media (max-width: 767px) {
          top: 28px;
          right: 15px;
        }
      }
    }
  }
}
.faqContent :global(.Collapsible__trigger) {
  /* composes: h1 from global; */
  display: block;
  background: #ffffff;
  border: 1px solid #e5efff;
  border-radius: 6px;
  margin-bottom: 0px;
  padding: 15px 64px 15px 18px;
  font-weight: 500;
  color: #060531;
  font-size: 16px;
  line-height: 22px;
  height: auto;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  @media (max-width: 1300px) {
    padding: 15px 48px 15px 18px;
  }

  @media (max-width: 767px) {
    margin-bottom: 12px;
    padding: 0px 44px 0px 12px;
    height: 64px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
}
.faqContent :global(.Collapsible) {
 height: 100%;
}

.faqContent :global(.Collapsible__contentInner) {
  padding: 0 15px;
  /* margin: -5px 0 20px; */
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
}